
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import validationRules from '../../validation/validationRules'
import PhoneInput from '../common/PhoneInput.vue'

const namespace: string = 'organization'

@Component({ components: { PhoneInput } })

export default class ContactForm extends Vue {
  @Prop({ type: [String, Number] }) propertyId!: string;
  @Prop({ type: [Object] }) contactData!: any;
  @Getter('organization', { namespace }) organization: any;
  @Action('sendOrganizationContactForm', { namespace }) sendOrganizationContactForm: any;

  formSent = false;
  formError = false;
  contactForm = {
    phone_number: '',
    message: '',
    name: '',
    email: '',
  };

  rules = {
    required: v => validationRules.checkRequired(v) || 'Required',
    isEmail: v => validationRules.checkEmail(v) || 'Invalid Email',
    isPhone: v => validationRules.checkPhone(v) || 'Invalid Phone',
  };

  get isValid (): boolean {
    return (this.$refs.form as Vue & { validate: () => boolean }).validate()
  }

  clearForm () {
    this.formSent = false
    this.formError = false
    for (const key in this.contactForm) {
      this.contactForm[key] = ''
    }
  }

  async sendForm () {
    if (!this.isValid) return
    try {
      if (this.propertyId) {
        await this.$api.property.sendContactForm(this.propertyId, this.contactForm)
      } else {
        await this.$api.organization.sendContactForm(this.organization.id, this.contactForm)
      }
    } catch (e) {
      console.warn(e)
      this.formError = true
    } finally {
      this.formSent = true
    }
  }
}
