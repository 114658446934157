var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"manager-settings-layouts"},[_c('v-row',[_c('v-col',[_c('manager-properties-selector')],1)],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-text',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},on:{"click:outside":function($event){return _vm.closeEditLayoutDialog()}},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"color":"primary"}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" add_circle ")]),_vm._v(" Add Layout ")],1)]}}]),model:{value:(_vm.editLayoutDialog),callback:function ($$v) {_vm.editLayoutDialog=$$v},expression:"editLayoutDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.editLayoutFormTitle)+" ")])]),_c('v-card-text',[_c('v-container',{staticClass:"pa-0"},[_c('v-form',{ref:"editLayoutForm",attrs:{"id":"edit-layout-form"},on:{"submit":function($event){$event.preventDefault();return _vm.saveLayout()}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.$store.getters['manager/propertyIdsAsItems'],"required":"","rules":[v => !!v.toString().length || 'Property is required'],"label":"Property","outlined":""},model:{value:(_vm.editedLayoutItem.property_id),callback:function ($$v) {_vm.$set(_vm.editedLayoutItem, "property_id", $$v)},expression:"editedLayoutItem.property_id"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"required":"","rules":[
                              v => !!v.trim() || 'Building is required'
                            ],"label":"Building","outlined":""},model:{value:(_vm.editedLayoutItem.building),callback:function ($$v) {_vm.$set(_vm.editedLayoutItem, "building", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"editedLayoutItem.building"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"required":"","rules":[
                              v => !!v.trim() || 'Floor is required'
                            ],"label":"Floor","outlined":""},model:{value:(_vm.editedLayoutItem.floor),callback:function ($$v) {_vm.$set(_vm.editedLayoutItem, "floor", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"editedLayoutItem.floor"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"required":"","rules":[
                              v => !!v.trim() || 'Unit is required'
                            ],"label":"Unit","outlined":""},model:{value:(_vm.editedLayoutItem.unit),callback:function ($$v) {_vm.$set(_vm.editedLayoutItem, "unit", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"editedLayoutItem.unit"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"required":"","rules":[
                              v => !!v.trim() || 'Unit Type is required'
                            ],"label":"Unit Type","outlined":""},model:{value:(_vm.editedLayoutItem.unit_type),callback:function ($$v) {_vm.$set(_vm.editedLayoutItem, "unit_type", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"editedLayoutItem.unit_type"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"required":"","rules":[
                              v => !!v.trim() || 'Room is required'
                            ],"label":"Room","outlined":""},model:{value:(_vm.editedLayoutItem.room),callback:function ($$v) {_vm.$set(_vm.editedLayoutItem, "room", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"editedLayoutItem.room"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"required":"","rules":[
                              v => !!v.trim() || 'Room Type is required'
                            ],"label":"Room Type","outlined":""},model:{value:(_vm.editedLayoutItem.room_type),callback:function ($$v) {_vm.$set(_vm.editedLayoutItem, "room_type", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"editedLayoutItem.room_type"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"required":"","rules":[
                              v => !!v.trim() || 'Bed is required'
                            ],"label":"Bed","outlined":""},model:{value:(_vm.editedLayoutItem.bed),callback:function ($$v) {_vm.$set(_vm.editedLayoutItem, "bed", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"editedLayoutItem.bed"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.closeEditLayoutDialog()}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"type":"submit","form":"edit-layout-form","loading":_vm.layoutSaving,"text":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.editLayoutFormActivatorText)+" ")])],1)],1)],1),_c('v-spacer',{staticClass:"d-flex d-sm-none"})],1),(_vm.$store.state.manager.loading || _vm.layoutsLoading)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e(),_c('ag-grid-vue',{staticClass:"ag-theme-material",attrs:{"grid-options":_vm.gridOptions,"column-defs":_vm.columnDefs},on:{"grid-ready":_vm.onGridReady,"row-double-clicked":(event) => !event.node.group && _vm.openEditLayoutDialog(event.data),"model-updated":function($event){return _vm.sizeColumns()}},model:{value:(_vm.layouts),callback:function ($$v) {_vm.layouts=$$v},expression:"layouts"}})],1)],1)],1)],1),_c('ConfirmationModal',{ref:"confirmationModal"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }