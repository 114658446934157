
import { Vue, Component } from 'vue-property-decorator'
import { ICellRendererParams } from 'ag-grid-community'
import { PaymentPlanAssignment } from '../../middleware/entities'
import { PaymentPlan } from '../../middleware/PaymentPlan'

@Component({})
export default class ManagerSettingsAssignPlansRequiredPlanColumn extends Vue {
  params!: ICellRendererParams

  mounted () { // autosize column based on the number of assigned payment plans. Not 100% precise, though does its thing
    const allPaymentPlanAssignmentPaymentPlanIds = this.parent.paymentPlanAssignments.map((paymentPlanAssignment: PaymentPlanAssignment) => {
      return paymentPlanAssignment[this.params.colDef.field as keyof PaymentPlanAssignment]
    })

    const longestArray = allPaymentPlanAssignmentPaymentPlanIds.reduce((acc: number[], curr: number[]) => {
      return curr.length > acc.length ? curr : acc
    }, [])

    if (this.params.data[this.params.colDef.field as keyof PaymentPlanAssignment].length === longestArray.length) {
      this.params.columnApi.autoSizeColumn(this.params.column)
    }
  }

  get parent () {
    return this.params.context.componentParent
  }

  get assignedPaymentPlans () {
    if (this.params.colDef.field === 'new_tenant_payment_plan_ids') {
      return this.parent.paymentPlans.newTenant.filter((paymentPlan: PaymentPlan) => {
        return this.params.data[(this.params.colDef.field as keyof PaymentPlanAssignment)].includes(paymentPlan.id)
      })
    } else if (this.params.colDef.field === 'renewal_tenant_payment_plan_ids') {
      return this.parent.paymentPlans.renewalTenant.filter((paymentPlan: PaymentPlan) => {
        return this.params.data[(this.params.colDef.field as keyof PaymentPlanAssignment)].includes(paymentPlan.id)
      })
    } else if (this.params.colDef.field === 'renewal_transfer_tenant_payment_plan_ids') {
      return this.parent.paymentPlans.renewalTransferTenant.filter((paymentPlan: PaymentPlan) => {
        return this.params.data[(this.params.colDef.field as keyof PaymentPlanAssignment)].includes(paymentPlan.id)
      })
    }

    return []
  }

  get allPaymentPlansAsItems () {
    if (this.params.colDef.field === 'new_tenant_payment_plan_ids') {
      return this.parent.getAvailablePaymentPlansOfTypeAsItems('newTenant')
    } else if (this.params.colDef.field === 'renewal_tenant_payment_plan_ids') {
      return this.parent.getAvailablePaymentPlansOfTypeAsItems('renewalTenant')
    } else if (this.params.colDef.field === 'renewal_transfer_tenant_payment_plan_ids') {
      return this.parent.getAvailablePaymentPlansOfTypeAsItems('renewalTransferTenant')
    } else {
      return []
    }
  }

  updatePaymentPlanAssignment () {
    this.parent.updatePaymentPlanAssignment(this.params.data)
    this.params.columnApi.autoSizeColumn(this.params.column)
  }

  deletePaymentPlan (paymentPlan: PaymentPlan) {
    const index = this.params.data[this.params.colDef.field!].indexOf(paymentPlan.id)
    this.params.data[this.params.colDef.field!].splice(index, 1)

    this.updatePaymentPlanAssignment()
    this.params.columnApi.autoSizeColumn(this.params.column)
  }
}
