
import videojs from 'video.js'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class VideoPlayer extends Vue {
  @Prop({}) options!: any;
  @Prop({ type: Boolean }) active!: boolean;
  loaded = false;
  player = null;

  @Watch('active')
  onActiveChange (newVal) {
    const method = newVal ? 'play' : 'pause'
    this.player[method]()
  }

  mounted () {
    this.player = videojs(this.$refs.videoPlayer, this.options, () => {
      this.loaded = true
    })

    if (this.active) {
      this.player.play()
    }
  }

  beforeDestroy () {
    if (this.player) {
      this.player.dispose()
    }
  }
}
