
import { Vue, Component, Watch } from 'vue-property-decorator'
import ManagerPropertySelector from '../components/manager/ManagerPropertiesSelector.vue'
import ManagerLeasePeriodSelector from '../components/manager/ManagerLeasePeriodsSelector.vue'
import ManagerSettingsAssignPlansRequiredPlanColumn from '../components/manager/ManagerSettingsAssignPlansRequiredPlanColumn.vue'
import { AgGridVue } from 'ag-grid-vue'
import { ColDef, ColumnApi, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community'
import API_tmp from '../middleware/API_tmp'
import { PaymentPlanAssignment } from '../middleware/entities'
import { PaymentPlan } from '../middleware/PaymentPlan'

@Component({
  components: {
    ManagerPropertySelector,
    ManagerLeasePeriodSelector,
    AgGridVue,
  },
})
export default class ManagerSettingsAssignPlans extends Vue {
  gridOptions: GridOptions = null!
  columnDefs: ColDef[] = null!
  gridApi: GridApi = null!
  columnApi: ColumnApi = null!

  paymentPlans: { [key: string]: PaymentPlan[] } = {
    newTenant: [],
    renewalTenant: [],
    renewalTransferTenant: [],
  }

  paymentPlansLoading = false

  paymentPlanAssignments: PaymentPlanAssignment[] = null!
  paymentPlanAssignmentsLoading = false
  paymentPlanAssignmentsBeingUpdated: PaymentPlanAssignment[] = []

  selectedPaymentPlanAssignments: PaymentPlanAssignment[] = []

  beforeMount () {
    this.gridOptions = {
      context: {
        componentParent: this,
      },

      groupUseEntireRow: true,
      suppressScrollOnNewData: true,
      enableCellChangeFlash: true,
      suppressCellSelection: true,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      groupSelectsChildren: true,
      groupSelectsFiltered: true,
      animateRows: true,
      domLayout: 'autoHeight',

      defaultColDef: {
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
        headerCheckboxSelectionFilteredOnly: true,
      },
    }

    this.columnDefs = [
      {
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        menuTabs: [],
        resizable: false,
      },

      { headerName: 'Id', field: 'id' },

      {
        headerName: 'Property',
        valueGetter: (params) => {
          const property = this.$store.getters['manager/propertyById'](params.data.property_id)
          return property ? property.name : ''
        },
      },

      { headerName: 'Building', field: 'building' },
      { headerName: 'Floor', field: 'floor' },
      { headerName: 'Unit', field: 'unit' },
      { headerName: 'Unit type', field: 'unit_type' },
      { headerName: 'Room', field: 'room' },
      { headerName: 'Room type', field: 'room_type' },
      { headerName: 'Bed', field: 'bed' },

      {
        headerName: 'Required Plan - New Tenant',
        field: 'new_tenant_payment_plan_ids',
        cellRendererFramework: ManagerSettingsAssignPlansRequiredPlanColumn,
      },

      {
        headerName: 'Required Plan - Renewal Tenant',
        field: 'renewal_tenant_payment_plan_ids',
        cellRendererFramework: ManagerSettingsAssignPlansRequiredPlanColumn,
      },

      {
        headerName: 'Required Plan - Renewal Transfer Tenant',
        field: 'renewal_transfer_tenant_payment_plan_ids',
        cellRendererFramework: ManagerSettingsAssignPlansRequiredPlanColumn,
      },
    ]

    this.loadPaymentPlans()
    this.loadPaymentPlanAssignments()
  }

  onGridReady (params: GridReadyEvent) {
    this.gridApi = params.api
    this.columnApi = params.columnApi
  }

  get commonSelectedNewTenantPaymentPlanIds () {
    return this.getCommonSelectedXPaymentPlanIds('new_tenant_payment_plan_ids')
  }

  set commonSelectedNewTenantPaymentPlanIds (value) {
    this.setCommonSelectedXPaymentPlanIds('new_tenant_payment_plan_ids', value)
  }

  get commonSelectedRenewalTenantPaymentPlanIds () {
    return this.getCommonSelectedXPaymentPlanIds('renewal_tenant_payment_plan_ids')
  }

  set commonSelectedRenewalTenantPaymentPlanIds (value: number[]) {
    this.setCommonSelectedXPaymentPlanIds('renewal_tenant_payment_plan_ids', value)
  }

  get commonSelectedRenewalTransferTenantPaymentPlanIds () {
    return this.getCommonSelectedXPaymentPlanIds('renewal_transfer_tenant_payment_plan_ids')
  }

  set commonSelectedRenewalTransferTenantPaymentPlanIds (value: number[]) {
    this.setCommonSelectedXPaymentPlanIds('renewal_transfer_tenant_payment_plan_ids', value)
  }

  @Watch('$store.state.manager.selectedProperties')
  onSelectedPropertiesChange () {
    this.loadPaymentPlanAssignments()
  }

  @Watch('$store.state.manager.selectedLeasePeriods')
  onSelectedLeasePeriodsChange () {
    this.loadPaymentPlans()
  }

  getAvailablePaymentPlansOfTypeAsItems (type: string) {
    return this.paymentPlans[type]
      .map((paymentPlan: PaymentPlan) => {
        return {
          text: paymentPlan.name,
          value: paymentPlan.id,
        }
      })
  }

  getCommonSelectedXPaymentPlanIds (x: keyof PaymentPlanAssignment) {
    const assignedPaymentPlanIds = this.selectedPaymentPlanAssignments
      .flatMap(paymentPlanAssignment => {
        return paymentPlanAssignment[x]
      })

    return ([...(new Set(assignedPaymentPlanIds))] as number[])
  }

  setCommonSelectedXPaymentPlanIds (x: keyof PaymentPlanAssignment, value: number[]) {
    this.selectedPaymentPlanAssignments
      .forEach(paymentPlanAssignment => {
        (paymentPlanAssignment[x] as number[]) = value
      })
  }

  sizeColumns () {
    this.columnApi.autoSizeAllColumns()
  }

  async loadPaymentPlans () {
    this.paymentPlansLoading = true

    try {
      const paymentPlans = await API_tmp.manager.settings.assignPlans.getPaymentPlans(this.$store.getters['manager/selectedLeasePeriodsIds'])

      this.paymentPlans.newTenant = paymentPlans.filter(paymentPlan => {
        return paymentPlan.type === 'New Tenant'
      })

      this.paymentPlans.renewalTenant = paymentPlans.filter(paymentPlan => {
        return paymentPlan.type === 'Renewal Tenant'
      })

      this.paymentPlans.renewalTransferTenant = paymentPlans.filter(paymentPlan => {
        return paymentPlan.type === 'Renewal Transfer Tenant'
      })
    } catch {
      this.$store.dispatch('ui/showError', "Couldn't load payment plans")
    } finally {
      this.paymentPlansLoading = false
    }
  }

  async loadPaymentPlanAssignments () {
    this.paymentPlanAssignmentsLoading = true

    /* tempfix 101 - force-disable grouping before a new data is loaded */
    if (this.columnApi) {
      const cols = this.columnApi.getAllColumns()
      this.columnApi.removeRowGroupColumns(cols)
    }
    /* /tempfix */

    try {
      this.paymentPlanAssignments = await API_tmp.manager.settings.assignPlans.getPaymentPlanAssignments(this.$store.getters['manager/selectedPropertiesIds'])
    } catch {
      this.$store.dispatch('ui/showError', "Couldn't load payment plan assignments")
    } finally {
      this.paymentPlanAssignmentsLoading = false
    }
  }

  // noinspection JSUnusedGlobalSymbols
  async updatePaymentPlanAssignment (paymentPlanAssignment: PaymentPlanAssignment) {
    this.paymentPlanAssignmentsBeingUpdated.push(paymentPlanAssignment)

    try {
      await API_tmp.manager.settings.assignPlans.updatePaymentPlanAssignment(paymentPlanAssignment)

      this.sizeColumns()
      await this.$store.dispatch('ui/showSuccess', 'The payment plan assignment updated')
    } catch {
      await this.$store.dispatch('ui/showError', "Couldn't update the payment plan assignment")
    } finally {
      this.paymentPlanAssignmentsBeingUpdated.splice(this.paymentPlanAssignmentsBeingUpdated.indexOf(
        paymentPlanAssignment), 1)
    }
  }
}
