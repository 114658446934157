var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"manager-settings-document-templates-editor-subject-editor"},[_c('v-subheader',{staticClass:"manager-settings-document-templates-editor-subject-editor__subheader",class:{
      'manager-settings-document-templates-editor-subject-editor__subheader--focus': _vm.editorFocused && !_vm.disabled,
      'manager-settings-document-templates-editor-subject-editor__subheader--errors': _vm.errorMessage,
    }},[_vm._v(" Email Subject ")]),(!_vm.disabled && _vm.$vuetify.breakpoint.smAndDown)?_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"block":"","depressed":"","color":"primary"}},on),[_vm._v(" Variables "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" expand_"+_vm._s(_vm.variablesMenuOpened ? 'less' : 'more')+" ")])],1)]}}],null,false,4056397597),model:{value:(_vm.variablesMenuOpened),callback:function ($$v) {_vm.variablesMenuOpened=$$v},expression:"variablesMenuOpened"}},[_c('v-list',_vm._l((_vm.variables),function(variable){return _c('v-list-item',{key:variable.name,on:{"click":function($event){_vm.insertVariable(variable), _vm.variablesMenuOpened = false}}},[_c('v-list-item-content',[_vm._v(" "+_vm._s(variable.name)+" ")])],1)}),1)],1):_vm._e(),_c('div',{ref:"editor",staticClass:"ql-container manager-settings-document-templates-editor-subject-editor__editor",class:{
      'manager-settings-document-templates-editor-subject-editor__editor--focus': _vm.editorFocused && !_vm.disabled,
      'manager-settings-document-templates-editor-subject-editor__editor--errors': _vm.errorMessage,
    },attrs:{"tabindex":"0"},on:{"focus":function($event){return _vm.onFocus()}}}),_c('div',{staticClass:"v-text-field__details"},[_c('div',{staticClass:"v-messages theme--light error--text"},[_c('div',{staticClass:"v-messages__wrapper"},[_c('div',{staticClass:"v-messages__message"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }