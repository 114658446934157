import _Vue from 'vue'
import api from './api'

declare module 'vue/types/vue' {
  interface Vue {
    $api: typeof api
  }
}

export const ApiVuePlugin = {
  install (Vue: typeof _Vue) {
    Vue.prototype.$api = api
  },
}
