
import { Vue, Component, Prop } from 'vue-property-decorator'
import FileUploader from '../common/FileUploader.vue'
import ManagerPropertiesSelector from './ManagerPropertiesSelector.vue'
import { Action, Getter } from 'vuex-class'
import { PropertyImage } from '../../middleware/PropertyImage'

const namespace: string = 'property'

@Component({
  components: {
    FileUploader,
    ManagerPropertiesSelector,
  },
})

export default class PropertyImagesEditor extends Vue {
  @Prop({ type: String, required: true }) propertyId: string;
  @Prop({ type: Array }) propertyImagesList: PropertyImage[];
  @Action('setPropertyImages', { namespace }) setPropertyImages: any;
  @Getter('propertyImages', { namespace }) propertyImages: PropertyImage[];
  @Getter('propertyImagesLoaded', { namespace }) propertyImagesLoaded: boolean;

  dialog: boolean = false
  dialogLoading: boolean = false
  editedPropertyImageId: string | null = null
  formData: PropertyImage = new PropertyImage()
  loadedImage: File[] = []
  errors: { [key: string ]: string } = {}

  async mounted () {
    if (this.propertyImagesList.length) {
      this.setPropertyImages(this.propertyImagesList.sort((a, b) => a.order - b.order))
    } else if (!this.propertyImagesLoaded) {
      this.loadPropertyImages()
    }
  }

  loadPropertyImages = async () => {
    let property
    try {
      property = (await this.$api.property.get(+this.propertyId))
    } catch (e) {
      await this.$store.dispatch('ui/showError', 'Couldn\'t load property images')
    } finally {
      this.setPropertyImages(property.property_images)
    }
  }

  closeDialog () {
    this.dialog = false
    this.errors = {}
    this.formData = new PropertyImage()
    this.editedPropertyImageId = null
    this.dialogLoading = false
  }

  reloadImageUploader () {
    // @ts-ignore
    this.$refs.propertyimage_image && this.$refs.propertyimage_image.reload()
  }

  async savePropertyImage () {
    this.dialogLoading = true
    const formData = new FormData()
    if (this.loadedImage.length) {
      formData.append('image', this.loadedImage[0])
    }
    formData.append('caption', this.formData.caption)
    formData.append('prop', this.propertyId)

    const valid = this.validate()
    if (valid) {
      try {
        if (this.editedPropertyImageId) {
          await this.$api.property.updateImage(String(this.formData.id), formData)
        } else {
          const res = await this.$api.property.loadImage(this.propertyId, formData)
        }
      } catch (e) {
        await this.$store.dispatch('ui/showError', `Couldn't ${this.editedPropertyImageId ? 'edit' : 'create'} amenity`)
      } finally {
        this.closeDialog()
        this.loadPropertyImages()
      }
    }
    this.dialogLoading = false
  }

  validate () {
    const errors = {
      image: !this.editedPropertyImageId ? !this.loadedImage[0] ? 'Cannot be empty' : null : null,
    }
    if (Object.values(errors).filter(val => val).length) {
      this.errors = { ...errors }
      return false
    } else {
      return true
    }
  }

  changeOrder (dir, idx) {
    if ((idx === 0 && dir === 'up') || (idx === this.propertyImages.length - 1 && dir === 'down')) return
    const directionIdx = dir === 'up' ? -1 : 1
    const temp = this.propertyImages[idx + directionIdx]

    const newAmenitesArr = this.propertyImages.slice()
    newAmenitesArr[idx + directionIdx] = { ...newAmenitesArr[idx], initialOrder: idx, order: idx + directionIdx }
    newAmenitesArr[idx] = { ...temp, initialOrder: idx + directionIdx, order: idx }
    // API save is in the parent component, because we need to save all changes at once on save button click
    this.setPropertyImages(newAmenitesArr)
  }

  editPropertyImage (id: string) {
    this.editedPropertyImageId = id
    this.formData = { ...this.propertyImages.find((amenity) => id === amenity.id) }
    this.reloadImageUploader()
    this.dialog = true
  }

  async deletePropertyImage (id: string) {
    await this.$api.property.deleteImage(id)
    this.setPropertyImages(this.propertyImages.filter(amenity => amenity.id !== id))
  }
}
