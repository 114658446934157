import { render, staticRenderFns } from "./WebpagePropertiesListEditor.vue?vue&type=template&id=5940b554"
import script from "./WebpagePropertiesListEditor.vue?vue&type=script&lang=ts"
export * from "./WebpagePropertiesListEditor.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports