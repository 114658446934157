
import { Component, Vue } from 'vue-property-decorator'
import { ICellRendererParams } from 'ag-grid-community'
import { LeasePeriod } from '../../middleware/LeasePeriod'

@Component({})
export default class LeasePeriodsTableRenewalPeriods extends Vue {
  params!: ICellRendererParams

  mounted () {
    // HACK autosize column based on the number of assigned lease periods. Not 100% precise, though does its thing
    const allPeriodsToRenewFrom = this.parent.leasePeriods.map((leasePeriod: LeasePeriod) => {
      return leasePeriod.periods_to_renew_from
    })
    const longestArrayLength = allPeriodsToRenewFrom.reduce((acc: number[], curr: number[]) => {
      return curr.length > acc.length ? curr : acc
    }, []).length
    if (this.leasePeriod.periods_to_renew_from.length === longestArrayLength) {
      this.params.columnApi.autoSizeColumn(this.params.column)
    }
  }

  get leasePeriod (): LeasePeriod {
    return this.params.data
  }

  get parent () {
    return this.params.context.componentParent
  }

  get allLeasePeriods (): LeasePeriod[] {
    return this.parent.leasePeriods
  }

  get subperiodsString (): string {
    return this.leasePeriod.semesters.map(semester => semester.name).join(', ')
  }

  unassignLeasePeriod (leasePeriod: LeasePeriod) {
    throw new Error('Backend not donea')
    // this.leasePeriod.periods_to_renew_from
  }

  onUpdate () {
    this.parent.$emit('renewalPeriodsUpdated', this.leasePeriod)
  }
}
