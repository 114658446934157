
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import ManagerPropertiesSelector
  from '../../components/manager/ManagerPropertiesSelector.vue'
import { AgGridVue } from 'ag-grid-vue'
import {
  ColDef,
  ColumnApi,
  GridApi,
  GridOptions,
  GridReadyEvent,
} from 'ag-grid-community'
import { LeasePeriod } from '../../middleware/LeasePeriod'
import { mediumDate } from '../../filters'
import AgTableSwitch from './AgTableSwitch.vue'
import LeasePeriodsTableActions from './LeasePeriodsTableActions.vue'
import LeasePeriodsTableSemesters from './LeasePeriodsTableSemesters.vue'
import LeasePeriodsTableRenewalPeriods
  from './LeasePeriodsTableRenewalPeriods.vue'
import LeasePeriodContracts from './LeasePeriodContracts.vue'

@Component({
  components: {
    ManagerPropertiesSelector,
    AgGridVue,
  },
})
export default class LeasePeriodsTable extends Vue {
  @Prop({ type: Array, required: true }) leasePeriods!: LeasePeriod[]
  leasePeriodsLocal: LeasePeriod[] = []

  gridOptions: GridOptions = null!
  columnDefs: ColDef[] = null!
  gridApi: GridApi = null!
  columnApi: ColumnApi = null!

  @Watch('leasePeriods', { immediate: true })
  onLeasePeriodsChange () {
    this.leasePeriodsLocal = [...this.leasePeriods]
  }

  // TODO We likely can think a smarter solution to make checkboxes work the way we desire.
  created () {
    this.$on('fieldChanged', this.onFieldChanged.bind(this))
  }

  beforeDestroy () {
    this.$off('fieldChanged', this.onFieldChanged.bind(this))
  }

  onFieldChanged ({ entity, field, value }: { entity: any, field: string, value: any }) {
    this.$emit('fieldInput', entity, field, value)
  }

  beforeMount () {
    this.gridOptions = {
      context: {
        componentParent: this,
      },

      groupUseEntireRow: true,
      suppressScrollOnNewData: true,
      enableCellChangeFlash: true,
      suppressCellSelection: true,
      animateRows: true,
      domLayout: 'autoHeight',

      defaultColDef: {
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
      },
    }

    this.columnDefs = [
      {
        field: 'name',
        headerName: 'Period',
      },
      {
        field: 'description',
        headerName: 'Description',
      },
      {
        field: 'subperiods',
        headerName: 'Subperiods',
        cellRendererFramework: LeasePeriodsTableSemesters,
      },
      {
        field: 'start_date',
        headerName: 'Start Date',
        valueGetter: (params) => mediumDate(params.data.start_date),
      },
      {
        field: 'end_date',
        headerName: 'End Date',
        valueGetter: (params) => mediumDate(params.data.end_date),
      },
      {
        field: 'visible_to_managers',
        headerName: 'Display on Manager Dashboard',
        cellRendererFramework: AgTableSwitch,
      },
      {
        field: 'visible',
        headerName: 'Open to Leasing',
        cellRendererFramework: AgTableSwitch,
      },
      {
        field: 'periods_to_renew_from',
        headerName: 'Valid Renewal Periods',
        cellRendererFramework: LeasePeriodsTableRenewalPeriods,
      },
      {
        field: 'allow_same_bed_renewals',
        headerName: 'Limit Leasing to Renewals',
        cellRendererFramework: AgTableSwitch,
      },
      {
        field: 'open_to_renewals',
        headerName: 'Limit Leasing to Renewal Transfers',
        cellRendererFramework: AgTableSwitch,
      },
      {
        field: 'contract_template_ids',
        headerName: 'Contract Templates',
        cellRendererFramework: LeasePeriodContracts,
      },
      {
        field: 'guarantor_contract_template_ids',
        headerName: 'Guarantor Contract Templates',
        cellRendererFramework: LeasePeriodContracts,
      },
      {
        headerName: 'Actions',
        sortable: false,
        filter: false,
        enableRowGroup: false,
        cellRendererFramework: LeasePeriodsTableActions,
        pinned: 'right',
        suppressSizeToFit: true,
      },
    ]
  }

  onGridReady (params: GridReadyEvent) {
    this.gridApi = params.api
    this.columnApi = params.columnApi
  }

  onLeasePeriodEdit (leasePeriod: LeasePeriod) {
    this.$emit('editLeasePeriod', leasePeriod)
  }

  sizeColumns () {
    if (this.$vuetify.breakpoint.lgAndDown) {
      if (this.columnApi) this.columnApi.autoSizeAllColumns()
    } else {
      if (this.gridApi) this.gridApi.sizeColumnsToFit()
    }
  }
}
