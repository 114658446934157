import { Amenity } from '../middleware/Amenity'
import { executeApiRequest } from '../middleware/api'
import { API } from './endpoints'

const list = async (prop?: string): Promise<Amenity[]> => {
  const data = await executeApiRequest({ method: 'get', url: API.AMENITY.BASE, params: { prop } })
  return data.map(item => new Amenity({
    ...item,
    image: item.image ? (item.image.charAt(0) === '/' ? item.image.substr(1) : item.image).replace('media//', 'media/') : null,
  }))
}
const create = async (amenity: FormData) => {
  const data = await executeApiRequest({ method: 'post', url: API.AMENITY.BASE, data: amenity })
  return new Amenity(data)
}
const changeOrder = async (id: number | string, order: number) => executeApiRequest({ method: 'post', url: API.AMENITY.MOVE_TO(id), data: { order } })
const edit = async (id: string, amenity: FormData): Promise<Amenity> => {
  const data = await executeApiRequest({ method: 'patch', url: API.AMENITY.SINGLE(id), data: amenity })
  return new Amenity(data)
}
const deleteAmenity = async (id: string) => executeApiRequest({ method: 'delete', url: API.AMENITY.SINGLE(id) })

export default {
  list,
  create,
  changeOrder,
  edit,
  deleteAmenity,
}
