import { render, staticRenderFns } from "./ManagerSettingsDocumentTemplatesActionColumn.vue?vue&type=template&id=2fd01cb9&scoped=true"
import script from "./ManagerSettingsDocumentTemplatesActionColumn.vue?vue&type=script&lang=ts"
export * from "./ManagerSettingsDocumentTemplatesActionColumn.vue?vue&type=script&lang=ts"
import style0 from "./ManagerSettingsDocumentTemplatesActionColumn.vue?vue&type=style&index=0&id=2fd01cb9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fd01cb9",
  null
  
)

export default component.exports