
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Resource } from '../../middleware/Resource'

@Component({
  components: {
  },
})
export default class PropertyAdditionalResources extends Vue {
  @Prop({ type: Array }) resources: Resource[];
  @Prop({ type: String }) description: string;
}
