
import { Vue, Component, Prop } from 'vue-property-decorator'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

@Component({
  components: { VuePhoneNumberInput },
})
export default class RegisterForm extends Vue {
  @Prop({ required: true, type: [String] }) value!: string;
  @Prop({ type: Array }) rules!: Function[];
  @Prop({ type: Boolean }) outlined!: boolean[];
  error: boolean = false
  errorMessages: string = ''
  wasEdited: boolean = false

  get phone () {
    return this.value
  }

  set phone (val) {
    this.$emit('input', val)
  }

  getFullValue () {
    // @ts-ignore
    return this.$refs.phoneInput ? this.$refs.phoneInput.results.formattedNumber : ''
  }

  update (val) {
    if (!this.wasEdited) {
      this.wasEdited = true
      return
    }
    if (!this.rules || !this.rules.length) {
      return
    }
    const errors = this.rules
      .map(rule => rule(val.formattedNumber || val.phoneNumber))
      .concat(val.isValid ? true : 'Incorrect phone number')
    this.errorMessages = errors.filter(error => typeof error === 'string').join(' ')
    this.error = !!this.errorMessages.length
  }
}
