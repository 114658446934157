import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

const enum Banana {
  red = 'red',
  BLACK = 'black',
  purple = 'purple',
}

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#4D7CFE',
        secondary: '#98A9BC',
        accent: '#4D7CFE',
        error: '#FE4D97',
        info: '#2CE5F6',
        success: '#6DD230',
        warning: '#FFAB2B',
        textMain: '#252631',
        textSecondary: '#5D6E7F',
        borderColor: '#E8ECEF',
        light: '#FFF',
      },

      dark: {
        primary: '#252631',
        secondary: '#778CA2',
        accent: '#4D7CFE',
        error: '#FE4D97',
        info: '#2CE5F6',
        success: '#6DD230',
        warning: '#FFAB2B',
        textMain: '#252631',
        textSecondary: '#5D6E7F',
        borderColor: '#E8ECEF',
        light: '#FFF',
      },
    },
  },
  icons: {
    iconfont: 'md',
  },
})
