
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Property } from '../../middleware/Property'
import { PortfolioItem } from '../../middleware/PortfolioItem'
import FileUploader from '../common/FileUploader.vue'
import ManagerPropertiesSelector from './ManagerPropertiesSelector.vue'
import { State, Action, Getter } from 'vuex-class'
import ManagerSettingsPropertyEditor from '../../views/ManagerSettingsPropertyEditor.vue'

const namespace: string = 'property'

@Component({
  components: {
    FileUploader,
    ManagerPropertiesSelector,
  },
})

export default class WebpagePropertiesListEditor extends Vue {
  @Action('loadProperties', { namespace }) loadProperties: any;
  @Getter('properties', { namespace }) properties: Property[];
  @Getter('propertiesLoaded', { namespace }) propertiesLoaded: boolean;
  propertiesOptionsList: Property[] = []
  mounted () {
    if (this.propertiesLoaded) {
      this.propertiesOptionsList = [...this.properties]
    } else {
      this.loadProperties()
    }
  }

  @Watch('propertiesLoaded')
  onPropertiesLoaded () {
    this.propertiesOptionsList = [...this.properties]
  }

  togglePropertyVisibility = async (updatedProperty: { status: string, id: string }) => (
    this.$api.property.save({ ...updatedProperty })
  )
}
