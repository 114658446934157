
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Property } from '../../middleware/Property'
import { PortfolioItem } from '../../middleware/PortfolioItem'
import { Action, Getter } from 'vuex-class'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'

import PropertyCard from './PropertyCard.vue'
import { SwiperOptions } from 'swiper'
import PortfolioSelect from './PortfolioSelect.vue'

const namespace: string = 'property'

@Component({
  components: {
    PortfolioSelect,
    Swiper,
    SwiperSlide,
    PropertyCard,
  },
  directives: {
    swiper: directive,
  },
})
export default class PropertiesPortfolio extends Vue {
  @Prop({ required: true, type: Object }) blocksVisibility: any;
  @Action('loadProperties', { namespace }) loadProperties: any;
  @Action('loadPortfolios', { namespace }) loadPortfolios: any;
  @Getter('properties', { namespace }) properties: Property[];
  @Getter('portfolios', { namespace }) portfolios: PortfolioItem[];
  @Getter('propertiesLoaded', { namespace }) propertiesLoaded: boolean;
  @Getter('portfoliosLoaded', { namespace }) portfoliosLoaded: boolean;

  preparedPortfolios: PortfolioItem[] = [];

  selectedPortfolioId: number = null;

  swiperOptions: SwiperOptions = {
    loop: true,
    autoplay: {
      delay: 5000,
    },
    spaceBetween: 30,
    watchOverflow: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 2,
      },
      640: {
        slidesPerView: 3,
      },
      980: {
        slidesPerView: 4,
      },
    },
  };

  @Watch('propertiesLoaded')
  onPropertiesLoad () {
    this.preparePortfolios()
  }

  @Watch('portfoliosLoaded')
  onPortfoliosLoad () {
    this.preparePortfolios()
  }

  get propertiesGroup (): PortfolioItem | null {
    return this.preparedPortfolios.find(portfolio => portfolio.id === this.selectedPortfolioId) || null
  }

  get currentGroupItems () {
    if (!this.propertiesGroup) return false
    return this.propertiesGroup.properties.map(i => this.properties.find(({ id }) => id === i))
  }

  preparePortfolios () {
    if (!this.propertiesLoaded || !this.portfoliosLoaded) return
    this.preparedPortfolios = this.portfolios.map((portolioItem: PortfolioItem) => {
      return {
        ...portolioItem,
        properties: portolioItem.properties.filter(propertyId => {
          const property = this.properties.find(({ id }) => id === propertyId)
          // TODO For some reason backend sometimes returns nonexisting property ids.
          if (!property) {
            return false
          }
          if (property.status === 'DISABLED') {
            return false
          }
          return true
        }),
      }
    })

    this.selectedPortfolioId = this.preparedPortfolios[0] ? +this.preparedPortfolios[0].id : null
  }

  mounted () {
    if (!this.portfoliosLoaded) {
      this.loadPortfolios()
    }
    if (!this.propertiesLoaded) {
      this.loadProperties()
    }
    this.preparePortfolios()
  }
}
