
import { Vue, Component } from 'vue-property-decorator'
import AuthForm from '../components/auth/AuthForm.vue'

@Component({
  components: {
    AuthForm,
  },
})
export default class Login extends Vue {

}
