
import { Vue, Component } from 'vue-property-decorator'

@Component({})
export default class ManagerSettings extends Vue {
  get pages ():{name: string, description: string[], routeName: string}[] {
    return [
      {
        name: 'Lease Periods',
        description: ['Manager your leasing periods', 'Leasing settings, assign contracts'],
        routeName: 'lease-periods',
      },
      {
        name: 'Layouts',
        description: ['Manage Building Layouts', 'Add Units, Rooms, and Beds'],
        routeName: 'layouts',
      },
      {
        name: 'Payment Plans',
        description: ['Manage Payment Plans', 'Change pricing, create new plans'],
        routeName: 'payment-plans',
      },
      {
        name: 'Assign Plans',
        description: ['Assign Approved Payment Plans', 'New, renewal, and renewal transfer user plans'],
        routeName: 'assign-plans',
      },
      {
        name: 'Document Templates',
        description: ['Create and manage reusable templates', 'Email, SMS, and Contract templates'],
        routeName: 'document-templates',
      },
      {
        name: 'Rental Application',
        description: ['Manage your rental application', 'Set required and non-required fields'],
        routeName: 'rental-application',
      },
      {
        name: 'Property Webpage',
        description: ['Manage your property marketing page', 'Add images, videos, and descriptions'],
        routeName: 'property-webpages',
      },
      {
        name: 'Company Webpage',
        description: ['Manage your company webpage', 'Add portfolios, properties and company information'],
        routeName: 'company-webpage',
      },
    ].sort((a, b) => (a.name > b.name) ? 1 : -1)
  }
}
