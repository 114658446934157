
import { Vue, Component, Prop } from 'vue-property-decorator'
import { DocumentTemplate } from '../../middleware/entities'

@Component({
  components: {},
})
export default class ManagerSettingsDocumentTemplatesDetails extends Vue {
  @Prop({ type: DocumentTemplate, required: true }) readonly documentTemplate!: DocumentTemplate
}
