
import { Vue, Component } from 'vue-property-decorator'
import { ICellRendererParams } from 'ag-grid-community'

@Component({})
export default class PaymentPlansActionColumn extends Vue {
  params!: ICellRendererParams

  get parent () {
    return this.params.context.componentParent
  }

  get paymentPlanEditable () {
    return !this.params.data.approved
  }

  clonePaymentPlan () {
    this.parent.openEditPaymentPlanDialog(this.params.data)
  }
}
