import { executeApiRequest } from './../middleware/api'
import { API } from './endpoints'

const getPropertyMedia = async (prop?: string) => executeApiRequest({ method: 'get', url: API.PROPERTY_BG_MEDIA.BASE, params: { prop } })
const changePropertyMediaOrder = async (id: string, order) => executeApiRequest({ method: 'post', url: API.PROPERTY_BG_MEDIA.MOVE_TO(id), data: { order } })
const uploadPropertyMedia = async (media: FormData) => executeApiRequest({ method: 'post', url: API.PROPERTY_BG_MEDIA.BASE, data: media })
const deletePropertyMedia = async (id?: string) => executeApiRequest({ method: 'delete', url: API.PROPERTY_BG_MEDIA.SINGLE(id) })

const getOrganizationMedia = async (organization?: string) => executeApiRequest({ method: 'get', url: API.ORGANIZATION_BG_MEDIA.BASE, params: { organization } })
const changeOrganizationMediaOrder = async (id: string, order) => executeApiRequest({ method: 'post', url: API.ORGANIZATION_BG_MEDIA.MOVE_TO(id), data: { order } })
const uploadOrganizationMedia = async (media: FormData) => executeApiRequest({ method: 'post', url: API.ORGANIZATION_BG_MEDIA.BASE, data: media })
const deleteOrganizationMedia = async (id: string) => executeApiRequest({ method: 'delete', url: API.ORGANIZATION_BG_MEDIA.SINGLE(id) })

export default {
  getPropertyMedia,
  changePropertyMediaOrder,
  uploadPropertyMedia,
  deletePropertyMedia,
  getOrganizationMedia,
  changeOrganizationMediaOrder,
  uploadOrganizationMedia,
  deleteOrganizationMedia,
}
