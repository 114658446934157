
import { Vue, Component } from 'vue-property-decorator'
import { Image } from '../middleware/entities'
import { Property } from '../middleware/Property'
import { MediaItem, extensions } from '../middleware/MediaItem'
import { Action, Getter } from 'vuex-class'
import ManagerSettingsEditorSubmenu from '../components/manager/ManagerSettingsEditorSubmenu.vue'
import GalleryEditor from '../components/manager/GalleryEditor.vue'
import AmenitiesEditor from '../components/manager/AmenitiesEditor.vue'
import FileUploader from '../components/common/FileUploader.vue'
import WebpageModelEditor from '../components/manager/WebpageModelEditor.vue'
import TestimonialsEditor from '../components/manager/TestimonialsEditor.vue'
import InstagramWidget from '../components/common/InstagramWidget.vue'
import PropertyFriends from '../components/landing/PropertyFriends.vue'
import ResourcesEditor from '../components/manager/ResourcesEditor.vue'
import { WebsiteBlockAttr } from '../middleware/WebsiteBlockAttr'
import { Amenity } from '../middleware/Amenity'
import PropertyLeasePeriodsOld
  from '../components/landing/PropertyLeasePeriodsOld.vue'
import { PropertyImage } from '../middleware/PropertyImage'

const namespace: string = 'property'

const websiteBlockAttrs = [
  'lease-periods',
  'friends',
  'about',
  'gallery',
  'features',
  'additional-resources',
  'location',
  'latest-posts',
  'what-people-say',
  'how-it-works',
  'contact-form',
]
@Component({
  components: {
    PropertyLeasePeriodsOld,
    ManagerSettingsEditorSubmenu,
    FileUploader,
    GalleryEditor,
    AmenitiesEditor,
    WebpageModelEditor,
    TestimonialsEditor,
    InstagramWidget,
    PropertyFriends,
    ResourcesEditor,
  },
})
export default class ManagerSettingsPropertyEditor extends Vue {
  @Getter('singleProperty', { namespace }) singleProperty: (id: number | string) => Property;
  @Getter('propertyLoading', { namespace }) propertyLoading: any;
  @Getter('propertyImages', { namespace }) propertyImages: PropertyImage[];
  @Getter('amenities', { namespace }) amenities: Amenity[];

  @Action('loadSingleProperty', { namespace }) loadSingleProperty: any;
  @Action('togglePropertiesLoading', { namespace }) togglePropertiesLoading: any;

  loadedBackgroundMedia: File[] = []
  deletedBackgroundMedia: Set<MediaItem> = new Set()
  reorderedBackgroundMedia: MediaItem[] = []
  loadedPrimaryImage: File[] = []
  loadedPropertyImages: File[] = []
  deletedPropertyImages: Set<Image> = new Set()
  reorderedPropertyImages: Image[] = []
  changedFields: Set<string> = new Set()
  websiteBlockAttrs: { [key: string]: WebsiteBlockAttr} = {}
  changedWebsiteBlockAttrs: Set<string> = new Set()
  errors: {[key: string]: string[]}
  submenuLinks = [
    {
      href: 'header-media',
      text: 'Header media',
      icon: 'image',
    },
    {
      href: 'primary-image',
      text: 'Primary property image',
      icon: 'wallpaper',
    },
    {
      href: 'lease-periods',
      text: 'Lease periods',
      icon: 'event_note',
    },
    {
      href: 'friends-and-people',
      text: 'Friends and people',
      icon: 'insert_emoticon',
    },
    {
      href: 'about',
      text: 'About',
      icon: 'notes',
    },
    {
      href: 'gallery',
      text: 'Gallery',
      icon: 'collections',
    },
    {
      href: 'features',
      text: 'Features',
      icon: 'business',
    },
    {
      href: 'additional-resources',
      text: 'Additional Resources',
      icon: 'business',
    },
    {
      href: 'address',
      text: 'Address information',
      icon: 'location_on',
    },
    {
      href: 'testimonials',
      text: 'Testimonials',
      icon: 'supervisor_account',
    },
    {
      href: 'contacts',
      text: 'Contact information',
      icon: 'phone',
    },
    {
      href: 'social-networks',
      text: 'Social networks',
      icon: 'public',
    },
    {
      href: 'webpage-model',
      text: 'Webpage model',
      icon: 'description',
    },
  ]

  get property () {
    const property = this.singleProperty(+this.$route.params.id)
    if (!property) return null
    return new Property({
      ...property,
      property_images: property.property_images.map(i => ({ ...i, image: i.image.charAt(0) === '/' ? i.image.substr(1).replace('media//', 'media/') : i.image.replace('media//', 'media/') })),
    })
  }

  async mounted () {
    if (!this.property || !this.property.full) {
      this.loadSingleProperty(+this.$route.params.id)
    }
    const data = (await this.$api.websiteBlockAttr.getPropertyWebsiteBlockAttrs(this.$route.params.id))
    this.websiteBlockAttrs = websiteBlockAttrs
      .map(attr => data.find(({ block_name }) => block_name === attr))
      .reduce((cur, block) => {
        cur[block.block_name] = { ...block }
        return cur
      }, {})
  }

  get backgroundMedia () {
    return this.property
      ? this.property.background_media.map(media => ({
        ...media,
        url: media.file,
        type: (extensions.find(item => item.formats.includes(media.file.split('.').pop())) || { type: '' }).type,
      }))
      : []
  }

  get primaryImage () {
    return [{
      url: this.property.background_image,
      type: 'image',
    }]
  }

  deleteBackgroundMedia (deletedImage) {
    this.deletedBackgroundMedia.add(this.property.background_media.find(({ file }) => file === deletedImage.file))
  }

  deletePropertyImage (deletedImage) {
    this.deletedPropertyImages.add(this.property.property_images.find(({ id }) => id === deletedImage.id))
  }

  async saveProperty () {
    this.togglePropertiesLoading()
    const savingProperty: Partial<Property> = {
      id: this.property.id,
    }
    this.changedFields.forEach((field: string) => {
      if (field !== 'background_media' && field !== 'property_images') {
        savingProperty[field] = this.property[field]
      }
    })
    try {
      if (Object.keys(savingProperty).length > 1) {
        await this.$api.property.save(savingProperty)
      }
      if (this.loadedBackgroundMedia.length || this.deletedBackgroundMedia.size || this.reorderedBackgroundMedia.length) {
        await this.loadBgMedia()
      }
      if (this.loadedPrimaryImage.length) {
        await this.loadPrimaryImage()
      }
      await this.savePropertyImageOrder()
      await this.saveAmenityOrder()
      await this.loadWebsiteBlockAttrs()
      this.loadSingleProperty(+this.$route.params.id)
    } catch (e) {
      this.errors = { ...e }
      await this.$store.dispatch('ui/showError', "Couldn't save property")
    } finally {
      this.clearForm()
      this.$store.dispatch('ui/showSuccess', 'Your changes have successfully saved')
    }
  }

  clearForm () {
    this.changedFields.clear()
    this.loadedBackgroundMedia = []
    this.deletedBackgroundMedia.clear()
    this.loadedPrimaryImage = []
    this.loadedPropertyImages = []
    this.deletedPropertyImages.clear()
    // @ts-ignore
    this.$refs.backgroundMedia && this.$refs.backgroundMedia.reload()
    // @ts-ignore
    this.$refs.primaryImage && this.$refs.primaryImage.reload()
    // @ts-ignore
    this.$refs.propertyImages && this.$refs.propertyImages.reload()
    this.togglePropertiesLoading()
  }

  async loadBgMedia () {
    await Promise.all(this.loadedBackgroundMedia
      .map(media => {
        const formData = new FormData()
        formData.append('prop', `${this.property.id}`)
        formData.append('file', media)
        return this.$api.bgMedia.uploadPropertyMedia(formData)
      })
      .concat(Array.from(this.deletedBackgroundMedia).map(media =>
        this.$api.bgMedia.deletePropertyMedia(media.id),
      ))
      .concat(this.reorderedBackgroundMedia.reduce((res, { order, id }, idx) => {
        const defaultMedia = this.backgroundMedia[idx]

        if (order !== defaultMedia.order || id !== defaultMedia.id) {
          res.push(this.$api.bgMedia.changePropertyMediaOrder(id, order))
        } return res
      }, [])),
    )
  }

  async loadPropertyImages () {
    try {
      const deletedPropertiesIds = []
      this.deletedPropertyImages.forEach(({ id }) => { deletedPropertiesIds.push(id) })
      await Promise.all(deletedPropertiesIds.map(id => this.$api.property.deleteImage(id)))
      // we need to set the order of every media in case if it is not unique
      // synchronous requests cannot be used here due to backend restrictions
      const uniquePropertyImage = new Set(this.property.property_images.map(a => a.order))
      if (uniquePropertyImage.size !== this.property.property_images.length) {
        for (const idx in this.property.property_images) {
          await this.$api.property.changeImageOrder(this.property.property_images[idx].id, +idx)
        }
      } else {
        for (const idx in this.reorderedPropertyImages) {
          const defaultMedia = this.property.property_images[idx]
          const { id, order } = this.reorderedPropertyImages[idx]
          if (id !== defaultMedia.id || order !== defaultMedia.order) {
            if (!deletedPropertiesIds.includes(id)) {
              await this.$api.property.changeImageOrder(id, order)
            }
          }
        }
      }
      const loadedProperties = []
      this.loadedPropertyImages.forEach((image) => {
        const formData = new FormData()
        formData.append('image', image)
        loadedProperties.push(formData)
      })
      await Promise.all(loadedProperties.map(formData => this.$api.property.loadImage(this.property.id + '', formData)))
    } catch (e) {
      await this.$store.dispatch('ui/showError', "Couldn't save images")
    }
  }

  async loadPrimaryImage () {
    const formData = new FormData()
    formData.append('image', this.loadedPrimaryImage[0])
    await this.$api.property.loadPrimaryImage(`${this.property.id}`, formData)
  }

  async savePropertyImageOrder () {
    const uniqueAmenity = new Set(this.propertyImages.map(a => a.order))
    if (uniqueAmenity.size !== this.propertyImages.length) {
      return await Promise.all(
        this.propertyImages.map((propImage, idx) => this.$api.property.changeImageOrder(+propImage.id, idx)),
      )
    } else {
      return await Promise.all(
        this.propertyImages
          .filter(propImage => propImage.initialOrder && propImage.order !== propImage.initialOrder)
          .map(propImage => this.$api.property.changeImageOrder(+propImage.id, propImage.order)),
      )
    }
  }

  async saveAmenityOrder () {
    const uniqueAmenity = new Set(this.amenities.map(a => a.order))
    if (uniqueAmenity.size !== this.amenities.length) {
      return await Promise.all(this.amenities.map((amenity, idx) => this.$api.amenity.changeOrder(amenity.id, idx)))
    } else {
      return await Promise.all(this.amenities
        .filter(amenity => amenity.initialOrder && amenity.order !== amenity.initialOrder)
        .map(amenity => this.$api.amenity.changeOrder(amenity.id, amenity.order)))
    }
  }

  async loadWebsiteBlockAttrs () {
    const website_block_attrs = Object.keys(this.websiteBlockAttrs).map(block => this.websiteBlockAttrs[block])
    const changedWebsiteBlockAttrs = []
    const shouldBeCreated = []
    website_block_attrs.forEach(({ visibility, description, block_name, id }) => {
      const initial = this.property.website_block_attrs.find(item => item.id === id)

      if (!initial.id) {
        shouldBeCreated.push(this.websiteBlockAttrs[block_name])
      } else if (initial.visibility !== visibility || initial.description !== description) {
        changedWebsiteBlockAttrs.push(this.websiteBlockAttrs[block_name])
      }
    })
    await Promise.all(shouldBeCreated
      .map(({ block_name, description, visibility }) => (
        this.$api.websiteBlockAttr.createPropertyWebsiteBlockAttr({ block_name, description, visibility, prop: this.property.id })
      )).concat(changedWebsiteBlockAttrs
        .map(block => (
          this.$api.websiteBlockAttr.editPropertyWebsiteBlockAttrs(`${block.id}`, block)
        ))),
    )
  }
}
