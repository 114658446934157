
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { MediaItem } from '../../middleware/MediaItem'
@Component({
  components: {
    VueDropzone: vue2Dropzone,
  },
})
export default class ImageUploader extends Vue {
  @Prop({ type: Array }) readonly defaultValue!: (MediaItem|string)[]
  @Prop({ type: Boolean }) readonly multiple!: boolean
  @Prop({ type: Boolean }) readonly readonly!: boolean
  @Prop({ type: Boolean }) readonly showDeleteIconOnDefaultValue!: boolean
  @Prop({ type: Boolean }) readonly sortable!: boolean
  @Prop({ type: Boolean }) readonly imageOnly!: boolean
  @Prop({ type: Array }) readonly value!: File[]
  preloadedValue: (MediaItem|string)[] = []
  preloadedValueWasSetted: boolean = false
  dragging: boolean = false
  options: {[key: string]: any} = {
    url: '#',
    autoQueue: false,
    addRemoveLinks: true,
    maxFiles: 1,
    previewTemplate: this.template(),
  }

  created () {
    if (this.imageOnly) {
      this.options.acceptedFiles = 'image/*, video/*'
    }
  }

  async mounted () {
    if (this.multiple && this.$refs.myVueDropzone) {
      // @ts-ignore
      this.$refs.myVueDropzone.setOption('maxFiles', null)
    }
    if (!this.preloadedValueWasSetted && this.defaultValue) {
      this.preparePreloadedValue(this.defaultValue)
      this.preloadedValueWasSetted = true
    }
  }

  @Watch('defaultValue')
  onDefaultValueChange (newVal: { type: string, url: string }[]) {
    this.preparePreloadedValue(newVal)
  }

  preparePreloadedValue (val) {
    const shouldBeSorted = this.sortable && val.every(item => item instanceof Object && item.order)
    this.preloadedValue = shouldBeSorted ? val.slice().sort((a, b) => a.order - b.order) : [...val]
  }

  get files () {
    return this.value
  }

  set files (files) {
    this.$emit('input', files)
  }

  fileAdded (file) {
    this.files.push(file)
    if (this.preloadedValue.length && !this.multiple) {
      this.preloadedValue = []
    }
  }

  removedFile (file) {
    this.files = this.files.filter(loadedFile => loadedFile.name !== file.name)
  }

  deletePreloadedValue (media) {
    this.preloadedValue = this.preloadedValue.filter(preloadedVal => typeof preloadedVal === 'object' ? preloadedVal.url !== media.url : preloadedVal !== media)
    this.$emit('delete', media)
  }

  changeOrder (dir, idx) {
    if ((idx === 0 && dir === 'up') || (idx === this.preloadedValue.length - 1 && dir === 'down')) return
    const directionIdx = dir === 'up' ? -1 : 1
    const temp = typeof this.preloadedValue[idx + directionIdx] === 'object' ? { ...(this.preloadedValue[idx + directionIdx] as any), order: idx } : this.preloadedValue[idx + directionIdx]

    this.preloadedValue[idx + directionIdx] = typeof this.preloadedValue[idx] === 'object' ? { ...(this.preloadedValue[idx] as any), order: idx + directionIdx } : this.preloadedValue[idx]
    this.preloadedValue[idx] = temp
    this.preloadedValue = this.preloadedValue.slice()
    this.$emit('re-order', this.preloadedValue)
  }

  reload () {
    this.preloadedValueWasSetted = false
    // @ts-ignore
    this.$refs.myVueDropzone.removeAllFiles()
  }

  template () {
    return `<div class="dz-preview dz-file-preview">
      <div class="dz-details">
        <img data-dz-thumbnail class="dz-details__image mr-2"/>
        <div class="dz-details__image-info">
          <div class="dz-filename"><span data-dz-name></span></div>
          <div class="dz-size" data-dz-size></div>
          <div class="dz-error-message"><span data-dz-errormessage></span></div>
        </div>
      </div>
      <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
      <a class="dz-remove v-icon v-icon--link material-icons theme--light" href="javascript:undefined;" data-dz-remove="">delete</a>
    </div>`
  }
}
