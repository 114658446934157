import { Property } from './../middleware/Property'
import { executeApiRequest } from '../middleware/api'
import axiosInstance from '../middleware/axios'
import { API } from './endpoints'

const list = async (ids?: number[]) => {
  let data = []
  if (!ids) {
    data = await executeApiRequest({ method: 'get', url: API.PROPERTIES.BASE })
  } else {
    data = await Promise.all(ids.map((id: number) => axiosInstance.get(API.PROPERTIES.SINGLE(id))))
  }
  return data.map((property: any) => new Property(property))
}
const get = async (id: number): Promise<Property> => {
  const data = await executeApiRequest({ method: 'get', url: API.PROPERTIES.SINGLE(id) })
  return new Property(data)
}
const save = async (property: {[key: string]: any}) => (
  executeApiRequest({ method: 'patch', url: API.PROPERTIES.UPDATE(property.id), data: property })
)
const loadImage = async (propertyId: string, image: FormData) => (
  executeApiRequest({ method: 'post', url: API.PROPERTIES.UPLOAD_PROPERTY_IMAGES(propertyId), data: image })
)
const changeImageOrder = async (imageId: number, order: number) => (
  executeApiRequest({ method: 'post', url: API.PROPERTIES.MOVE_TO_PROPERTY_IMAGE(imageId), data: { order } })
)

const updateImage = async (imageId: string, image: any) => (
  executeApiRequest({ method: 'patch', url: API.PROPERTIES.UPDATE_PROPERTY_IMAGE(imageId), data: image })
)

const deleteImage = async (imageId: string) => (
  executeApiRequest({ method: 'delete', url: API.PROPERTIES.DELETE_PROPERTY_IMAGE(imageId) })
)
const loadPrimaryImage = async (id: string, formData: FormData) => (
  executeApiRequest({ method: 'post', url: API.PROPERTIES.UPLOAD_HEADER(id), data: formData })
)

const sendContactForm = async (id: string, formData: any) => (
  executeApiRequest({ method: 'post', url: API.PROPERTIES.CONTACT(id), data: formData })
)

export default {
  list,
  get,
  save,
  loadImage,
  changeImageOrder,
  updateImage,
  deleteImage,
  loadPrimaryImage,
  sendContactForm,
}
