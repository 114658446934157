
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
// @ts-ignore
// noinspection TypeScriptCheckImport
import Quill, { Delta as DeltaInterface } from 'quill'
import { insertVariable } from '../quill-variable'
import { insertContentBlock, getContentBlockLength } from '../quill-content-block'

const Delta = Quill.import('delta')

@Component({})
export default class ManagerSettingsDocumentTemplatesEditorBodyEditor extends Vue {
  @Prop({ required: true, type: String }) readonly type!: string
  @Prop({ required: true, type: Boolean }) readonly loading!: boolean
  @Prop({ required: true, type: Boolean }) readonly disabled!: boolean
  @Prop({ required: true, type: Array }) readonly variables!: { name: string, value: string }[]
  @Prop({ required: true, type: Array }) readonly contentBlocks!: { name: string, value: string }[]
  @Prop({ required: true, type: Array }) readonly signatureTags!: { name: string, value: string, color?: string }[]

  editor: any = null!
  editorFocused = false
  messageLength: number = 0

  errorMessage: string = ''
  validationUnlocked = false

  activeFormats: {
    header?: boolean | string,
    align?: boolean | string,
    link?: string,
  } = {}

  stack = {
    undo: [],
    redo: [],
  }

  // noinspection JSUnusedGlobalSymbols
  variablesMenuOpened = false
  // noinspection JSUnusedGlobalSymbols
  contentBlocksMenuOpened = false
  // noinspection JSUnusedGlobalSymbols
  signatureTagsMenuOpened = false

  linkMenuOpened = false
  pastedLink = ''

  imageMenuOpened = false
  selectedImageFile: File | null = null
  selectedImageLink: string | null = null

  mounted () {
    this.initEditor()
  }

  @Watch('type')
  onTypeChange () {
    this.initEditor()
  }

  @Watch('disabled')
  onDisabledChange (newVal: boolean) {
    if (!this.editor) return
    newVal ? this.editor.disable() : this.editor.enable()
  }

  initEditor () {
    let formats

    switch (this.type) {
      case 'Lease Agreement':
        formats = [
          'header',
          'align',
          'bold',
          'italic',
          'link',
          'list',
          'image',
          'blockquote',
          // "table",
          'video',
          'Variable',
          'ContentBlock',
        ]
        break

      case 'Email Template':
        formats = [
          'header',
          'align',
          'bold',
          'italic',
          'link',
          'list',
          'image',
          'blockquote',
          'video',
          'Variable',
        ]
        break

      case 'SMS Template':
        formats = ['Variable']
        break
    }

    this.editor = new Quill(this.$refs.editor, {
      debug: process.env.NODE_ENV === 'production' ? false : 'warn',
      modules: { toolbar: false },
      formats,
    })

    delete this.editor.getModule('keyboard').bindings[13]
    delete this.editor.getModule('keyboard').bindings[9]
    document.querySelector('.manager-settings-document-templates-editor-body-editor .ql-editor')!
      .setAttribute('tabindex', '0');

    (this.editor.on as any)('selection-change', (event: any) => {
      if (event !== null) {
        this.activeFormats = this.editor.getFormat()
        this.stack = this.editor.history.stack
        this.editorFocused = true
        this.onFocus()
      } else {
        this.editorFocused = false

        if (this.validationUnlocked) {
          this.validate()
        } else {
          this.validationUnlocked = true
          this.validate()
        }
      }
    });

    (this.editor.on as any)('text-change', (delta: DeltaInterface, oldContent: DeltaInterface, source: string) => {
      // prevent adding empty extra variable on enter click
      if (source === 'user') {
        const filtered = delta.ops.filter(({ insert }: { insert: any }) => insert ? insert.Variable || insert.ContentBlock || insert === '\n' : false)

        if (filtered.length >= 2) {
          this.editor.deleteText(this.editor.getSelection().index - 2, 1)
        }
      }
      this.activeFormats = this.editor.getFormat()
      this.updateMessageLength()
      this.emitChanges()
      this.validate()
    })

    if (this.disabled) {
      this.editor.disable()
    }
  }

  getValue (): any[] {
    return this.editor.getContents().ops
  }

  setValue (value: any[]) {
    this.editor.setContents(value)
  }

  emitChanges () {
    this.$emit('change', this.getValue())
  }

  validate () {
    if (this.messageLength < 10) {
      this.errorMessage = 'Template Body must be at least 10 characters long'
      return false
    }

    this.errorMessage = ''
    return true
  }

  updateMessageLength () {
    const insertedVariables = this.getVariables()
    const variablesCommonLength = insertedVariables
      .map((insertedVariable: { name: string, value: string, color?: string }) => insertedVariable.name.length)
      .reduce((acc, curr) => acc + curr, 0)
    const insertedContentBlocks = this.getContentBlocks()
    const contentBlocksCommonLength = insertedContentBlocks
      .map((insertedContentBlock: { name: string, value: string }) => getContentBlockLength(insertedContentBlock))
      .reduce((acc, curr) => acc + curr, 0)
    const insertedEmbeddedContentCount = insertedVariables.length + insertedContentBlocks.length
    this.messageLength = this.editor.getLength() - insertedEmbeddedContentCount + variablesCommonLength + contentBlocksCommonLength - 1 // `-1` for trailing \n
  }

  getVariables () {
    return this.getValue()
      .filter(op => op.insert.Variable)
      .map(op => JSON.parse(op.insert.Variable))
  }

  getContentBlocks () {
    return this.getValue()
      .filter(op => typeof op.insert === 'object' && 'ContentBlock' in op.insert)
      .map(op => JSON.parse(op.insert.ContentBlock))
  }

  get headerValue () {
    return ('header' in this.activeFormats) ? this.activeFormats.header : false
  }

  get alignValue () {
    return ('align' in this.activeFormats) ? this.activeFormats.align : false
  }

  header (value = false) {
    this.editor.format('header', value)
  }

  align (value = false) {
    this.editor.format('align', value)
  }

  bold (value = false) {
    this.editor.format('bold', value)
  }

  italic (value = false) {
    this.editor.format('italic', value)
  }

  get linkModel () {
    if ('link' in this.activeFormats) {
      return this.activeFormats.link!
    } else {
      return this.pastedLink
    }
  }

  set linkModel (value: string) {
    if ('link' in this.activeFormats) {
      this.activeFormats.link = value
    } else {
      this.pastedLink = value
    }
  }

  link () {
    const range = this.editor.selection.savedRange
    if (!range || range.length === 0) return

    if (this.pastedLink.length) {
      this.editor.format('link', this.pastedLink)
    } else {
      this.editor.format('link', false)
    }

    this.pastedLink = ''
    this.linkMenuOpened = false
  }

  list (value = false) {
    this.editor.format('list', value)
  }

  async image () {
    const toBase64 = (file: File) => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })

    const range = this.editor.selection.savedRange
    if (!range || range.length !== 0) return
    const cursorPosition = range.index

    if (this.selectedImageFile) {
      this.editor.insertEmbed(cursorPosition, 'image', await toBase64(this.selectedImageFile))
    } else if (this.selectedImageLink) {
      this.editor.insertEmbed(cursorPosition, 'image', this.selectedImageLink)
    }

    this.selectedImageFile = null
    this.selectedImageLink = null
    this.imageMenuOpened = false
  }

  blockquote (value = false) {
    this.editor.format('blockquote', value)
  }

  undo () {
    this.editor.history.undo()
  }

  redo () {
    this.editor.history.redo()
  }

  insertContentBlock (contentBlock: { name: string, value: string }) {
    if (this.disabled) return
    insertContentBlock(this.editor, contentBlock)
  }

  insertVariable (variable: { name: string, value: string, color?: string }) {
    if (this.disabled) return
    insertVariable(this.editor, variable)
  }

  onFocus () {
    this.editor.focus()
    this.$emit('focus')
  }
}
