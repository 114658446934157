
import { Component, Vue, Prop } from 'vue-property-decorator'
import { LeasePeriod } from '../../middleware/LeasePeriod'
import LeasePeriodSemesterSelect from './LeasePeriodSemesterSelect.vue'
import PropertySelect from './PropertySelect.vue'

@Component({
  components: { PropertySelect, LeasePeriodSemesterSelect },
})
export default class LeasePeriodEdit extends Vue {
  @Prop({ required: true }) leasePeriod!: LeasePeriod
}
