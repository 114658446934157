// @ts-ignore
import Quill from 'quill'
import store from '../store/store'

const Embed = Quill.import('blots/embed')
const Delta = Quill.import('delta')

let currentEditor: any

function quillGetHTML (inputDelta: any) {
  const tempCont = document.createElement('div');

  (new Quill(tempCont)).setContents(inputDelta)
  return tempCont.getElementsByClassName('ql-editor')[0].innerHTML
}

class ContentBlock extends Embed {
  static blotName = 'ContentBlock';
  static tagName = 'span';
  static className = 'quill-content-block';

  static create (contentBlock: { name: string, value: string }) {
    const computedContentBlock = typeof contentBlock === 'object' ? contentBlock : JSON.parse(contentBlock)
    const existingContentBlocks: string[] = currentEditor.getContents()
      .filter((op: any) => {
        return typeof op.insert === 'object' && 'ContentBlock' in op.insert
      })
      .map((op: any) => {
        return op.insert.ContentBlock
      })

    if (existingContentBlocks.includes(computedContentBlock.name)) {
      return store.dispatch('ui/showError', 'Content blocks must be unique')
    }
    const node = super.create(computedContentBlock.name)
    let contentBlockHTML: string = ''
    if (typeof computedContentBlock.value === 'object') {
      try {
        contentBlockHTML = quillGetHTML(new Delta(computedContentBlock.value))
      } catch (e) {
        console.warn('wrong content block format')
        contentBlockHTML = computedContentBlock.value
      }
    } else {
      contentBlockHTML = computedContentBlock.value
    }

    node.innerHTML = `<strong>${computedContentBlock.name}</strong><span>${contentBlockHTML}</span>`
    node.setAttribute('spellcheck', 'false')
    node.setAttribute('autocomplete', 'off')
    node.setAttribute('autocorrect', 'off')
    node.setAttribute('autocapitalize', 'off')

    node.setAttribute('data-contentblock', JSON.stringify(computedContentBlock))

    return node
  }

  static value (domNode: HTMLElement) {
    return domNode.dataset.contentblock
  }

  update (mutations: any, context: any) { // this method has something to do with some GKeyboard bug on androids
    for (const mutation of mutations) {
      if (mutation.type !== 'childList') continue
      if (mutation.removedNodes.length === 0) continue

      const nodeType = mutation.removedNodes[0].nodeType
      return setTimeout(() => this._remove(nodeType), 0)
    }

    const unhandledMutations = mutations.filter((m: any) => m.type !== 'childList')
    super.update(unhandledMutations, context)
  }

  _remove!: (nodeType: any) => any;
}

// noinspection JSIgnoredPromiseFromCall
Quill.register({
  'formats/ContentBlock': ContentBlock,
})

export function insertContentBlock (editor: Quill, contentBlock: { name: string, value: string }) {
  currentEditor = editor

  ContentBlock.prototype._remove = function (nodeType: any) {
    let adjust = 0
    if (nodeType === Node.TEXT_NODE) adjust = -1

    const cursorPosition = editor.getSelection().index + adjust
    // @ts-ignore
    this.remove()

    setTimeout(() => editor.setSelection(cursorPosition, Quill.sources.API), 0)
  }

  const range = editor.selection.savedRange
  if (!range || range.length !== 0) return

  const cursorPosition = range.index

  editor.insertEmbed(cursorPosition, 'ContentBlock', contentBlock, Quill.sources.API)
  editor.insertText(cursorPosition + 1, ' ', Quill.sources.API)
  editor.setSelection(cursorPosition + 2, Quill.sources.API)
}

export function getContentBlockLength (contentBlock: { name: string, value: string }) {
  const deltaValue = new Delta(contentBlock.value)
  // if content block contains variables
  const insertedVariablesLength = deltaValue
    .filter((op: any) => op.insert.Variable)
    .map((op: any) => op.insert.Variable.name.length)
    .reduce((acc: number, curr: string) => acc + curr, 0)
  return contentBlock.name.length + deltaValue.length() + insertedVariablesLength + 1
}
