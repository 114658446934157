
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Image } from '../../middleware/entities'

import GalleryWidget from '../common/GalleryWidget.vue'

@Component({
  components: {
    GalleryWidget,
  },
})
export default class PropertyGallery extends Vue {
  @Prop({ type: Array }) images!: Image[];
  @Prop({ type: String }) description!: string;

  get preparedImages () {
    if (!this.images || !this.images.length) return []
    return this.images.sort((a, b) => a.order - b.order).reduce((acc, i) => {
      acc.push({ url: i.image })
      return acc
    }, [])
  }
}
