import { completeOrganization } from './api'
/* eslint no-console: 0 */

import axios from './axios'
import * as T from './entities'
import { API } from '../api/endpoints'
import { RentalApplication } from './entities'
import { PaymentPlan } from './PaymentPlan'

// TODO This is not an ideal implementation.
// Should be modularized in some way.
const API_tmp = {
  manager: {
    async getOrganization (): Promise<T.Organization> {
      try {
        const data = (await axios.get(API.ORGANIZATIONS.BASE)).data[0]

        return completeOrganization(new T.Organization(data))
      } catch (e) {
        console.log('Error in getOrganizationData:')
        console.error(e)
        throw e
      }
    },

    settings: {
      layouts: {
        async getLayouts (propertyIds: number[]): Promise<T.Layout[]> {
          if (!propertyIds.length) return Promise.resolve([])

          try {
            /* MOCK DATA */
            const data: Partial<T.Layout>[] = await new Promise((resolve, reject) => { // mock
              setTimeout(() => {
                resolve(
                  Array.from(new Array(10 * propertyIds.length), (c, i) => {
                    return {
                      id: i,
                      property_id: 0,
                      building: `Room ${Math.random() > 0.5 ? 'South' : 'North'} Wing`,
                      floor: Math.random() * 25 | 0,
                      unit: `10${i}`,
                      unit_type: Math.random() > 0.5 ? 'Gold' : 'Silver',
                      room: `${i} `,
                      room_type: Math.random() > 0.5 ? 'Gold' : 'Silver',
                      bed: `Twin ${Math.random() > 0.5 ? '1' : '2'}`,
                    }
                  }),
                )
              }, 1000)
            })
            /* /MOCK DATA */

            // let data: Partial<T.Layout>[] = (await axios.get("/api/xxx/get-layouts", {
            //   params: {
            //     property_ids: propertyIds,
            //   },
            // })).data;

            return data.map((rawLayout: Partial<T.Layout>) => new T.Layout(rawLayout))
          } catch (e) {
            console.info('Error in getLayouts:')
            console.error(e)
            throw e
          }
        },

        async createLayout (layout: T.Layout): Promise<number> {
          try {
            /* MOCK DATA */
            return await new Promise((resolve, reject) => { // mock
              setTimeout(() => {
                resolve(Math.random() * 1000 | 0)
              }, 1000)
            })
            /* /MOCK DATA */

            // return (await axios.post("/api/xxx/create-layout", {
            //   layout,
            // })).data;
          } catch (e) {
            console.info('Error in createLayout:')
            console.error(e)
            throw e
          }
        },

        async updateLayout (layout: T.Layout): Promise<void> {
          try {
            /* MOCK DATA */
            await new Promise((resolve, reject) => { // mock
              setTimeout(() => {
                resolve()
              }, 1000)
            })
            /* /MOCK DATA */

            // await axios.put("/api/xxx/update-layout", {
            //   layout,
            // });
          } catch (e) {
            console.info('Error in updateLayout:')
            console.error(e)
            throw e
          }
        },

        async deleteLayout (layoutId: number): Promise<void> {
          try {
            /* MOCK DATA */
            await new Promise((resolve, reject) => { // mock
              setTimeout(() => {
                resolve()
              }, 1000)
            })
            /* /MOCK DATA */

            // await axios.delete("/api/xxx/delete-layout", {
            //   layout_id: layoutId,
            // });
          } catch (e) {
            console.info('Error in deleteLayout:')
            console.error(e)
            throw e
          }
        },
      },
      assignPlans: {
        async getPaymentPlans (leasePeriodIds: number[]): Promise<PaymentPlan[]> {
          if (!leasePeriodIds.length) return Promise.resolve([])

          try {
            /* MOCK DATA */
            const data: Partial<PaymentPlan>[] = await new Promise((resolve, reject) => { // mock
              setTimeout(() => {
                resolve(
                  Array.from(new Array(30), (c, i) => {
                    let type

                    if (i < 10) {
                      type = 'New Tenant'
                    } else if (i >= 10 && i < 20) {
                      type = 'Renewal Tenant'
                    } else if (i >= 20 && i < 30) {
                      type = 'Renewal Transfer Tenant'
                    }

                    return {
                      id: i,
                      name: `Payment Plan #${i}`,
                      type,
                      lease_period_id: Math.random() * 6 | 0,
                    }
                  }),
                )
              }, 1000)
            })
            /* /MOCK DATA */

            // let data: Partial<PaymentPlan>[] = (await axios.get("/api/xxx/get-payment-plans", {
            //   params: {
            //     lease_period_ids: leasePeriodIds,
            //   },
            // })).data;

            return data.map((rawPaymentPlan: Partial<PaymentPlan>) => new PaymentPlan(rawPaymentPlan))
          } catch (e) {
            console.info('Error in getPaymentPlans:')
            console.error(e)
            throw e
          }
        },

        async getPaymentPlanAssignments (propertyIds: number[]): Promise<T.PaymentPlanAssignment[]> {
          if (!propertyIds.length) return Promise.resolve([])

          try {
            /* MOCK DATA */
            const data: Partial<T.PaymentPlanAssignment>[] = await new Promise((resolve, reject) => { // mock
              setTimeout(() => {
                resolve(
                  Array.from(new Array(10 * propertyIds.length), (c, i) => {
                    return {
                      id: i,
                      property_id: 0,
                      building: `Building #${i}`,
                      floor: i,
                      unit: `Unit #${i}`,
                      unit_type: ['Gold', 'Silver'][Math.random() * 2 | 0],
                      room: i,
                      room_type: ['Gold', 'Silver'][Math.random() * 2 | 0],
                      bed: `Bed #${i}`,
                      new_tenant_payment_plan_ids: Array.from(
                        new Array(Math.random() * 5 | 0), // less then 5 ids
                        () => Math.random() * 10 | 0, // each id is less then 10
                      ),
                      renewal_tenant_payment_plan_ids: Array.from(
                        new Array(Math.random() * 5 | 0), // less then 5 ids
                        () => Math.random() * 10 + 10 | 0, // each id is between 11 and 20
                      ),
                      renewal_transfer_tenant_payment_plan_ids: Array.from(
                        new Array(Math.random() * 5 | 0), // less then 5 ids
                        () => Math.random() * 10 + 20 | 0, // each id is between 21 and 30
                      ),
                    }
                  }),
                )
              }, 1000)
            })
            /* /MOCK DATA */

            // let data: Partial<T.PaymentPlanAssignment>[] = (await axios.get("/api/xxx/get-payment-plan-assignments",
            // { params: { property_ids: propertyIds, }, })).data;

            return data.map((rawPaymentPlanAssignment: Partial<T.PaymentPlanAssignment>) => new T.PaymentPlanAssignment(
              rawPaymentPlanAssignment))
          } catch (e) {
            console.info('Error in getPaymentPlanAssignments:')
            console.error(e)
            throw e
          }
        },

        async updatePaymentPlanAssignment (paymentPlanAssignment: T.PaymentPlanAssignment): Promise<void> {
          try {
            /* MOCK DATA */
            await new Promise((resolve, reject) => { // mock
              setTimeout(() => {
                resolve()
              }, 1000)
            })
            /* /MOCK DATA */

            // await axios.put("/api/xxx/update-payment-plan", {
            //   payment_plan_assignment: paymentPlanAssignment,
            // });
          } catch (e) {
            console.info('Error in updatePaymentPlanAssignment:')
            console.error(e)
            throw e
          }
        },
      },

      documentTemplates: {
        async getDocumentTemplates (): Promise<T.DocumentTemplate[]> {
          try {
            /* MOCK DATA */
            const data: Partial<T.DocumentTemplate>[] = await new Promise((resolve, reject) => { // mock
              setTimeout(() => {
                resolve(
                  Array.from(new Array(10), (c, i) => {
                    const type = ['Lease Agreement', 'Email Template', 'SMS Template'][Math.random() * 3 | 0]
                    let approved

                    if (type === 'Lease Agreement') {
                      approved = Math.random() > 0.5
                    }

                    return new T.DocumentTemplate({
                      id: i,
                      name: `Document #${i}`,
                      type,
                      approved,
                      created_by: 'Karen Kilmer',
                      created_at: '2020-06-08T22:12:59.510Z',
                      approved_by: approved ? 'Karen Kilmer' : null,
                    })
                  }),
                )
              }, 1000)
            })
            /* /MOCK DATA */

            // let data: Partial<T.DocumentTemplate>[] = (await axios.get("/api/xxx/get-document-templates")).data;

            return data.map((rawDocumentTemplate: Partial<T.DocumentTemplate>) => new T.DocumentTemplate(
              rawDocumentTemplate))
          } catch (e) {
            console.info('Error in getDocumentTemplates:')
            console.error(e)
            throw e
          }
        },

        async createDocumentTemplate (documentTemplate: T.DocumentTemplate): Promise<number> {
          try {
            /* MOCK DATA */
            return await new Promise((resolve, reject) => { // mock
              setTimeout(() => {
                resolve(Math.random() * 1000 | 0)
              }, 1000)
            })
            /* /MOCK DATA */

            // return (await axios.post("/api/xxx/create-document-template", {
            //   document_template: documentTemplate,
            // })).data;
          } catch (e) {
            console.info('Error in createDocumentTemplate:')
            console.error(e)
            throw e
          }
        },

        async cloneDocumentTemplate (documentTemplate: T.DocumentTemplate): Promise<number> {
          try {
            /* MOCK DATA */
            return await new Promise((resolve, reject) => { // mock
              setTimeout(() => {
                resolve(Math.random() * 1000 | 0)
              }, 1000)
            })
            /* /MOCK DATA */

            // return (await axios.post("/api/xxx/clone-document-template", {
            //   document_template: documentTemplate,
            // })).data;
          } catch (e) {
            console.info('Error in cloneDocumentTemplate:')
            console.error(e)
            throw e
          }
        },

        async getDocumentTemplate (documentTemplateId: number): Promise<T.DocumentTemplate> {
          try {
            /* MOCK DATA */
            const data: Partial<T.DocumentTemplate> = await new Promise((resolve, reject) => { // mock
              setTimeout(() => {
                const type = ['Lease Agreement', 'Email Template', 'SMS Template'][Math.random() * 3 | 0]
                const msg = JSON.parse(
                  '[{"insert":"A bit of bold and italic.\\n\\nA centered bulleted list:"},{"attributes":{"align":"center"},"insert":"\\n"},{"insert":"One"},{"attributes":{"align":"center","list":"bullet"},"insert":"\\n"},{"insert":"Two"},{"attributes":{"align":"center","list":"bullet"},"insert":"\\n"},{"insert":"\\nA right-aligned ordered list:"},{"attributes":{"align":"right"},"insert":"\\n"},{"insert":"One"},{"attributes":{"align":"right","list":"ordered"},"insert":"\\n"},{"insert":"Two"},{"attributes":{"align":"right","list":"ordered"},"insert":"\\n"},{"insert":"\\nBlockquote."},{"attributes":{"blockquote":true},"insert":"\\n"}]')
                let subject
                let approved

                if (type === 'Email Template') {
                  subject = msg
                }

                if (type === 'Lease Agreement') {
                  approved = Math.random() > 0.5
                }

                resolve(
                  new T.DocumentTemplate({
                    id: documentTemplateId,
                    name: `Document #${documentTemplateId}`,
                    type,
                    approved,
                    created_at: '2018-06-08T22:12:59.510Z',
                    template: {
                      subject,
                      body: msg,
                    },
                  }),
                )
              }, 1000)
            })
            /* /MOCK DATA */

            // let data: Partial<T.DocumentTemplate> = (await axios.get("/api/xxx/get-document-template", {
            //   params: {
            //     document_template_id: documentTemplateId,
            //   }
            // })).data;

            return new T.DocumentTemplate(data)
          } catch (e) {
            console.info('Error in getDocumentTemplate:')
            console.error(e)
            throw e
          }
        },

        async getVariables (): Promise<{value: string, name?: string}[]> {
          try {
            // const data: { name: string, length: number }[] = await new Promise((resolve, reject) => { // mock
            //   setTimeout(() => {
            //     resolve([
            //       {
            //         name: 'One (9)',
            //         length: 9,
            //       },

            //       {
            //         name: 'Two (14)',
            //         length: 14,
            //       },

            //       {
            //         name: 'Three (22)',
            //         length: 22,
            //       },
            //     ])
            //   }, 500)
            // })

            const data: { [key: string]: string } = (await axios.get('/api/messages/variables')).data

            return Object.keys(data).map((variableName: string) => ({ value: variableName, name: data[variableName] }))
          } catch (e) {
            console.info('Error in getVariables:')
            console.error(e)
            throw e
          }
        },

        async getContentBlocks (): Promise<string[]> {
          try {
            const data: string[] = await new Promise((resolve, reject) => { // mock
              setTimeout(() => {
                resolve([
                  'Application Data',
                  'Assignment',
                  'Another',
                ])
              }, 500)
            })

            // let data: string[] = (await axios.get("/api/xxx/get-content-blocks")).data;

            return data
          } catch (e) {
            console.info('Error in getContentBlocks:')
            console.error(e)
            throw e
          }
        },

        async updateDocumentTemplate (documentTemplate: T.DocumentTemplate): Promise<void> {
          try {
            /* MOCK DATA */
            await new Promise((resolve, reject) => { // mock
              setTimeout(() => {
                resolve()
              }, 1000)
            })
            /* /MOCK DATA */

            // await axios.put("/api/xxx/update-document-template", {
            //   document_template: documentTemplate,
            // });
          } catch (e) {
            console.info('Error in updateDocumentTemplate:')
            console.error(e)
            throw e
          }
        },

        async approveDocumentTemplate (documentTemplate: T.DocumentTemplate): Promise<void> {
          try {
            /* MOCK DATA */
            await new Promise((resolve, reject) => { // mock
              setTimeout(() => {
                resolve()
              }, 1000)
            })
            /* /MOCK DATA */

            // await axios.post("/api/xxx/approve-document-template", {
            //   document_template: documentTemplate,
            // });
          } catch (e) {
            console.info('Error in approveDocumentTemplate:')
            console.error(e)
            throw e
          }
        },
      },

      rentalApplication: {
        async getRentalApplication (): Promise<RentalApplication> {
          try {
            /* MOCK DATA */
            const data: Partial<RentalApplication> = await new Promise((resolve, reject) => { // mock
              setTimeout(() => {
                const rentalApplication = new RentalApplication({
                  id: 0,
                  organization_id: 0,
                })

                for (const field in rentalApplication) {
                  // eslint-disable-next-line
                  if (rentalApplication.hasOwnProperty(field)) {
                    // TODO Not completely sure what's going on here.
                    (rentalApplication as any)[field] = Math.random() > 0.5
                  }
                }

                resolve(rentalApplication)
              }, 1000)
            })
            /* /MOCK DATA */

            // let data: Partial<RentalApplication> = (await axios.get("/api/xxx/get-rental-application")).data;

            return new RentalApplication(data)
          } catch (e) {
            console.info('Error in getRentalApplication:')
            console.error(e)
            throw e
          }
        },

        async updateRentalApplication (rentalApplication: RentalApplication): Promise<void> {
          try {
            /* MOCK DATA */
            await new Promise((resolve, reject) => { // mock
              setTimeout(() => {
                resolve()
              }, 1000)
            })
            /* /MOCK DATA */

            // await axios.put("/api/xxx/update-rental-application", {
            //   rental_application: rentalApplication,
            // });
          } catch (e) {
            console.info('Error in updateRentalApplication:')
            console.error(e)
            throw e
          }
        },
      },
    },
  },
}

export default API_tmp
