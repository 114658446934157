
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { MediaItem } from '../../middleware/MediaItem'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'

import MediaView from '@/components/common/MediaView.vue'
import AuthModal from '../auth/AuthModal.vue'
import PageLayoutIntroText from './PageLayoutIntroText.vue'
import { authService } from '../../services/authServiceInstance'
import { VANITY_WEB_URLS } from '../../constants'

@Component({
  components: {
    MediaView,
    AuthModal,
    Swiper,
    SwiperSlide,
    PageLayoutIntroText,
  },
  directives: {
    swiper: directive,
  },
})

export default class PageLayout extends Vue {
  @Prop({ type: Boolean }) loaded!: boolean;
  @Prop({ type: Array }) media!: MediaItem[];
  @Prop({ type: String }) title!: string;
  @Prop({ type: String }) text!: string;
  @Prop({ type: String }) buttonText!: string;
  @Prop({ type: Boolean }) placeTextBottom!: boolean;
  @Prop({ type: Array }) socials!: { type: string, icon: string, link: string }[];

  @Getter('organization', { namespace: 'organization' }) organization: any;
  @Getter('organizationLoaded', { namespace: 'organization' }) organizationLoaded: any;

  @Action('toggleAuthModal', { namespace: 'ui' }) toggleAuthModal: any;

  @Watch('organizationLoaded')
  onOrganizationLoad () {
    setTimeout(() => {
      this.checkCurrentSlide()
    })
  }

  currentSlide = 0;

  swiperOptions = {
    loop: true,
    autoplay: {
      delay: 5000,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
      clickable: true,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  };

  housingLink = {
    type: 'housing',
    icon: 'housingLogo',
    link: 'https://www.hud.gov/program_offices/fair_housing_equal_opp',
  }

  domainAliases = {};

  get socialsFilled () {
    return [this.housingLink, ...this.socials]
  }

  get showGallery (): boolean {
    return this.media.length > 1
  }

  getHomeUrl () {
    const { name, params } = this.$route
    if (name === 'Property' && params?.id) { return `/new/property/${params?.id}` }

    return '/'
  }

  openProfile () {
    this.$router.push('/tenant-profile/user-reservations/reservations')
  }

  openAuthModal (type) {
    this.toggleAuthModal({
      open: true,
      type,
    })
  }

  checkUserRole (role: string): boolean {
    // TODO Probably incorrect place for this check.
    if (!authService.user) {
      return
    }
    return authService.hasRole(role)
  }

  checkCurrentSlide () {
    const swiper = this.$refs.mySwiper && (this.$refs.mySwiper as any).$swiper
    if (!swiper) return

    swiper.on('slideChange', (val) => {
      this.currentSlide = swiper.activeIndex
    })
  }

  parseDomainAliases () {
    const { VUE_APP_DOMAIN_ALIASES } = process.env
    const aliases = VUE_APP_DOMAIN_ALIASES?.split(',') || []
    aliases.forEach((alias) => {
      const [vanityUrl, mainUrl, propertyId] = alias.split('>')
      this.domainAliases[propertyId] = {
        mainUrl,
        vanityUrl,
      }
    })
  }

  mounted () {
    this.checkCurrentSlide()
    this.parseDomainAliases()
  }

  logout () {
    authService.logout()
  }
}
