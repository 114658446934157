import { User } from '../middleware/entities'
import axios from '../middleware/axios'
import { API } from '../api/endpoints'

export const AuthServiceKeys = {
  // token and user are used for old app. Not super sure these are important.
  token: 'token',
  user: 'user',
  mgrToken: 'mgrToken',
  mgrUser: 'mgrUser',
}

/**
 * This class is intended as a central spot for auth related operations.
 *
 * init should be run before you expect instance to function properly.
 * We don't do any interaction with axios instance here because axios instance
 * loads token from local storage on each request independently.
 */
export class AuthService {
  token: string
  user?: User

  init (): void {
    const user = localStorage.getItem(AuthServiceKeys.mgrUser)
    this.user = user ? JSON.parse(user) : null

    this.token = localStorage.getItem(AuthServiceKeys.mgrToken) || null
  }

  hasRole (role: string): boolean {
    return this.user?.roles.includes(role)
  }

  async login (credentials: {username: string, password: string}): Promise<void> {
    this.logout()
    const { user, token } = (await axios.post(API.TOKEN_AUTH, credentials)).data
    this.user = user
    this.token = token

    if (['manager', 'maintenance'].includes(user.roles[0])) {
      // TODO We use both keys here because old app uses them.
      localStorage.setItem(AuthServiceKeys.mgrUser, JSON.stringify(user))
      localStorage.setItem(AuthServiceKeys.mgrToken, token)
      return
    }

    // TODO We use both keys here because old app uses them.
    localStorage.setItem(AuthServiceKeys.user, JSON.stringify(user))
    localStorage.setItem(AuthServiceKeys.token, token)
  }

  logout (): void {
    Object.keys(AuthServiceKeys).forEach(
      key => localStorage.removeItem(key),
    )
    this.user = null
    this.token = null
  }

  redirectAfterLogin (): void {
    // These are redirects to separate apps, so no vue-router here.
    if (this.user.roles[0] === 'manager') {
      window.location.href = '/rc-manager/alerts/'
    } else if (this.user.roles[0] === 'maintenance') {
      window.location.href = '/rc-manager/maintenance/tickets'
    } else {
      window.location.href = '/tenant-profile/user-reservations/reservations'
    }
  }
}
