import { routes } from './router'
import { RouteConfig } from 'vue-router'

export function getRoute (predicate: (route: RouteConfig) => boolean): RouteConfig | null {
  function findInChildren (children: Array<RouteConfig>): RouteConfig | null {
    if (!children.length) return null
    const route = children.find(predicate)

    return route || findInChildren(children.flatMap(child => {
      return child.children || []
    }))
  }

  return findInChildren(routes) || null
}

export function getRouteChildren (name: string): Array<RouteConfig> {
  let route = getRoute(route => route.name === name)

  if (route) {
    if (route.path === '') {
      route = getRoute(route => route.meta && route.meta.bypass === name)
    }

    if (route && route.children) {
      return route
        .children
        .filter(route => {
          return route.path !== ''
        })
    }
  }

  return []
}
