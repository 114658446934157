
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import RcSwiper from '../common/RcSwiper.vue'

@Component({
  components: {
    RcSwiper,
  },
})
export default class PropertyFriends extends Vue {
  @Prop({ type: Boolean }) previewModeOnly!: boolean
  @Prop({ type: String }) propertyId!: string
  @Prop({ type: String }) description!: string

  mounted () {
    if (this.propertyId) {
      this.loadPropertyFriends()
    }
  }

  @Watch('propertyId')
  onPropertyIdLoaded () {
    this.loadPropertyFriends()
  }

  async loadPropertyFriends () {
    const data = await this.$api.propertyFriend.get(this.propertyId)
    this.personsList = data.map(({ user, match_percentage }) => ({
      name: user ? `${user.first_name} ${user.last_name[0]}.` : '',
      match: match_percentage,
      image: user ? user.image : null,
    }))
  }

  personsList = []

  get itemsPerPage () {
    return this.$vuetify.breakpoint.smAndDown ? 2 : 4
  }
}
