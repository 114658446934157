
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})

export default class HowItWorks extends Vue {
  workSteps = [{
    icon: 'lease',
    title: 'Select your lease period',
    text: 'Select the leasing period that best fits your schedule',
  }, {
    icon: 'house',
    title: 'Pick your unit, room, and lease preferences',
    text: 'Create and customize your lease with your selections',
  }, {
    icon: 'contract',
    title: 'Sign and pay',
    text: 'Complete all forms, contracts, and payments online',
  }, {
    icon: 'key',
    title: 'Quick online approvals',
    text: 'Fast feedback, support, and lease approval',
  }]
}
