import { sortLeasePeriods } from './../helpers/sort'
import axios from '../middleware/axios'
import { API } from './endpoints'
import { LeasePeriod } from '../middleware/LeasePeriod'

export const getLeasePeriodsList = async (): Promise<LeasePeriod[]> => {
  const response = (await axios.get(API.AGREEMENT_PERIODS.SHOW_ALL())).data
  const leasePeriods = (response as Partial<LeasePeriod>[]).map((leasePeriodData) => new LeasePeriod(leasePeriodData))

  return sortLeasePeriods(leasePeriods)
}

export const getLeasePeriodsForProperty = async (propertyId: number): Promise<LeasePeriod[]> => {
  const response = (await axios.get(API.AGREEMENT_PERIODS.SHOW_ALL(propertyId))).data
  const leasePeriods = (response as Partial<LeasePeriod>[]).map((leasePeriodData) => new LeasePeriod(leasePeriodData))

  return sortLeasePeriods(leasePeriods)
}

export default {
  list: getLeasePeriodsList,
  getForProperty: getLeasePeriodsForProperty,
}
