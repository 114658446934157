
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Property } from '../../middleware/Property'
import { PortfolioItem } from '../../middleware/PortfolioItem'
import FileUploader from '../../components/common/FileUploader.vue'
import { State, Action, Getter } from 'vuex-class'

const namespace: string = 'property'

@Component({
  components: {
    FileUploader,
  },
})

export default class PropertiesPortfolioEditor extends Vue {
  @Action('loadProperties', { namespace }) loadProperties: any;
  @Action('loadPortfolios', { namespace }) loadPortfolios: any;
  @Action('updatePortfolios', { namespace }) setPortfolios: any;
  @Getter('properties', { namespace }) properties: Property[];
  @Getter('portfolios', { namespace }) portfolios: PortfolioItem[];
  @Getter('propertiesLoaded', { namespace }) propertiesLoaded: boolean;
  @Getter('portfoliosLoaded', { namespace }) portfoliosLoaded: boolean;

  addPortfolioDialog: boolean = false
  portfolioDialogLoading: boolean = false
  editedPropertyId: string | null = null
  formData: PortfolioItem = new PortfolioItem()
  loadedImage: File[] = []
  presortedOptionsList: number[] = []

  mounted () {
    if (!this.portfoliosLoaded) {
      this.loadPortfolios()
    }
    if (!this.propertiesLoaded) {
      this.loadProperties()
    }
  }

  @Watch('propertiesLoaded')
  onPropertiesLoaded (newVal: boolean) {
    this.setPresortedOptionsList()
  }

  @Watch('portfoliosLoaded')
  onPortfoliosLoaded (newVal: boolean) {
    this.setPresortedOptionsList()
  }

  get propertiesOptionsList () {
    if (!this.propertiesLoaded || !this.portfoliosLoaded) return {}
    return this.properties.reduce((acc, { id, name }) => {
      acc[id] = this.formData.properties.includes(id)
      return acc
    }, {})
  }

  setPresortedOptionsList ():number[] {
    if (!this.propertiesLoaded || !this.portfoliosLoaded) return []
    const extraProps: number[] = this.properties.reduce((res, { id }) => {
      if (!this.formData.properties.includes(id)) {
        res.push(id)
      }
      return res
    }, [])
    this.presortedOptionsList = this.formData.properties.concat(extraProps)
  }

  changePropertiesOrder (dir, idx) {
    if ((idx === 0 && dir === 'up') || (idx === this.presortedOptionsList.length - 1 && dir === 'down')) return
    const directionIdx = dir === 'up' ? -1 : 1
    const temp = this.presortedOptionsList[idx + directionIdx]

    this.presortedOptionsList[idx + directionIdx] = this.presortedOptionsList[idx]
    this.presortedOptionsList[idx] = temp
    this.presortedOptionsList = this.presortedOptionsList.slice()
  }

  closeAddPortfolioDialog () {
    this.addPortfolioDialog = false
    this.formData = new PortfolioItem()
    this.editedPropertyId = null
    this.loadPortfolios()
  }

  reloadImageUploader () {
    // @ts-ignore
    this.$refs.portfolio_image && this.$refs.portfolio_image.reload()
  }

  async savePortfolio () {
    this.portfolioDialogLoading = true
    const imageFormData = new FormData()
    if (this.loadedImage) {
      imageFormData.append('image', this.loadedImage[0])
    }

    this.formData.properties = this.presortedOptionsList.reduce((acc, curr) => (
      this.propertiesOptionsList[curr] ? acc.concat(+curr) : acc
    ), [])
    try {
      if (this.editedPropertyId) {
        await this.$api.portfolio.edit(this.editedPropertyId, this.formData)
        if (this.loadedImage.length) {
          await this.$api.portfolio.uploadImage(this.editedPropertyId, imageFormData)
        }
      } else {
        const res = await this.$api.portfolio.create(this.formData)
        if (this.loadedImage.length) {
          await this.$api.portfolio.uploadImage(res.id + '', imageFormData)
        }
      }
    } catch (e) {
      await this.$store.dispatch('ui/showError', `Couldn't ${this.editedPropertyId ? 'edit' : 'create'} property portfolio`)
    } finally {
      this.closeAddPortfolioDialog()
      this.portfolioDialogLoading = false
    }
  }

  editPortfolio (id: string) {
    this.editedPropertyId = id
    this.formData = { ...this.portfolios.find((portfolio) => id === portfolio.id) }
    this.reloadImageUploader()
    this.addPortfolioDialog = true
  }

  async deletePortfolio (id: string) {
    await this.$api.portfolio.deletePortfolio(id)
    this.setPortfolios(this.portfolios.filter(portfolio => portfolio.id !== id))
  }
}
