import { Ledger } from './../../middleware/Ledger'
import { Module } from 'vuex'
import { AccountCode } from 'src/middleware/AccountCode'

interface LedgerState {
  ledgers: Ledger[];
  ledgersLoaded: Boolean;
  ledgerTypes: AccountCode[]
  ledgerTypesLoaded: Boolean;
}

export default <Module<LedgerState, any>>{
  namespaced: true,

  state: {
    ledgers: [],
    ledgersLoaded: false,
    ledgerTypes: [],
    ledgerTypesLoaded: false,
  },

  mutations: {
    SET_LEDGERS: (state, value) => {
      state.ledgers = value
    },
    SET_LEDGERS_LOADED: (state, value) => {
      state.ledgersLoaded = value
    },
    SET_LEDGER_TYPES: (state, value) => {
      state.ledgerTypes = value
    },
    SET_LEDGER_TYPES_LOADED: (state, value) => {
      state.ledgerTypesLoaded = value
    },
  },

  actions: {
    async loadLedgers ({ commit }, payload) {
      commit('SET_LEDGERS', payload)
      commit('SET_LEDGERS_LOADED', true)
    },
    async loadLedgerTypes ({ commit }, payload) {
      commit('SET_LEDGER_TYPES', payload)
      commit('SET_LEDGER_TYPES_LOADED', true)
    },
  },

  getters: {
    ledgerTypes: (state) => state.ledgerTypes,
    ledgerTypesLoaded: (state) => state.ledgerTypesLoaded,
    ledgerTypesForProperties: (state) => (propertyIds: number[]) => state.ledgerTypes.filter(item => propertyIds.includes(item.property_id)),
  },
}
