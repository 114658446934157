import Vue from 'vue'
import Vuex from 'vuex'
import ui from './modules/ui'
import manager from './modules/manager'
import property from './modules/property'
import organization from './modules/organization'
import leasePeriod from './modules/leasePeriod'
import ledger from './modules/ledger'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    ui,
    manager,
    property,
    organization,
    leasePeriod,
    ledger,
  },
})
