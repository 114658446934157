
import { Vue, Component, Prop } from 'vue-property-decorator'
import VueInstagram from 'vue-instagram'

@Component({
  components: {
    VueInstagram,
  },
})
export default class InstagramWidget extends Vue {
  @Prop({ type: Boolean }) previewModeOnly!: boolean;
  @Prop({ type: Array }) tags!: string[];
  @Prop({ type: String }) instagram_key!: string;

  accessToken = '362555203.1677ed0.48d80658a060464898a1b65a7f650c6e'
}
