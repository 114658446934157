
import { Vue, Component } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { getRouteChildren } from '../router/utils'
import { authService } from '../services/authServiceInstance'
const namespace = 'ui'
@Component({})
export default class Manager extends Vue {
  @Getter('navDisabled', { namespace }) navDisabled: boolean;
  @Action('setNavDisabledState', { namespace }) setNavDisabledState: Function;

  isNavbarVisible = this.$vuetify.breakpoint.mdAndUp

  beforeCreate () {
    console.log('authService.user', authService.user)
    if (!authService.user) {
      this.$router.push({ name: 'LoginPage.vue', query: { redirect: this.$route.path } })
    }
  }

  created () {
    this.$store.dispatch('manager/loadOrganization')
    if (this.$route.query['from-origin']) {
      this.isNavbarVisible = false
      this.setNavDisabledState(true)
    }
  }

  get isLoaded (): boolean {
    // HACK Loaded check should be implemented better.
    return !!this.$store.getters['manager/propertyIdsAsItems'].length
  }

  get breadcrumbs () {
    const breadcrumbs = this.$route.matched
      .map((route, i) => {
        // from-origin flag shows can we navigate there if come from external link
        return route.meta.link
          ? {
            text: route.meta.link.text,
            to: { name: route.name || route.meta.bypass },
            exact: true,
            disabled: route.meta.fromOrigin ? false : this.navDisabled,
          }
          : undefined
      })
      .filter(r => r) // remove `undefined`s

    document.title = 'Room Choice | ' + breadcrumbs.map(breadcrumb => breadcrumb!.text)
      .join(' - ')

    return breadcrumbs
  }

  get links () {
    return getRouteChildren('Manager')
      .map(child => {
        const link = {
          name: child.meta.link.text,
          routeName: child.name || child.meta.bypass,
          icon: child.meta.link.icon,
          unreadNotificationsCount: 0,
        }

        link.unreadNotificationsCount = this.getUnreadNotificationsCount(link)

        return link
      })
  }

  getUnreadNotificationsCount (link: any) {
    if (link.name === 'Reservations') return 12
    if (link.name === 'Collections') return 34
    if (link.name === 'Messages') return 13
    return 0
  }
}
