import { LeasePeriod } from './../middleware/LeasePeriod'

export const sortLeasePeriods = (leasePeriods: LeasePeriod[]) => leasePeriods.sort((a, b) => {
  const startA = a.start_date!
  const startB = b.start_date!
  if (startA > startB) {
    return -1
  }
  if (startA < startB) {
    return 1
  }
  return 0
})
