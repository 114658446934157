export default {
  checkRequired (val: string): boolean|string {
    return !!val || 'This field is required'
  },
  checkPhone (val: string): boolean|string {
    if (!val) return true
    const phoneNumber = val.trim().replace(/\s/g, '')
    return /^(?:\+?1[-.●]?)?\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/.test(phoneNumber) || 'Incorrect phone'
  },
  checkEmail (val: string): boolean|string {
    return /\S+@\S+\.\S+/.test(val) || 'Incorrect email'
  },
  compareStrings (newVal: string, oldVal: string): boolean|string {
    return newVal === oldVal || 'Passwords should match'
  },
  minLength (val: string, minLength: number): boolean|string {
    return val.length >= minLength || `Use at least ${minLength} characters`
  },
  bothRegisters (val: string) :boolean|string {
    let lower = false
    let upper = false
    val.split('').forEach(character => {
      if (character === character.toLowerCase()) {
        lower = true
      } else if (character === character.toUpperCase()) {
        upper = true
      }
    })
    return (lower && upper) || 'Use both lower and upper case characters'
  },
}
