import { Module } from 'vuex'
import { Organization } from '../../middleware/entities'
import API_tmp from '../../middleware/api'
import api from '../../api/api'

interface OrganizationState {
  organization: Organization;
  organizationLoaded: boolean;
}

export default <Module<OrganizationState, any>>{
  namespaced: true,

  state: {
    organization: null,
    organizationLoaded: false,
  },

  mutations: {
    SET_ORGANIZATION: (state, value) => {
      state.organization = value
    },
    SET_ORGANIZATION_LOADED: (state, value) => {
      state.organizationLoaded = value
    },
  },

  actions: {
    async loadOrganization ({ commit }) {
      try {
        const organization = await API_tmp.manager.getOrganization()

        const website_block_attrs = await api.websiteBlockAttr.getOrganizationWebsiteBlockAttrs(`${organization.id}`)

        commit('SET_ORGANIZATION', { ...organization, website_block_attrs: [...website_block_attrs] })
      } catch (err) {
        throw err
      } finally {
        commit('SET_ORGANIZATION_LOADED', true)
      }
    },
  },

  getters: {
    organization: (state) => {
      return state.organization
    },
    organizationLoaded: (state) => {
      return state.organizationLoaded
    },
  },
}
