
import { Vue, Component } from 'vue-property-decorator'
import { ICellRendererParams } from 'ag-grid-community'

@Component({})
export default class ManagerSettingsDocumentTemplatesActionColumn extends Vue {
  params!: ICellRendererParams

  get parent () {
    return this.params.context.componentParent
  }

  get documentTemplateEditable () {
    return !this.params.data.approved
  }

  cloneDocumentTemplate () {
    this.parent.openCloneDocumentTemplateDialog(this.params.data)
  }
}
