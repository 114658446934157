
import { Vue, Component, Watch } from 'vue-property-decorator'
import { State, Getter } from 'vuex-class'

import BannerBlock from '@/components/common/BannerBlock.vue'
import InstagramWidget from '@/components/common/InstagramWidget.vue'
import TestimonialsCarousel from '@/components/common/TestimonialsCarousel.vue'
import PageLayout from '@/components/common/PageLayout.vue'

import PropertiesPortfolio from '@/components/landing/PropertiesPortfolio.vue'
import OrganizationAbout from '@/components/landing/OrganizationAbout.vue'
import ContactForm from '@/components/landing/ContactForm.vue'

const namespace: string = 'organization'

@Component({
  components: {
    PageLayout,
    InstagramWidget,
    TestimonialsCarousel,
    OrganizationAbout,
    PropertiesPortfolio,
    ContactForm,
  },
})

export default class Home extends Vue {
  @Getter('organization', { namespace: 'organization' }) organization: any;
  @Getter('organizationLoaded', { namespace: 'organization' }) organizationLoaded: any;

  scrollToPortfolio () {
    const properties = document.getElementById('properties')
    if (!properties) return

    window.scroll({
      behavior: 'smooth',
      left: 0,
      top: properties.offsetTop - 50,
    })
  }

  @Watch('organizationLoaded')
  onOrganizationLoad () {
    if (this.organization && this.organization.properties.length === 1) {
      this.$router.push({ name: 'Property', params: { id: this.organization.properties[0].id } })
    }
    if (this.organization && !this.organization.serve_new_site) {
      window.location.href = '/home'
      // Disabled on local build because we don't have nginx.
      if (process.env.NODE_ENV !== 'development') {
        window.location.href = '/home'
      }
    }
    this.setTitle()
  }

  setTitle () {
    if (this.organization) {
      document.title = 'Room Choice | ' + this.organization.name
    }
  }

  get media () {
    return this.organization
      ? this.organization.background_media.sort((a, b) => a.order - b.order).map(i => i.file)
      : []
  }

  get socials () {
    return this.organization
      ? [
        { type: 'facebook', icon: 'facebook', link: this.organization.facebook },
        { type: 'twitter', icon: 'twitter', link: this.organization.twitter },
        { type: 'instagram', icon: 'instagram', link: this.organization.instagram },
      ].filter(({ link }) => link)
      : []
  }

  getBlockVisibility (name: string) {
    const attr = this.organization.website_block_attrs.find(attr => attr.block_name === name)
    return attr ? attr.visibility : true
  }

  getBlockDescription (name: string) {
    const attr = this.organization.website_block_attrs.find(attr => attr.block_name === name)
    return attr ? attr.description : ''
  }

  mounted () {
    this.setTitle()
  }
}
