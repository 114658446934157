export class Ledger {
  constructor (data?: Partial<Ledger>) {
    Object.assign(this, data)
  }

  static get dueDateSpecials () {
    return ['Due Today', 'Due on Approval']
  }

  id: number = NaN
  sub_period_id: number = NaN // inheritance binding
  due_date: string = ''
  account_code_id: number = NaN
  description: string = ''
  credit_amount: number = 0
  charge_amount: number = 0
}
