
// TODO: split to several components
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { AgGridVue } from 'ag-grid-vue'
import { ColDef, ColumnApi, GridApi, GridOptions, GridReadyEvent, RowNode } from 'ag-grid-community'
import { AccountCode } from '../../middleware/AccountCode'
import { PaymentPlan } from '../../middleware/PaymentPlan'
import PaymentPlansEditorActionColumn
  from '../../components/manager/paymentPlans/PaymentPlansEditorActionColumn.vue'
import { currency, fullDate } from '../../filters'
import { VForm } from '../../custom-typings'
import moment from 'moment'
import { Getter, Action } from 'vuex-class'
import { authService } from '../../services/authServiceInstance'
import { LeasePeriod } from '../../middleware/LeasePeriod'
import { Ledger as LedgerEntry } from '../../middleware/Ledger'

const LEASE_PERIOD_NAMESPACE = 'leasePeriod'
const LEDGER_NAMESPACE = 'ledger'

@Component({
  components: {
    AgGridVue,
  },
})
export default class ManagerSettingsPaymentPlansEditor extends Vue {
  @Action('loadLeasePeriods', { namespace: LEASE_PERIOD_NAMESPACE }) setLeasePeriods: Function;
  @Action('loadLedgerTypes', { namespace: LEDGER_NAMESPACE }) setLedgerTypes: Function;
  @Prop({ type: Number, required: true }) readonly paymentPlanId!: number
  @Prop({ type: Boolean, required: true }) readonly previewOnlyMode!: boolean
  @Getter('leasePeriodsForProperties', { namespace: LEASE_PERIOD_NAMESPACE }) leasePeriodsForProperties: Function
  @Getter('leasePeriods', { namespace: LEASE_PERIOD_NAMESPACE }) leasePeriods: LeasePeriod[]
  @Getter('leasePeriodsLoaded', { namespace: LEASE_PERIOD_NAMESPACE }) leasePeriodsLoaded: boolean;
  @Getter('propertiesIds', { namespace: 'manager' }) propertiesIds: number[]
  @Getter('ledgerTypes', { namespace: LEDGER_NAMESPACE }) ledgerTypes: AccountCode[]
  @Getter('ledgerTypesForProperties', { namespace: LEDGER_NAMESPACE }) ledgerTypesForProperties: Function
  @Getter('ledgerTypesLoaded', { namespace: LEDGER_NAMESPACE }) ledgerTypesLoaded: boolean

  gridOptions: GridOptions = null!
  columnDefs: ColDef[] = null!
  gridApi: GridApi = null!
  columnApi: ColumnApi = null!

  paymentPlan: PaymentPlan = new PaymentPlan()
  paymentPlanLoading = false
  paymentPlanUpdating = false
  paymentPlanApproving = false
  paymentPlanTouched = false

  propertyFilter: number = NaN

  editedLedgerEntryIndex = -1
  editedLedgerEntryItem = new LedgerEntry()

  editLedgerEntryDialog = false
  // noinspection JSUnusedGlobalSymbols
  dueDateMenu = false
  editedLedgerEntryTypeFilter: string | null = null

  // noinspection JSUnusedGlobalSymbols
  effectiveStartDateMenu = false
  // noinspection JSUnusedGlobalSymbols
  effectiveEndDateMenu = false

  async beforeMount () {
    this.gridOptions = {
      context: {
        componentParent: this,
      },

      groupUseEntireRow: true,
      suppressScrollOnNewData: true,
      enableCellChangeFlash: true,
      suppressCellSelection: true,
      animateRows: true,
      domLayout: 'autoHeight',

      defaultColDef: {
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
      },

      postSort: (): void => {
        setTimeout(() => {
          this.gridApi.refreshCells()
        })
      },
    }

    this.initializeColumns()
    await this.loadLeasePeriods()
    await this.loadPaymentPlan()
    this.loadLedgerTypes()
  }

  onGridReady (params: GridReadyEvent) {
    this.gridApi = params.api
    this.columnApi = params.columnApi
  }

  async loadLeasePeriods () {
    if (!this.leasePeriodsLoaded) {
      try {
        const leasePeriods = await this.$api.leasePeriod.list()

        this.setLeasePeriods(leasePeriods)
      } catch (e) {
        console.log(e)
      }
    }
  }

  // noinspection JSUnusedGlobalSymbols
  get PaymentPlan () {
    return PaymentPlan
  }

  get leasePeriodsOptions () {
    return this.leasePeriodsForProperties(Number.isNaN(this.propertyFilter)
      ? []
      : [this.propertyFilter])
      .map((leasePeriod: LeasePeriod) => ({
        text: leasePeriod.name,
        value: leasePeriod.id,
      }))
  }

  get LedgerEntry () {
    return LedgerEntry
  }

  get paymentPlanEditable () {
    return !this.previewOnlyMode && !this.paymentPlan.approved
  }

  get editLedgerEntryFormTitle () {
    return this.editedLedgerEntryIndex === -1 ? 'Add New Ledger Entry' : 'Edit Ledger Entry'
  }

  get editLedgerEntryFormActivatorText () {
    return this.editedLedgerEntryIndex === -1 ? 'Create' : 'Save'
  }

  get formattedDueDate () {
    return LedgerEntry.dueDateSpecials.includes(this.editedLedgerEntryItem.due_date)
      ? this.editedLedgerEntryItem.due_date
      : fullDate(this.editedLedgerEntryItem.due_date)
  }

  get accountTypesAsItems () {
    return this.$store.state.manager.accountCodes.map((accountCode: AccountCode) => {
      return accountCode.type
    })
  }

  get accountsAsItems () {
    return this.$store.state.manager.accountCodes
      .filter((accountCode: AccountCode) => {
        return accountCode.type === this.editedLedgerEntryTypeFilter
      })
      .map((accountCode: AccountCode) => {
        return {
          text: accountCode.name,
          value: accountCode.id,
        }
      })
  }

  get subtotals () {
    const accounts = this.paymentPlan.ledger_entries.map(ledgerEntry => {
      return this.$store.getters['manager/accountCodeById'](ledgerEntry.account_code_id)
    })
      .filter((account: AccountCode | null) => account !== null)

    const uniqueAccounts: AccountCode[] = ([...(new Set(accounts))] as AccountCode[])

    return uniqueAccounts.map(account => {
      return {
        account: account.name,
        amount: this.paymentPlan.ledger_entries.filter(ledgerEntry => {
          return ledgerEntry.account_code_id === account.id
        })
          .reduce((acc, curr) => {
            return acc + (curr.charge_amount - curr.credit_amount)
          }, 0),
      }
    })
  }

  get total () {
    return this.paymentPlan.ledger_entries.reduce((acc, curr) => {
      return acc + (curr.charge_amount - curr.credit_amount)
    }, 0)
  }

  get dueToday () {
    return this.paymentPlan.ledger_entries.filter(ledgerEntry => {
      return ledgerEntry.due_date === 'Due Today'
    })
      .reduce((acc, curr) => {
        return acc + (curr.charge_amount - curr.credit_amount)
      }, 0)
  }

  get dueOnApproval () {
    return this.paymentPlan.ledger_entries.filter(ledgerEntry => {
      return ledgerEntry.due_date === 'Due on Approval'
    })
      .reduce((acc, curr) => {
        return acc + (curr.charge_amount - curr.credit_amount)
      }, 0)
  }

  @Watch('paymentPlanEditable')
  onPaymentPlanEditableChange () {
    this.initializeColumns()
  }

  @Watch('paymentPlanTouched')
  onPaymentPlanTouchedChange (newVal: boolean) {
    window.onbeforeunload = newVal
      ? function () {
        return 'Any unsaved progress will be lost! Are you sure you want to proceed'
      }
      : null
  }

  initializeColumns () {
    this.columnDefs = [
      {
        headerName: 'Due Date',
        field: 'due_date',
        valueFormatter: (params) => {
          return LedgerEntry.dueDateSpecials.includes(params.value) ? params.value : fullDate(params.value)
        },
      },

      {
        headerName: 'Type',
        valueGetter: (params) => {
          const accountCode = this.$store.getters['manager/accountCodeById'](params.data.account_code_id)
          return accountCode ? accountCode.type : ''
        },
      },

      {
        headerName: 'Account',
        valueGetter: (params) => {
          const accountCode = this.$store.getters['manager/accountCodeById'](params.data.account_code_id)
          return accountCode ? accountCode.name : ''
        },
      },

      {
        headerName: 'Property',
        valueGetter: () => {
          const property = this.$store.getters['manager/propertyById'](this.propertyFilter)
          return property ? property.name : ''
        },
      },

      {
        headerName: 'Lease Period',
        valueGetter: () => {
          const leasePeriod = this.$store.getters['leasePeriod/leasePeriodById'](this.paymentPlan.lease_period_id)
          return leasePeriod ? leasePeriod.name : ''
        },
      },

      {
        headerName: 'Sub Period',
        valueGetter: (params) => {
          const subPeriod = this.$store.getters['manager/subPeriodById'](params.data.sub_period_id)
          return subPeriod ? subPeriod.name : ''
        },
      },

      { headerName: 'Description', field: 'description' },

      {
        headerName: 'Pmts/Credit Amount',
        field: 'credit_amount',
        valueFormatter (params) {
          return params.value ? currency(params.value) : ''
        },
      },

      {
        headerName: 'Charge Amount',
        field: 'charge_amount',
        valueFormatter (params) {
          return params.value ? `-${currency(params.value)}` : ''
        },
      },

      {
        headerName: 'Total Amount',

        valueGetter: (params) => {
          const nodes: RowNode[] = []
          this.gridApi.forEachLeafNode((node: RowNode) => nodes.push(node))

          const rowsBefore = nodes.filter(node => node.rowIndex <= params.node.rowIndex)
            .map(node => node.data)

          return rowsBefore.reduce((acc, curr) => {
            return acc + (curr.charge_amount - curr.credit_amount)
          }, 0)
        },

        valueFormatter (params) {
          return currency(params.value)
        },
      },

      this.paymentPlanEditable
        ? {
          headerName: 'Action',
          sortable: false,
          filter: false,
          enableRowGroup: false,
          cellRendererFramework: PaymentPlansEditorActionColumn,
          pinned: 'right',
        }
        : {},
    ]
  }

  openEditLedgerEntryDialog (ledgerEntry: LedgerEntry) {
    if (!this.paymentPlanEditable) return

    this.editedLedgerEntryIndex = this.paymentPlan.ledger_entries.indexOf(ledgerEntry)
    this.editedLedgerEntryItem = new LedgerEntry(ledgerEntry)

    const accountCode = this.$store.getters['manager/accountCodeById'](ledgerEntry.account_code_id)

    if (accountCode) {
      this.editedLedgerEntryTypeFilter = accountCode.type
    }

    this.editLedgerEntryDialog = true
  }

  closeEditLedgerEntryDialog () {
    this.editLedgerEntryDialog = false

    setTimeout(() => {
      this.editedLedgerEntryIndex = -1
      this.editedLedgerEntryItem = new LedgerEntry()

      this.editedLedgerEntryTypeFilter = null;

      (this.$refs.editLedgerEntryForm as VForm).resetValidation()
    }, 300)
  }

  async loadLedgerTypes () {
    if (!this.ledgerTypesLoaded) {
      const ledgerTypes = await this.$api.ledger.getLedgerTypes()
      this.setLedgerTypes(ledgerTypes)
    }
  }

  saveLedgerEntry () {
    if (!(this.$refs.editLedgerEntryForm as VForm).validate()) return

    if (this.editedLedgerEntryIndex === -1) {
      this.addLedgerEntry(this.editedLedgerEntryItem)
    } else {
      this.updateLedgerEntry(this.editedLedgerEntryItem)
    }

    this.closeEditLedgerEntryDialog()
    this.paymentPlanTouched = true
  }

  addLedgerEntry (ledgerEntry: LedgerEntry) {
    this.gridApi.updateRowData({ add: [ledgerEntry] })
  }

  updateLedgerEntry (ledgerEntry: LedgerEntry) {
    Object.assign(this.paymentPlan.ledger_entries[this.editedLedgerEntryIndex], ledgerEntry)
    this.gridApi.updateRowData({ update: [this.paymentPlan.ledger_entries[this.editedLedgerEntryIndex]] })
  }

  deleteLedgerEntry (ledgerEntry: LedgerEntry) {
    if (!confirm('This action can not be undone! Are you sure you want to proceed?')) return

    this.gridApi.updateRowData({ remove: [ledgerEntry] })
    this.paymentPlanTouched = true
  }

  onPropertyFilterChange () {
    this.paymentPlan.lease_period_id = NaN
    this.paymentPlan.ledger_entries.forEach(ledgerEntry => { ledgerEntry.sub_period_id = NaN })

    this.gridApi.refreshCells()

    this.paymentPlanTouched = true
  }

  onPaymentPlanLeasePeriodIdChange () {
    this.paymentPlan.ledger_entries.forEach(ledgerEntry => { ledgerEntry.sub_period_id = NaN })

    this.gridApi.refreshCells()

    this.paymentPlanTouched = true
  }

  onEditedLedgerEntryTypeFilterChange () {
    this.editedLedgerEntryItem.account_code_id = NaN
  }

  sizeColumns () {
    if (this.$vuetify.breakpoint.lgAndDown) {
      if (this.columnApi) this.columnApi.autoSizeAllColumns()
    } else {
      if (this.gridApi) this.gridApi.sizeColumnsToFit()
    }
  }

  async loadPaymentPlan () {
    this.paymentPlanLoading = true

    try {
      this.paymentPlan = await this.$api.paymentPlan.get(this.paymentPlanId, this.leasePeriods.map(item => item.id), this.propertiesIds)

      if (this.paymentPlan.approved && !this.previewOnlyMode) {
        this.$router.replace({
          name: 'ManagerSettingsPaymentPlansPreviewer',
          params: { id: this.paymentPlan.id.toString() },
        })
      }

      const leasePeriod = this.$store.getters['leasePeriod/leasePeriodById'](this.paymentPlan.lease_period_id)

      if (leasePeriod) {
        this.propertyFilter = leasePeriod.property
      }
    } catch {
      this.$router.push({ name: 'ManagerSettingsPaymentPlans' })
      this.$store.dispatch('ui/showError', "Couldn't load the payment plan")
    } finally {
      this.paymentPlanLoading = false
    }
  }

  async updatePaymentPlan (paymentPlan: PaymentPlan) {
    if (!(this.$refs.editPaymentPlanForm as VForm).validate()) return

    if (this.paymentPlan.ledger_entries.some(e => Number.isNaN(e.sub_period_id))) {
      return this.$store.dispatch(
        'ui/showError',
        'Please, ensure that every Ledger Entry has Sub Period set',
      )
    }

    if (!(this.$refs.effectiveDatesForm as VForm).validate()) return

    this.paymentPlanUpdating = true

    try {
      await this.$api.paymentPlan.update(paymentPlan)

      this.paymentPlanTouched = false
      this.$store.dispatch('ui/showSuccess', 'The payment plan updated')
    } catch {
      this.$store.dispatch('ui/showError', "Couldn't update the payment plan")
    } finally {
      this.paymentPlanUpdating = false
    }
  }

  async approvePaymentPlan () {
    this.paymentPlanApproving = true

    try {
      await this.$api.paymentPlan.approve(this.paymentPlan)

      this.paymentPlan.approved = true
      this.paymentPlan.approved_by = authService.user.name
      this.paymentPlan.approved_at = moment(new Date())
        .toISOString()

      if (!this.previewOnlyMode) {
        this.$router.replace({
          name: 'ManagerSettingsPaymentPlansPreviewer',
          params: { id: this.paymentPlan.id.toString() },
        })
      }

      this.$store.dispatch('ui/showSuccess', 'The payment plan approved')
    } catch {
      this.$store.dispatch('ui/showError', "Couldn't approve the payment plan")
    } finally {
      this.paymentPlanApproving = false
    }
  }
}
