import moment from 'moment'

export function currency (value: any): string { // $123.45, $17250.00
  return parseFloat(value)
    .toLocaleString('en-US', { style: 'currency', currency: 'USD' })
}

export function shortDate (value: string): string { // 10/26/2019
  return value
    ? moment(value)
      .format('MM/DD/YYYY')
    : ''
}

export function mediumDate (value: string): string { // Oct 26, 2019
  return value
    ? moment(value)
      .format('MMM D, YYYY')
    : ''
}

export function fullDate (value: string): string { // Saturday, October 26th, 2019
  return value
    ? moment(value)
      .format('dddd, MMMM Do, YYYY')
    : ''
}

export function simpleTime (value: string): string { // 4:59 PM
  return moment(value)
    .format('h:mm A')
}
