import { Module } from 'vuex'
import { LeasePeriod } from './../../middleware/LeasePeriod'

interface LeasePeriodState {
  leasePeriods: LeasePeriod[];
  leasePeriodsLoaded: Boolean;
  selectedLeasePeriods: LeasePeriod[];
}

export default <Module<LeasePeriodState, any>>{
  namespaced: true,

  state: {
    leasePeriods: [],
    leasePeriodsLoaded: false,
    selectedLeasePeriods: [],
  },

  mutations: {
    SET_LEASE_PERIODS: (state, value) => {
      state.leasePeriods = value
    },
    SET_LEASE_PERIODS_LOADED: (state, value) => {
      state.leasePeriodsLoaded = value
    },
    SET_SELECTED_LEASE_PERIODS: (state, value) => {
      state.selectedLeasePeriods = value
    },
  },

  actions: {
    async loadLeasePeriods ({ commit }, payload) {
      commit('SET_LEASE_PERIODS', payload)
      commit('SET_LEASE_PERIODS_LOADED', true)
    },
    async selectLeasePeriods ({ commit }, payload) {
      commit('SET_SELECTED_LEASE_PERIODS', payload)
    },
  },

  getters: {
    leasePeriods: state => state.leasePeriods,
    leasePeriodsForProperties: state => propertiesIds => state.leasePeriods
      .filter(period => propertiesIds.includes(period.property)),
    leasePeriodsLoaded: state => state.leasePeriodsLoaded,
    selectedLeasePeriods: state => state.selectedLeasePeriods,
    selectedLeasePeriodsIds: state => state.selectedLeasePeriods.map(({ id }) => id),
    leasePeriodById: state => leasePeriodId => state.leasePeriods.find(({ id }) => id === leasePeriodId),
  },
}
