
import { Component, Vue } from 'vue-property-decorator'
import { ICellRendererParams } from 'ag-grid-community'

@Component({})
export default class AgTableSwitch extends Vue {
  params!: ICellRendererParams

  get valueProxy (): boolean {
    return this.params.data[this.params.colDef.field as string]
  }

  set valueProxy (value: boolean) {
    this.parent.$emit('fieldChanged', {
      entity: this.params.data,
      field: this.params.colDef.field,
      value,
    })
  }

  get parent () {
    return this.params.context.componentParent
  }
}
