
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import ManagerSettingsDocumentTemplatesEditorSubjectEditor
  from '../components/manager/ManagerSettingsDocumentTemplatesEditorSubjectEditor.vue'
import ManagerSettingsDocumentTemplatesEditorBodyEditor
  from '../components/manager/ManagerSettingsDocumentTemplatesEditorBodyEditor.vue'
import ManagerSettingsDocumentTemplateDetails
  from '../components/manager/ManagerSettingsDocumentTemplateDetails.vue'
import { DocumentTemplate } from '../middleware/entities'
import API_tmp from '../middleware/api'
import { Validatable, QuillEditorCapsule } from '../custom-typings'
import { HELLO_SIGN_TAGS_LIST } from '../constants'
import moment from 'moment'
import { authService } from '../services/authServiceInstance'

@Component({
  components: {
    ManagerSettingsDocumentTemplatesEditorBodyEditor,
    ManagerSettingsDocumentTemplatesEditorSubjectEditor,
    ManagerSettingsDocumentTemplateDetails,
  },
})
export default class ManagerSettingsDocumentTemplatesEditor extends Vue {
  @Prop({ required: true, type: Number }) readonly documentTemplateId!: number
  @Prop({ required: true, type: Boolean }) readonly previewOnlyMode!: boolean

  documentTemplate: DocumentTemplate = new DocumentTemplate()
  documentTemplateLoading = false
  documentTemplateUpdating = false
  documentTemplateApproving = false

  lastFocusedEditor: any = null
  documentTemplateDirty = false

  contentBlocks: { name: string, value: string }[] = []
  contentBlocksLoading = false

  variables: { name?: string, value: string }[] = []
  variablesLoading = false

  signatureTags: { name?: string, value: string }[] = []

  created () {
    this.loadDocumentTemplate()
    this.loadVariables()
  }

  mounted () {
    this.lastFocusedEditor = this.$refs.bodyEditor
  }

  @Watch('documentTemplateDirty')
  onDocumentTemplateDirtyChange (newVal: boolean) {
    newVal
      ? window.onbeforeunload = window.onbeforeunload = () => 'You may lose any unsaved changes. Are you sure you want to proceed?'
      : window.onbeforeunload = null
  }

  async loadDocumentTemplate () {
    this.documentTemplateLoading = true

    try {
      this.documentTemplate = await API_tmp.manager.settings.documentTemplates.getDocumentTemplate(this.documentTemplateId)

      if (this.documentTemplate.type === 'Lease Agreement') {
        this.loadContentBlocks()
        this.loadSignatureTags()
      }

      setTimeout(() => {
        if (this.documentTemplate.type === 'Email Template') {
          (this.$refs.subjectEditor as QuillEditorCapsule).setValue(this.documentTemplate.template.subject as any[])
        }

        (this.$refs.bodyEditor as QuillEditorCapsule).setValue(this.documentTemplate.template.body)

        this.documentTemplateDirty = false
      })
    } catch {
      await this.$store.dispatch('ui/showError', "Couldn't load document template")
    } finally {
      this.documentTemplateLoading = false
    }
  }

  async loadVariables () {
    this.variablesLoading = true

    try {
      this.variables = await API_tmp.manager.settings.documentTemplates.getVariables()
    } catch {
      await this.$store.dispatch('ui/showError', "Couldn't load variables")
    } finally {
      this.variablesLoading = false
    }
  }

  async loadContentBlocks () {
    this.contentBlocksLoading = true

    try {
      this.contentBlocks = await API_tmp.manager.settings.documentTemplates.getContentBlocks()
    } catch {
      await this.$store.dispatch('ui/showError', "Couldn't load content blocks")
    } finally {
      this.contentBlocksLoading = false
    }
  }

  async loadSignatureTags () {
    this.signatureTags = [...HELLO_SIGN_TAGS_LIST]
  }

  async updateDocumentTemplate () {
    if (!(this.$refs.templateName as Validatable).validate()) return
    if (this.$refs.subjectEditor && !(this.$refs.subjectEditor as Validatable).validate()) return
    if (!(this.$refs.bodyEditor as Validatable).validate()) return

    this.documentTemplateUpdating = true

    try {
      await API_tmp.manager.settings.documentTemplates.updateDocumentTemplate(this.documentTemplate)

      this.documentTemplateDirty = false
      await this.$store.dispatch('ui/showSuccess', 'The document template updated')
    } catch {
      await this.$store.dispatch('ui/showError', "Couldn't update document template")
    } finally {
      this.documentTemplateUpdating = false
    }
  }

  async approveDocumentTemplate () {
    if (this.documentTemplate.type !== 'Lease Agreement') return

    this.documentTemplateApproving = true

    try {
      await API_tmp.manager.settings.documentTemplates.approveDocumentTemplate(this.documentTemplate)

      this.documentTemplate.approved = true
      this.documentTemplate.approved_by = authService.user.name
      this.documentTemplate.approved_at = moment(new Date())
        .toISOString()

      this.$store.dispatch('ui/showSuccess', 'The document template approved')

      if (!this.previewOnlyMode) {
        this.$router.replace({
          name: 'ManagerSettingsDocumentTemplatesPreviewer',
          params: { id: this.documentTemplate.id.toString() },
        })
      }
    } catch {
      await this.$store.dispatch('ui/showError', "Couldn't approve document template")
    } finally {
      this.documentTemplateApproving = false
    }
  }
}
