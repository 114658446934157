
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import Swiper, { SwiperOptions } from 'swiper'
import {
  Swiper as VueSwiper,
  SwiperSlide as VueSwiperSlide,
} from 'vue-awesome-swiper'

@Component({
  name: 'RcSwiper',
  components: {
    VueSwiper,
    VueSwiperSlide,
  },
})
export default class RcSwiper extends Vue {
  @Prop({ required: true, type: Array }) items: any[]
  @Ref() swiperComponent: any
  swiperOptions: SwiperOptions = {
    loop: true,
    spaceBetween: 30,
    autoplay: {
      delay: 5000,
    },
    centerInsufficientSlides: true,
    pagination: {
      el: '.swiper-pagination',
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 2,
      },
      640: {
        slidesPerView: 3,
      },
      980: {
        slidesPerView: 4,
      },
    },
  }

  get swiperInstance (): Swiper {
    return this.swiperComponent.$swiper
  }

  public next () {
    this.swiperInstance.slideNext()
  }

  public prev () {
    this.swiperInstance.slidePrev()
  }
}
