
import { Vue, Component } from 'vue-property-decorator'
import { ICellRendererParams } from 'ag-grid-community'

@Component({})
export default class PaymentPlansEditorActionColumn extends Vue {
  params!: ICellRendererParams

  get parent () {
    return this.params.context.componentParent
  }

  editLedgerEntry () {
    this.parent.openEditLedgerEntryDialog(this.params.data)
  }

  deleteLedgerEntry () {
    this.parent.deleteLedgerEntry(this.params.data)
  }
}
