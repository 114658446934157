var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.address)?_c('div',{staticClass:"content-block"},[_c('h2',{staticClass:"mb-4"},[_vm._v(" Location ")]),_c('p',[_c('strong',[_vm._v(_vm._s(_vm.address))])]),_c('GmapMap',{staticClass:"property-location",attrs:{"center":_vm.mapCenter,"zoom":14,"options":{
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false
    }}},[_c('GmapMarker',{attrs:{"position":_vm.mapCenter,"icon":require('@/assets/svg/marker.svg')}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }