import { executeApiRequest } from './../middleware/api'
import { API } from './endpoints'

const getPropertyTestimonials = async (prop: string) => executeApiRequest({ method: 'get', url: API.TESTIMONIALS.PROPERTY_TESTIMONIALS, params: { prop } })
const createPropertyTestimonial = async (formData: FormData) => executeApiRequest({ method: 'post', url: API.TESTIMONIALS.PROPERTY_TESTIMONIALS, data: formData })
const editPropertyTestimonial = async (id: string, formData: FormData) => executeApiRequest({ method: 'patch', url: API.TESTIMONIALS.SINGLE_PROPERTY_TESTIMONIAL(id), data: formData })
const deleteTestimonial = async (id: string) => executeApiRequest({ method: 'DELETE', url: API.TESTIMONIALS.SINGLE_PROPERTY_TESTIMONIAL(id) })

const getOrganizationTestimonials = async (organizationId: string) => executeApiRequest({ method: 'get', url: API.TESTIMONIALS.BASE(organizationId), params: { show_on_organization: true } })

export default {

  getPropertyTestimonials,
  createPropertyTestimonial,
  editPropertyTestimonial,
  getOrganizationTestimonials,
  deleteTestimonial,
}
