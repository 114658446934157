import axios from '../middleware/axios'
import { AccountCode } from '../middleware/AccountCode'

export const getLedgerTypes = async (): Promise<AccountCode[]> => {
  try {
    const data = (await axios.get('/api/ledger_types/')).data

    return data.map((entry: AccountCode) => new AccountCode(entry))
  } catch (e) {
    console.info('Error in getAccountCodes: ')
    console.error(e)
    throw e
  }
}

export default {
  getLedgerTypes,
}
