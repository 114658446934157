
import { Component, Vue } from 'vue-property-decorator'
import LoginForm from './LoginForm.vue'
import ResetPasswordForm from './ResetPasswordForm.vue'
import RegisterForm from './RegisterForm.vue'
import { AuthFormTab } from './AuthForm'
import { VueConstructor } from 'vue'

@Component({
})
export default class AuthForm extends Vue {
  tab: AuthFormTab = 'login'

  get currentComponent () {
    const typeToComponent: Record<AuthFormTab, VueConstructor> = {
      login: LoginForm,
      register: RegisterForm,
      resetPassword: ResetPasswordForm,
    }
    return typeToComponent[this.tab]
  }
}
