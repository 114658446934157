
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})

export default class PageLayoutIntroText extends Vue {
  @Prop({ type: String }) title!: string;
  @Prop({ type: String }) text!: string;
  @Prop({ type: String }) buttonText!: string;g;
  @Prop({ type: Boolean }) placeTextBottom!: boolean;
}
