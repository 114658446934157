
import { Vue, Component, Watch } from 'vue-property-decorator'
import ManagerPropertySelector from '../../components/manager/ManagerPropertiesSelector.vue'
import ManagerLeasePeriodSelector from '../../components/manager/ManagerLeasePeriodsSelector.vue'
import { AgGridVue } from 'ag-grid-vue'
import { ColDef, ColumnApi, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community'
import PaymentPlansActionColumn from '../../components/manager/paymentPlans/PaymentPlansActionColumn.vue'
import { PaymentPlan } from '../../middleware/PaymentPlan'
import { Property } from '../../middleware/Property'
import { currency, mediumDate, simpleTime } from '../../filters'
import { VForm } from '../../custom-typings'
import { Getter } from 'vuex-class'
import { LeasePeriod } from '../../middleware/LeasePeriod'

const namespace = 'leasePeriod'

@Component({
  components: {
    ManagerPropertySelector,
    ManagerLeasePeriodSelector,
    AgGridVue,
  },
})
export default class PaymentPlans extends Vue {
  @Getter('selectedLeasePeriods', { namespace }) selectedLeasePeriods: LeasePeriod[]
  @Getter('selectedLeasePeriodsIds', { namespace }) selectedLeasePeriodsIds: number[]
  @Getter('leasePeriodById', { namespace }) leasePeriodById: Function
  @Getter('leasePeriodsForProperties', { namespace }) leasePeriodsForProperties: Function
  gridOptions: GridOptions = null!
  columnDefs: ColDef[] = null!
  gridApi: GridApi | null = null
  columnApi: ColumnApi | null = null

  paymentPlans: PaymentPlan[] = []
  paymentPlansLoading = false
  paymentPlanSaving = false

  editPaymentPlanDialog = false
  editedPaymentPlanItem = new PaymentPlan()
  editedPaymentPlanIndex = -1
  editedPaymentPlanPropertyFilter: number = NaN

  async beforeMount () {
    this.gridOptions = {
      context: {
        componentParent: this,
      },
      groupUseEntireRow: true,
      suppressScrollOnNewData: true,
      enableCellChangeFlash: true,
      suppressCellSelection: true,
      animateRows: true,
      domLayout: 'autoHeight',

      defaultColDef: {
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
      },
    }

    this.columnDefs = [
      { headerName: 'ID', field: 'id' },
      { headerName: 'Name', field: 'name' },
      { headerName: 'Type', field: 'type' },
      {
        headerName: 'Total',
        field: 'total',
        valueFormatter: (params) => params.value ? currency(params.value) : '',
      },
      {
        headerName: 'Property',
        valueGetter: (params) => {
          const property = this.$store.state.manager.properties
            .find((property: Property) => property.id === params.data.property_id)

          return property ? property.name : ''
        },
      },
      {
        headerName: 'Lease Period',
        valueGetter: (params) => {
          const leasePeriod = this.leasePeriodById(params.data.lease_period_id)

          return leasePeriod ? leasePeriod.name : ''
        },
      },
      {
        headerName: 'Approval Status',
        valueGetter: (params) => params.data.approved ? 'Approved' : 'Draft',
      },
      {
        headerName: 'Is Active',
        valueGetter: (params) => params.data.active ? 'Yes' : 'No',
      },
      { headerName: 'Created by', field: 'created_by' },
      {
        headerName: 'Created at',
        field: 'account',
        valueFormatter: (params) => `${mediumDate(params.data.created_at)} ${simpleTime(params.data.created_at)}`,
      },
      {
        headerName: 'Action',
        sortable: false,
        filter: false,
        enableRowGroup: false,
        cellRendererFramework: PaymentPlansActionColumn,
        pinned: 'right',
        suppressSizeToFit: true,
      },
    ]

    await this.loadPaymentPlans()
  }

  onGridReady (params: GridReadyEvent) {
    this.gridApi = params.api
    this.columnApi = params.columnApi
  }

  get leasePeriodsOptions () {
    return this.leasePeriodsForProperties(Number.isNaN(this.editedPaymentPlanPropertyFilter)
      ? []
      : [this.editedPaymentPlanPropertyFilter])
      .map((leasePeriod: LeasePeriod) => ({
        text: leasePeriod.name,
        value: leasePeriod.id,
      }))
  }

  get editPaymentPlanFormTitle () {
    return this.editedPaymentPlanIndex === -1 ? 'Add New Payment Plan' : 'Clone Payment Plan'
  }

  get editPaymentPlanFormActivatorText () {
    return this.editedPaymentPlanIndex === -1 ? 'Create' : 'Clone'
  }

  openEditPaymentPlanDialog (paymentPlan: PaymentPlan) {
    this.editedPaymentPlanIndex = this.paymentPlans.indexOf(paymentPlan)
    this.editedPaymentPlanItem = new PaymentPlan(paymentPlan)

    const leasePeriod = this.leasePeriodById(paymentPlan.lease_period_id)

    if (leasePeriod) {
      this.editedPaymentPlanPropertyFilter = leasePeriod.property_id
    }

    this.editPaymentPlanDialog = true
  }

  closeEditPaymentPlanDialog () {
    this.editPaymentPlanDialog = false

    setTimeout(() => {
      this.editedPaymentPlanIndex = -1
      this.editedPaymentPlanItem = new PaymentPlan()

      this.editedPaymentPlanPropertyFilter = NaN;

      (this.$refs.editPaymentPlanForm as VForm).resetValidation()
    }, 300)
  }

  @Watch('selectedLeasePeriods')
  onSelectedLeasePeriodsChange () {
    this.loadPaymentPlans()
  }

  @Watch('editedPaymentPlanPropertyFilter')
  onEditedPaymentPlanPropertyFilterChange (newVal: number, oldVal: number) {
    if (!Number.isNaN(oldVal) && !Number.isNaN(newVal)) {
      this.editedPaymentPlanItem.lease_period_id = NaN
    }
  }

  sizeColumns () {
    if (this.$vuetify.breakpoint.lgAndDown) {
      if (this.columnApi) this.columnApi.autoSizeAllColumns()
    } else {
      if (this.gridApi) this.gridApi.sizeColumnsToFit()
    }
  }

  async loadPaymentPlans () {
    this.paymentPlansLoading = true

    /* tempfix 101 - force-disable grouping before a new data is loaded */
    if (this.columnApi) {
      const cols = this.columnApi.getAllColumns()
      this.columnApi.removeRowGroupColumns(cols)
    }
    /* /tempfix */

    try {
      this.paymentPlans = await this.$api.paymentPlan.list(this.selectedLeasePeriodsIds, this.$store.state.manager.selectedProperties.map(({ id }) => id))
    } catch {
      this.$store.dispatch('ui/showError', "Couldn't load payment plans")
    } finally {
      this.paymentPlansLoading = false
    }
  }

  async savePaymentPlan () {
    if (!(this.$refs.editPaymentPlanForm as VForm).validate()) return

    this.paymentPlanSaving = true

    if (this.editedPaymentPlanIndex === -1) {
      await this.createPaymentPlan(this.editedPaymentPlanItem)
    } else {
      await this.clonePaymentPlan(this.editedPaymentPlanItem)
    }

    this.closeEditPaymentPlanDialog()
    this.paymentPlanSaving = false

    this.$router.push({
      name: 'ManagerSettingsPaymentPlansEditor',
      params: { id: this.editedPaymentPlanItem.id.toString() },
    })
  }

  async createPaymentPlan (paymentPlan: PaymentPlan) {
    try {
      paymentPlan.id = await this.$api.paymentPlan.create(paymentPlan)

      this.$store.dispatch('ui/showSuccess', 'The payment plan created')
    } catch {
      this.$store.dispatch('ui/showError', "Couldn't create the payment plan")
    }
  }

  async clonePaymentPlan (paymentPlan: PaymentPlan) {
    try {
      paymentPlan.id = await this.$api.paymentPlan.clone(paymentPlan)
      this.$store.dispatch('ui/showSuccess', 'The payment plan cloned')
    } catch {
      this.$store.dispatch('ui/showError', "Couldn't clone the payment plan")
    }
  }
}
