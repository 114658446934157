export class Resource {
  constructor (data?: Partial<Resource>) {
    Object.assign(this, data)
  }

  id: number | string = '';
  name: string = '';
  resource_file: string | File = '';
  prop: number = 0;
}
