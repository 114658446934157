
import { Vue, Component, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

@Component({})
export default class PageLayout extends Vue {
  @Getter('organization', { namespace: 'organization' }) organization: any
  @Getter('organizationLoaded', { namespace: 'organization' }) organizationLoaded: any

  @Action('loadOrganization', { namespace: 'organization' }) loadOrganization: any

  @Watch('organizationLoaded')
  onOrganizationLoad () {
    this.insertGTMScript()
  }

  created () {
    if (!this.organizationLoaded) {
      this.loadOrganization()
    }
  }

  insertGTMScript () {
    if (this.organization && this.organization.gtm_tag_id) {
      const { gtm_tag_id } = this.organization
      // Taken from https://developers.google.com/tag-manager/quickstart
      ;(function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
          'gtm.start':
          new Date().getTime(),
          event: 'gtm.js',
        }); var f = d.getElementsByTagName(s)[0]
        var j = d.createElement(s); var dl = l !== 'dataLayer' ? '&l=' + l : ''; (j as any).async = true; (j as any).src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f)
      })(window, document, 'script', 'dataLayer', gtm_tag_id)
    }
  }
}
