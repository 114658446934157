
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Property } from '../../middleware/Property'

@Component({})
export default class PropertySelect extends Vue {
  @Prop() value!: number[]

  get properties (): Property[] {
    return this.$store.state.manager.properties
  }

  get valueProxy (): number[] {
    return this.value
  }

  set valueProxy (value: number[]) {
    this.$emit('input', value)
  }

  selectAll () {
    if (this.value.length === this.properties.length) {
      this.valueProxy = []
    } else {
      this.valueProxy = this.properties.map(property => property.id)
    }
  }
}
