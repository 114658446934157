<template>
  <svg
    width="15"
    height="30"
    viewBox="0 0 15 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.03184 0C9.1029 0 10.7818 1.67895 10.7818 3.75C10.7818 5.82105 9.1029 7.5 7.03184 7.5C4.96079 7.5 3.28184 5.82105 3.28184 3.75C3.28184 1.67895 4.96079 0 7.03184 0ZM14.0211 20.7527L11.2086 9.50268C11.1325 9.19843 10.9569 8.92834 10.7098 8.73534C10.4626 8.54234 10.158 8.4375 9.84435 8.4375H9.17872C7.848 9.04939 6.27306 9.07582 4.88497 8.4375H4.21934C3.90573 8.4375 3.60113 8.54234 3.35394 8.73534C3.10675 8.92834 2.93117 9.19843 2.85511 9.50268L0.042605 20.7527C-0.178997 21.639 0.491785 22.5 1.40684 22.5H4.68809V28.5938C4.68809 29.3704 5.31768 30 6.09434 30H7.96934C8.74601 30 9.3756 29.3704 9.3756 28.5938V22.5H12.6568C13.5704 22.5 14.243 21.6404 14.0211 20.7527Z"
      fill="#ff87cd"
    />
  </svg>
</template>

<script>
export default {
  name: 'RcFemaleIcon',
}
</script>
