
import { Vue, Component, Prop } from 'vue-property-decorator'
import { VANITY_WEB_URLS } from '../../constants'
import { lazyLoadingHack } from '../../mixins/lazyLoadingHack'

@Component({})
export default class PropertyCard extends Vue {
  @Prop({}) property: any;

  domainAliases = {}

  mounted () {
    lazyLoadingHack()
    this.parseDomainAliases()
  }

  parseDomainAliases () {
    const { VUE_APP_DOMAIN_ALIASES } = process.env
    const aliases = VUE_APP_DOMAIN_ALIASES?.split(',') || []
    aliases.forEach((alias) => {
      const [vanityUrl, mainUrl, propertyId] = alias.split('>')
      this.domainAliases[propertyId] = {
        mainUrl,
        vanityUrl,
      }
    })
  }

  openProperty () {
    const domainAliase = this.domainAliases[this.property.id]
    const isMainPage = window.location.href.includes(domainAliase?.mainUrl)

    if (domainAliase && isMainPage) {
      window.open(`//${domainAliase.vanityUrl}`, '_blank')
      return
    }

    const route = this.$router.resolve({
      name: 'Property',
      params: { id: this.property.id },
    })

    window.open(route.href, '_blank')
  }
}
