
import { Vue, Component } from 'vue-property-decorator'
import { ICellRendererParams } from 'ag-grid-community'
import { LeasePeriod } from '../../middleware/LeasePeriod'
import LeasePeriodsTable from './LeasePeriodsTable.vue'
import { ContractTemplate } from '../../middleware/ContractTemplate'

@Component({})
export default class LeasePeriodContracts extends Vue {
  params!: ICellRendererParams

  mounted () {
    // HACK autosize column based on the number of assigned lease periods. Not 100% precise, though does its thing
    const allContractTemplateIds = this.parent.leasePeriodsLocal.map((leasePeriod: LeasePeriod) => {
      return leasePeriod.contract_template_ids
    })
    const longestArrayLength = allContractTemplateIds.reduce((acc: number[], curr: number[]) => {
      return curr.length > acc.length ? curr : acc
    }, []).length
    if (this.leasePeriod.contract_template_ids.length === longestArrayLength) {
      this.params.columnApi.autoSizeColumn(this.params.column)
    }
  }

  get leasePeriod (): LeasePeriod {
    return this.params.data
  }

  get leasePeriods () {
    return this.parent.leasePeriodsLocal
  }

  get parent (): LeasePeriodsTable {
    return this.params.context.componentParent
  }

  get contractTemplates () {
    // TODO This is mock. Not actual data.
    return [
      { id: 1, name: 'Template 1' },
      { id: 2, name: 'Template 2' },
    ]
  }

  updateContractTemplateAssignment () {
    throw new Error('Backend is not here.')
    // this.params.columnApi.autoSizeColumn(this.params.column)
  }

  deleteContractTemplate (contractTemplate: ContractTemplate) {
    const index = this.params.data[this.params.colDef.field!].indexOf(contractTemplate.id)
    this.params.data[this.params.colDef.field!].splice(index, 1)

    this.updateContractTemplateAssignment()
    // this.params.columnApi.autoSizeColumn(this.params.column)
  }
}
