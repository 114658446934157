
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { State, Action, Getter } from 'vuex-class'
import ManagerSettingsEditorSubmenu from '../components/manager/ManagerSettingsEditorSubmenu.vue'
import AmenitiesEditor from '../components/manager/AmenitiesEditor.vue'

@Component({
  components: {
  },
})
export default class WebpageModelEditor extends Vue {
  @Prop({ type: Object }) readonly value!: any

  @Watch('value', { deep: true })
  onValueChanged (val) {
    if (!Object.is(val, this.value)) {
      this.$emit('input', val)
    }
  }
}

