
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import API_tmp from '../../middleware/api'
import FileUploader from '../../components/common/FileUploader.vue'
import ManagerPropertiesSelector from '../../components/manager/ManagerPropertiesSelector.vue'
import { State, Action, Getter } from 'vuex-class'
import { Amenity } from '../../middleware/Amenity'

const namespace: string = 'property'

@Component({
  components: {
    FileUploader,
    ManagerPropertiesSelector,
  },
})

export default class AmenitiesEditor extends Vue {
  @Prop({ type: String, required: true }) propertyId: string;
  @Prop({ type: Array }) propertyAmenities: Amenity[];
  @Action('setAmenities', { namespace }) setAmenities: any;
  @Getter('amenities', { namespace }) amenities: Amenity[];
  @Getter('amenitiesLoaded', { namespace }) amenitiesLoaded: boolean;

  dialog: boolean = false
  dialogLoading: boolean = false
  editedAmentityId: string | null = null
  formData: Amenity = new Amenity()
  loadedImage: File[] = []
  errors: { [key: string ]: string } = {}

  async mounted () {
    if (this.propertyAmenities.length) {
      this.setAmenities(this.propertyAmenities.sort((a, b) => a.order - b.order))
    } else if (!this.amenitiesLoaded) {
      this.loadAmenities()
    }
  }

  loadAmenities = async () => {
    let amenities = []
    try {
      amenities = (await this.$api.amenity.list(this.propertyId)).sort((a, b) => a.order - b.order)
    } catch (e) {
      await this.$store.dispatch('ui/showError', 'Couldn\'t load amenities')
    } finally {
      this.setAmenities(amenities)
    }
  }

  closeDialog () {
    this.dialog = false
    this.errors = {}
    this.formData = new Amenity()
    this.editedAmentityId = null
    this.dialogLoading = false
  }

  reloadImageUploader () {
    // @ts-ignore
    this.$refs.amenity_image && this.$refs.amenity_image.reload()
  }

  async saveAmenity () {
    this.dialogLoading = true
    const formData = new FormData()
    if (this.loadedImage.length) {
      formData.append('image', this.loadedImage[0])
    }
    formData.append('name', this.formData.name)
    formData.append('prop', this.propertyId)
    const valid = this.validate()
    if (valid) {
      try {
        if (this.editedAmentityId) {
          await this.$api.amenity.edit(this.editedAmentityId, formData)
        } else {
          const res = await this.$api.amenity.create(formData)
        }
      } catch (e) {
        await this.$store.dispatch('ui/showError', `Couldn't ${this.editedAmentityId ? 'edit' : 'create'} amenity`)
      } finally {
        this.closeDialog()
        this.loadAmenities()
      }
    }
    this.dialogLoading = false
  }

  validate () {
    const errors = {
      name: !this.formData.name.length ? 'Cannot be empty' : null,
      image: !this.editedAmentityId ? !this.loadedImage[0] ? 'Cannot be empty' : null : null,
    }
    if (Object.values(errors).filter(val => val).length) {
      this.errors = { ...errors }
      return false
    } else {
      return true
    }
  }

  changeOrder (dir, idx) {
    if ((idx === 0 && dir === 'up') || (idx === this.amenities.length - 1 && dir === 'down')) return
    const directionIdx = dir === 'up' ? -1 : 1
    const temp = this.amenities[idx + directionIdx]

    const newAmenitesArr = this.amenities.slice()
    newAmenitesArr[idx + directionIdx] = { ...newAmenitesArr[idx], initialOrder: idx, order: idx + directionIdx }
    newAmenitesArr[idx] = { ...temp, initialOrder: idx + directionIdx, order: idx }
    // API save is in the parent component, because we need to save all changes at once on save button click
    this.setAmenities(newAmenitesArr)
  }

  editAmenity (id: string) {
    this.editedAmentityId = id
    this.formData = { ...this.amenities.find((amenity) => id === amenity.id) }
    this.reloadImageUploader()
    this.dialog = true
  }

  async deleteAmenity (id: string) {
    await this.$api.amenity.deleteAmenity(id)
    this.setAmenities(this.amenities.filter(amenity => amenity.id !== id))
  }
}
