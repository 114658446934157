
import { Vue, Component, Watch } from 'vue-property-decorator'
import ManagerPropertiesSelector from '@/components/manager/ManagerPropertiesSelector.vue'
import { AgGridVue } from 'ag-grid-vue'
import { ColDef, ColumnApi, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community'
import API_tmp from '../middleware/API_tmp'
import { Layout } from '../middleware/entities'
import { Property } from '../middleware/Property'
import ManagerSettingsLayoutsActionColumn from '@/components/manager/ManagerSettingsLayoutsActionColumn.vue'
import { VForm } from '../custom-typings'
import ConfirmationModal from '../components/common/ConfirmationModal.vue'

@Component({
  components: {
    ManagerPropertiesSelector,
    AgGridVue,
    ConfirmationModal,
  },
})
export default class ManagerSettingsLayouts extends Vue {
  gridOptions: GridOptions = null!
  columnDefs: ColDef[] = null!
  gridApi: GridApi = null!
  columnApi: ColumnApi = null!

  layouts: Layout[] = []
  layoutsLoading = false
  layoutSaving = false
  layoutsBeingDeleted: Layout[] = []

  editLayoutDialog = false
  editedLayoutIndex = -1
  editedLayoutItem = new Layout()

  beforeMount () {
    this.gridOptions = {
      context: {
        componentParent: this,
      },

      groupUseEntireRow: true,
      suppressScrollOnNewData: true,
      enableCellChangeFlash: true,
      suppressCellSelection: true,
      animateRows: true,
      domLayout: 'autoHeight',

      defaultColDef: {
        sortable: true,
        filter: true,
        enableRowGroup: true,
        resizable: true,
      },
    }

    this.columnDefs = [
      { headerName: 'ID', field: 'id' },

      {
        headerName: 'Property',
        valueGetter: (params) => {
          const property = this.$store.state.manager.properties.find((property: Property) => {
            return property.id === params.data.property_id
          })

          return property ? property.name : ''
        },
      },

      { headerName: 'Building', field: 'building' },
      { headerName: 'Floor', field: 'floor' },
      { headerName: 'Unit', field: 'unit' },
      { headerName: 'Unit Type', field: 'unit_type' },
      { headerName: 'Room', field: 'room' },
      { headerName: 'Room Type', field: 'room_type' },
      { headerName: 'Bed', field: 'bed' },

      {
        headerName: 'Action',
        sortable: false,
        filter: false,
        enableRowGroup: false,
        cellRendererFramework: ManagerSettingsLayoutsActionColumn,
        pinned: 'right',
        suppressSizeToFit: true,
      },
    ]

    this.loadLayouts()
  }

  onGridReady (params: GridReadyEvent) {
    this.gridApi = params.api
    this.columnApi = params.columnApi
  }

  get editLayoutFormTitle () {
    return this.editedLayoutIndex === -1 ? 'Add New Layout' : 'Edit Layout'
  }

  get editLayoutFormActivatorText () {
    return this.editedLayoutIndex === -1 ? 'Create' : 'Save'
  }

  @Watch('$store.state.manager.selectedProperties')
  onSelectedPropertiesChange () {
    this.loadLayouts()
  }

  openEditLayoutDialog (layout: Layout) {
    this.editedLayoutIndex = this.layouts.indexOf(layout)
    this.editedLayoutItem = new Layout(layout)

    this.editLayoutDialog = true
  }

  closeEditLayoutDialog () {
    this.editLayoutDialog = false

    // TODO Not sure what is this for.
    setTimeout(() => {
      this.editedLayoutIndex = -1
      this.editedLayoutItem = new Layout();

      (this.$refs.editLayoutForm as VForm).resetValidation()
    }, 300)
  }

  sizeColumns () {
    if (this.$vuetify.breakpoint.lgAndDown) {
      if (this.columnApi) this.columnApi.autoSizeAllColumns()
    } else {
      if (this.gridApi) this.gridApi.sizeColumnsToFit()
    }
  }

  async loadLayouts () {
    this.layoutsLoading = true

    /* tempfix 101 - force-disable grouping before a new data is loaded */
    if (this.columnApi) {
      const cols = this.columnApi.getAllColumns()
      this.columnApi.removeRowGroupColumns(cols)
    }
    /* /tempfix */

    try {
      this.layouts = await API_tmp.manager.settings.layouts.getLayouts(this.$store.getters['manager/selectedPropertiesIds'])
    } catch {
      this.$store.dispatch('ui/showError', "Couldn't load layouts")
    } finally {
      this.layoutsLoading = false
    }
  }

  async saveLayout () {
    if (!(this.$refs.editLayoutForm as VForm).validate()) return

    this.layoutSaving = true

    if (this.editedLayoutIndex === -1) {
      await this.createLayout(this.editedLayoutItem)
    } else {
      await this.updateLayout(this.editedLayoutItem)
    }

    this.layoutSaving = false
    this.closeEditLayoutDialog()
  }

  async createLayout (layout: Layout) {
    try {
      layout.id = await API_tmp.manager.settings.layouts.createLayout(layout)

      this.gridApi.updateRowData({ add: [layout] })

      this.$store.dispatch('ui/showSuccess', 'The layout created')
    } catch {
      this.$store.dispatch('ui/showError', "Couldn't create layout")
    }
  }

  async updateLayout (layout: Layout) {
    try {
      await API_tmp.manager.settings.layouts.updateLayout(layout)

      Object.assign(this.layouts[this.editedLayoutIndex], layout)
      this.gridApi.updateRowData({ update: [this.layouts[this.editedLayoutIndex]] })

      this.$store.dispatch('ui/showSuccess', 'The layout updated')
    } catch {
      this.$store.dispatch('ui/showError', "Couldn't update the layout")
    }
  }

  // noinspection JSUnusedGlobalSymbols
  async deleteLayout (layout: Layout) { // called from ManagerSettingsLayoutsActionColumn
    await (this.$refs.confirmationModal as ConfirmationModal).confirm('This action can not be undone! Are you sure you want to proceed?')

    this.layoutsBeingDeleted.push(layout)

    try {
      await API_tmp.manager.settings.layouts.deleteLayout(layout.id)

      this.gridApi.updateRowData({ remove: [layout] })

      this.$store.dispatch('ui/showSuccess', 'The layout deleted')
    } catch {
      this.$store.dispatch('ui/showError', "Couldn't delete the layout")
    } finally {
      this.layoutsBeingDeleted.splice(this.layoutsBeingDeleted.indexOf(layout), 1)
    }
  }
}
