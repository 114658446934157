import { render, staticRenderFns } from "./ManagerSettingsPropertiesActionColumn.vue?vue&type=template&id=27baf393&scoped=true"
import script from "./ManagerSettingsPropertiesActionColumn.vue?vue&type=script&lang=ts"
export * from "./ManagerSettingsPropertiesActionColumn.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27baf393",
  null
  
)

export default component.exports