
export class PropertyImage {
  constructor (data?: Partial<PropertyImage>) {
    Object.assign(this, data)
  }

  id: number | string = '';
  amenity_type: string = '';
  caption: string = '';
  image: string = '';
  order: number = 0;
  initialOrder?: number;
  created_at: string;
  updated_at: string;
}
