// TODO: should be renamed to LedgerType

export class AccountCode {
  constructor (data?: Partial<AccountCode>) {
    Object.assign(this, data)
  }

  id: number = NaN
  name: string = ''
  property_id: number = NaN
  type: string = ''
}
