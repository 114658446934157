
import 'lightgallery.js'
import 'lg-thumbnail.js'
import { Vue, Component, Prop } from 'vue-property-decorator'

declare global {
  interface Window {
    lightGallery: any;
  }
}

@Component({})
export default class GalleryWidget extends Vue {
  @Prop({ type: Array }) images!: { caption: string, url: string }[]

  checkIndex (idx: number) {
    return idx === 0 || idx === 5 ? 6 : 3
  }

  async openGallery () {
    (this.$refs.gallery as HTMLElement).querySelector('a').click()
  }

  mounted () {
    window.lightGallery(this.$refs.gallery, {
      enableTouch: true,
      thumbnail: true,
      enableThumbSwipe: true,
    })
  }
}
