
import { Vue, Component, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { AgGridVue } from 'ag-grid-vue'
import { ColDef, ColumnApi, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community'
import ManagerPropertySelector from '../components/manager/ManagerPropertiesSelector.vue'
import ManagerSettingsPropertiesActionColumn from '../components/manager/ManagerSettingsPropertiesActionColumn.vue'
import { Property } from '../middleware/Property'

const namespace: string = 'manager'
@Component({
  components: {
    ManagerPropertySelector,
    AgGridVue,
    ManagerSettingsPropertiesActionColumn,
  },
})
export default class ManagerSettingsProperties extends Vue {
  gridOptions: GridOptions = null!
  columnDefs: ColDef[] = null!
  propertiesLoading: boolean = false
  loadedProperties: Property[] = []
  properties: Property[] = []
  @Getter('selectedPropertiesIds', { namespace }) selectedPropertiesIds: any;

  beforeMount () {
    this.gridOptions = {
      context: {
        componentParent: this,
      },

      groupUseEntireRow: true,
      suppressScrollOnNewData: true,
      enableCellChangeFlash: true,
      suppressRowClickSelection: true,
      groupSelectsChildren: true,
      groupSelectsFiltered: true,
      animateRows: true,
      domLayout: 'autoHeight',

      defaultColDef: {
        sortable: true,
        filter: true,
        enableRowGroup: true,
        cellClass: 'manager-setting-website__cell',
        resizable: true,
        autoHeight: true,
      },
    }

    this.columnDefs = [
      { headerName: 'Id', field: 'id', maxWidth: 100 },
      { headerName: 'Name', field: 'name' },
      { headerName: 'Organization', field: 'organization_name' },
      { headerName: 'Address', valueFormatter: this.fullAddressFormatter },
      { headerName: 'Manager', valueFormatter: this.managerFormatter, minWidth: 270, maxWidth: 300 },
      { headerName: 'Actions', pinned: 'right', cellRendererFramework: ManagerSettingsPropertiesActionColumn },
    ]
    this.loadProperties()
  }

  fullAddressFormatter ({ data }) {
    return `${data.street}, ${data.city}, ${data.state} ${data.code}`
  }

  managerFormatter ({ data }) {
    return `${data.manager_fname} ${data.manager_lname} ${data.manager_email ? `(email: ${data.manager_email})` : ''}`
  }

  async loadProperties () {
    this.propertiesLoading = true
    try {
      this.loadedProperties = await this.$api.property.list()
    } catch (e) {
      /* eslint no-console: 0 */
      console.warn('error', e)
    } finally {
      this.propertiesLoading = false
    }
  }

  @Watch('$store.state.manager.selectedProperties')
  onSelectedPropertiesChange () {
    this.properties = this.loadedProperties.filter((property) => this.selectedPropertiesIds.includes(property.id))
  }

  @Watch('loadedProperties')
  onLoadedPropertiesChange () {
    this.properties = this.loadedProperties.filter((property) => this.selectedPropertiesIds.includes(property.id))
  }
}
