import { WebsiteBlockAttr } from './../middleware/WebsiteBlockAttr'
import { executeApiRequest } from '../middleware/api'
import { API } from './endpoints'

const getPropertyWebsiteBlockAttrs = async (prop: string) => executeApiRequest({ method: 'get', url: API.WEBSITE_BLOCK_ATTRS.PROPERTY_BASE, params: { prop } })
const editPropertyWebsiteBlockAttrs = async (id: string, formData: WebsiteBlockAttr) => executeApiRequest({ method: 'patch', url: API.WEBSITE_BLOCK_ATTRS.PROPERTY_SINGLE(id), data: formData })
const createPropertyWebsiteBlockAttr = async (formData: WebsiteBlockAttr) => executeApiRequest({ method: 'post', url: API.WEBSITE_BLOCK_ATTRS.PROPERTY_BASE, data: formData })

const getOrganizationWebsiteBlockAttrs = async (prop: string) => executeApiRequest({ method: 'get', url: API.WEBSITE_BLOCK_ATTRS.ORGANIZATION_BASE, params: { prop } })
const editOrganizationWebsiteBlockAttrs = async (id: string, formData: WebsiteBlockAttr) => executeApiRequest({ method: 'patch', url: API.WEBSITE_BLOCK_ATTRS.ORGANIZATION_SINGLE(id), data: formData })
const createOrganizationWebsiteBlockAttr = async (formData: WebsiteBlockAttr) => executeApiRequest({ method: 'post', url: API.WEBSITE_BLOCK_ATTRS.ORGANIZATION_BASE, data: formData })

export default {
  getPropertyWebsiteBlockAttrs,
  editPropertyWebsiteBlockAttrs,
  createPropertyWebsiteBlockAttr,
  getOrganizationWebsiteBlockAttrs,
  editOrganizationWebsiteBlockAttrs,
  createOrganizationWebsiteBlockAttr,
}
