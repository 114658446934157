import { executeApiRequest } from '../middleware/api'
import { API } from './endpoints'
import { Organization } from '../middleware/entities'

const getAllOrganizations = async (): Promise<Organization[]> => executeApiRequest({ method: 'get', url: API.ORGANIZATIONS.BASE })

const sendContactForm = async (id: string, formData: any) => executeApiRequest({ method: 'post', url: API.ORGANIZATIONS.CONTACT(id), data: formData })

export default {
  getAll: getAllOrganizations,
  sendContactForm,
}
