
import { Vue, Component } from 'vue-property-decorator'
import validationRules from '../../validation/validationRules'
import { authService } from '../../services/authServiceInstance'

@Component({
})
export default class LoginForm extends Vue {
  rules = {
    required: v => validationRules.checkRequired(v) || '',
    isEmail: v => validationRules.checkEmail(v) || '',
  }

  remember = true
  errorText = []
  credentials = {
    username: '',
    password: '',
  }

  get isValid (): boolean {
    return (this.$refs.form as Vue & { validate: () => boolean }).validate()
  }

  async sendForm () {
    if (!this.isValid) return false

    try {
      await authService.login(this.credentials)
      // Just to close modal
      this.$emit('sent')
      authService.redirectAfterLogin()
    } catch ({ response }) {
      this.errorText = []
      const resData = response.data
      if (resData) {
        for (const key in resData) {
          if (typeof resData[key] === 'string') {
            this.errorText.push(resData[key])
          } else {
            this.errorText.push(resData[key][0])
          }
        }
      }
    }
  }
}
