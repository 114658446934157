
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { State, Action, Getter } from 'vuex-class'
import FileUploader from '../common/FileUploader.vue'
import { Testimonial } from '../../middleware/Testimonial'

const namespace: string = 'property'

@Component({
  components: {
    FileUploader,
  },
})
export default class TestimonialsEditor extends Vue {
  @Prop({ type: String }) organizationId: string
  @Prop({ type: String }) propertyId: string
  @Action('setTestimonials', { namespace }) setTestimonials: any;
  @Getter('testimonials', { namespace }) testimonials: Testimonial[];
  @Getter('testimonialsLoaded', { namespace }) testimonialsLoaded: boolean;

  loadedImage: File[] = []
  dialog: boolean = false
  dialogLoading: boolean = false
  editedId: string = null
  formData: Testimonial = new Testimonial()
  errors: { [key: string ]: string } = {}

  async mounted () {
    if (this.propertyId) {
      this.loadTestimonials()
    }
  }

  @Watch('propertyId')
  async onPropertyIdChange (val) {
    if (val) {
      this.loadTestimonials()
    }
  }

  async loadTestimonials () {
    try {
      const testimonials = await this.$api.testimonial.getPropertyTestimonials(this.propertyId)
      this.setTestimonials(testimonials)
    } catch (e) {
      await this.$store.dispatch('ui/showError', 'Couldn\'t load testimonials')
    }
  }

  closeDialog () {
    this.dialog = false
    this.errors = {}
    this.formData = new Testimonial()
    this.editedId = null
    this.dialogLoading = false
  }

  reloadImageUploader () {
    // @ts-ignore
    this.$refs.loadedImage && this.$refs.loadedImage.reload()
  }

  async saveTestimonial () {
    this.dialogLoading = true
    const formData = new FormData()

    if (this.loadedImage.length) {
      formData.append('image', this.loadedImage[0])
    }
    formData.append('name', this.formData.name)
    formData.append('show_on_organization', `${this.formData.show_on_organization}`)
    formData.append('testimonial', this.formData.testimonial)
    formData.append('prop', this.propertyId)

    const valid = this.validate()
    if (valid) {
      try {
        if (this.editedId) {
          await this.$api.testimonial.editPropertyTestimonial(this.editedId, formData)
        } else {
          await this.$api.testimonial.createPropertyTestimonial(formData)
        }
      } catch (e) {
        await this.$store.dispatch('ui/showError', `Couldn't ${this.editedId ? 'edit' : 'create'} testimonial`)
      } finally {
        this.loadTestimonials()
        this.closeDialog()
      }
    }
    this.dialogLoading = false
  }

  editTestimonial (id: string) {
    this.editedId = id
    this.formData = { ...this.testimonials.find((testimonial) => id === testimonial.id) }
    this.reloadImageUploader()
    this.dialog = true
  }

  async deleteTestimonial (id: string) {
    try {
      await this.$api.testimonial.deleteTestimonial(id)
      this.loadTestimonials()
    } catch (e) {
      await this.$store.dispatch('ui/showError', 'Couldn\'t delete testimonial')
    }
  }

  validate () {
    const errors = {
      name: !this.formData.name.length ? 'Cannot be empty' : null,
      testimonial: !this.formData.testimonial ? 'Cannot be empty' : null,
      image: !this.editedId ? !this.loadedImage[0] ? 'Cannot be empty' : null : null,
    }
    if (Object.values(errors).filter(val => val).length) {
      this.errors = { ...errors }
      return false
    } else {
      return true
    }
  }
}
