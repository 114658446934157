
import { Vue, Component } from 'vue-property-decorator'
import { RentalApplication } from '../middleware/entities'
import API from '../middleware/API_tmp'

@Component({})
export default class ManagerSettingsRentalApplication extends Vue {
  rentalApplication: RentalApplication | null = null
  rentalApplicationLoading = false
  rentalApplicationUpdating = false

  mounted () {
    this.loadRentalApplication()
  }

  async loadRentalApplication () {
    this.rentalApplicationLoading = true

    try {
      this.rentalApplication = await API.manager.settings.rentalApplication.getRentalApplication()
    } catch {
      await this.$store.dispatch('ui/showError', "Couldn't load rental application")
    } finally {
      this.rentalApplicationLoading = false
    }
  }

  async updateRentalApplication () {
    this.rentalApplicationUpdating = true

    try {
      await API.manager.settings.rentalApplication.updateRentalApplication(this.rentalApplication as RentalApplication)

      await this.$store.dispatch('ui/showSuccess', 'Rental application updated')
    } catch (e) {
      await this.$store.dispatch('ui/showError', "Couldn't update rental application")
    } finally {
      this.rentalApplicationUpdating = false
    }
  }
}
