
/*eslint-disable */
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

import { gmapApi } from 'vue2-google-maps';
import GmapMap from 'vue2-google-maps/dist/components/map.vue';
import GmapMarker from 'vue2-google-maps/dist/components/marker';

declare global {
  interface Window { google: any; }
}

@Component({
  components: {
    GmapMap,
    GmapMarker
  }
})

export default class PropertyLocation extends Vue {
  @Prop({ type: String }) address!: string;

  mapCenter = {lat: 38.8975, lng: -77.0366};

  setCenter() {
    if (!this.address || !window.google) return;
    let geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({address: this.address}, (results, status) => {
      if (results && results.length) {
        this.mapCenter = results[0].geometry.location;
      }
    })
  }

  mounted () {
    const pingApi = setInterval(() => {
      if (window.google) {
        clearInterval(pingApi)
        this.setCenter()
      }
    }, 100);
  }
}
/* eslint-enable */
