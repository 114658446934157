
export class Amenity {
  constructor (data?: Partial<Amenity>) {
    Object.assign(this, data)
  }

  id: number | string = '';
  amenity_type: string = '';
  name: string = '';
  image: string = '';
  order: number = 0;
  initialOrder?: number;
}
