
import { Component, Prop, Vue } from 'vue-property-decorator'
import { LeasePeriodFilters } from './LeasePreiodTypes'
import { LeasePeriodSemester } from '../../../middleware/LeasePeriodSemester'
import RcFilterIcon from './RcFilterIcon.vue'

@Component({
  name: 'LeasePeriodsFilters',
  components: { RcFilterIcon },
})
export default class LeasePeriodsFilters extends Vue {
  @Prop({ required: true }) leasePeriodFilters: LeasePeriodFilters
  @Prop({ required: true }) semesters: LeasePeriodSemester[]

  genderOptions = [
    { value: 'M', text: 'Male' },
    { value: 'F', text: 'Female' },
  ]

  roomTypeOptions = [
    { value: 'shared', text: 'Shared' },
    { value: 'private', text: 'Private' },
  ]

  dateSortOptions = [
    { value: 'asc', text: 'Ascending' },
    { value: 'desc', text: 'Descending' },
  ]
}
