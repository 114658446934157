
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { PortfolioItem } from '../../middleware/PortfolioItem'
import { Swiper as VueSwiper, SwiperSlide } from 'vue-awesome-swiper'

import PropertyCard from './PropertyCard.vue'
import { lazyLoadingHack } from '../../mixins/lazyLoadingHack'
import Swiper, { SwiperOptions } from 'swiper'

const namespace: string = 'property'

@Component({
  name: 'PortfolioSelect',
  components: {
    VueSwiper,
    SwiperSlide,
    PropertyCard,
  },
})

export default class PortfolioSelect extends Vue {
  @Prop({ required: true, type: Array }) portfolios: PortfolioItem[]
  @Prop({ type: Number }) value: number
  @Ref() vueSwiper: any // really VueSwiper, but gives TS error

  swiperOptions: SwiperOptions = {
    autoplay: {
      delay: 5000,
    },
    spaceBetween: 30,
    watchOverflow: true,
    allowTouchMove: false,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 2,
      },
      640: {
        slidesPerView: 3,
      },
      980: {
        slidesPerView: 4,
      },
    },
  };

  get swiperInstance (): Swiper {
    return this.vueSwiper.$swiper
  }

  next () {
    this.swiperInstance.slideNext()
  }

  prev () {
    this.swiperInstance.slidePrev()
  }

  async mounted () {
    lazyLoadingHack()
  }

  selectPortfolio (portfolioId: number): void {
    this.$emit('input', portfolioId)
  }

  get hasProperties (): boolean {
    return this.portfolios.some(portfolio => portfolio.properties.length)
  }
}
