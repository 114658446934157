
import { Vue, Component, Prop } from 'vue-property-decorator'
import { MediaItem, extensions } from '../../middleware/MediaItem'

import VideoPlayer from './VideoPlayer.vue'

@Component({
  components: {
    VideoPlayer,
  },
})
export default class MediaView extends Vue {
  @Prop({ type: String }) media!: string;
  @Prop({ type: Boolean }) active!: boolean;

  loaded: boolean = false
  style = { top: 0 }

  get preparedMedia ():MediaItem {
    const fileExtFull = /^.+\.([^.]+)$/.exec(this.media)
    if (!fileExtFull || !fileExtFull.length) return null

    const fileExt = fileExtFull[1].replace(/\?.*/, '')

    return extensions.reduce((acc, i) => {
      if (i.formats.includes(fileExt)) {
        acc = { ...acc, type: i.type, ext: fileExt }
      }
      return acc
    }, {
      url: this.media,
      type: '',
      ext: '',
      file: '',
      id: '',
    })
  }

  generateVideoOptions () {
    return {
      autoplay: false,
      controls: false,
      muted: true,
      loop: true,
      sources: [
        {
          src: this.preparedMedia.url,
          type: `video/${this.preparedMedia.ext}`,
        },
      ],
    }
  }

  onLoaded () {
    this.loaded = true
  }

  handleScroll () {
    if (this.$refs.parallaxEl) {
      const pageTop = window.scrollY
      const newTop = (pageTop * 0.5)
      const style = (this.$refs.parallaxEl as any).$el
        ? (this.$refs.parallaxEl as any).$el.style
        : (this.$refs.parallaxEl as any).style

      style.transform = `translate3d(0, ${newTop}px, 0)`
      style.height = `calc(${style.height} - ${pageTop})`
    }
  }

  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  }

  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
