export const extensions = [{
  type: 'video',
  formats: ['m4v', 'avi', 'mpg', 'mp4', 'webm'],
}, {
  type: 'image',
  formats: ['jpg', 'gif', 'bmp', 'png', 'jpeg'],
}]

export class MediaItem {
  constructor (data?: Partial<MediaItem>) {
    Object.assign(this, data)
  }

  id: string = '';
  type: string = '';
  url: string = '';
  file: string = '';
  ext: string = '';
  order?: number;
  organization?: number;
}
