import { executeApiRequest } from './../middleware/api'
import axios from '../middleware/axios'
import { API } from './endpoints'
import { Resource } from '../middleware/Resource'

const list = async (prop: string) => {
  const response = (await axios.get(API.PROPERTY_RESOURCES.BASE, { params: { prop } })).data
  return response.map((resource: Partial<Resource>) => new Resource(resource))
}
const get = () => executeApiRequest({ method: 'get', url: API.PROPERTY_RESOURCES.BASE })
const create = async (data: FormData) => axios({
  method: 'post',
  url: API.PROPERTY_RESOURCES.BASE,
  headers: { 'Content-Type': 'multipart/form-data' },
  data,
})
const edit = async (id: string, data: FormData) => axios({
  method: 'put',
  url: API.PROPERTY_RESOURCES.SINGLE(id),
  headers: { 'Content-Type': 'multipart/form-data' },
  data,
})
const deleteResource = async (id: string) => axios.delete(API.PROPERTY_RESOURCES.SINGLE(id))

export default {
  list,
  get,
  create,
  edit,
  deleteResource,
}
