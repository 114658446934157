// TODO Hack: this is very bad.

export const lazyLoadingHack = () => {
  const images = document.querySelectorAll('.lazy-image') || []
  if (images.length) {
    images.forEach((i) => loadImage(i))
  }
}

export default {
  mounted () {
    lazyLoadingHack()
  },
}

const loadImage = (elem) => {
  if (!elem) return
  elem.addEventListener('load', () => {
    elem.classList.add('loaded')
  })
  elem.addEventListener('error', () => {
    elem.classList.add('loading-error')
  })
}
