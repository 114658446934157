
import { Vue, Component, Prop } from 'vue-property-decorator'
import validationRules from '../../validation/validationRules'
import { State, Action, Getter } from 'vuex-class'
import PhoneInput from './../common/PhoneInput.vue'
import { authService } from '../../services/authServiceInstance'

@Component({
  components: { PhoneInput },
})
export default class RegisterForm extends Vue {
  @Getter('authModal', { namespace: 'ui' }) authModal: any;

  rules = {
    required: v => validationRules.checkRequired(v),
    isEmail: v => validationRules.checkEmail(v),
    checkPassword: v => validationRules.compareStrings(v, this.formData.password),
    isPhone: v => validationRules.checkPhone(v),
    minLength: v => validationRules.minLength(v, 8),
    bothRegisters: v => validationRules.bothRegisters(v),
  };

  confirmPassword = '';
  errorText = '';
  formData = {
    email: '',
    first_name: '',
    last_name: '',
    cell_phone: '',
    password: '',
    gender: '',
    is_active: true,
    assigned_property: '',
    agreement_period: '',
    start_date: '',
    end_date: '',
  };

  get isValid (): boolean {
    return (this.$refs.form as Vue & { validate: () => boolean }).validate()
  }

  async sendForm () {
    if (!this.isValid) return false
    // @ts-ignore
    const cell_phone = this.$refs.phoneInput ? this.$refs.phoneInput.getFullValue() : this.formData.cell_phone
    // TODO Use proper type for register payload.
    // TODO Not super sure why would we need data from 3 sources. Likely hack.
    const fullData = Object.assign({}, this.formData, this.authModal.data, { cell_phone })

    // HACK Backend requires filled property.
    if (!fullData.assigned_property) {
      if (this.$route.name === 'Property') {
        fullData.assigned_property = this.$route.params.id
      } else {
        const organizations = await this.$api.organization.getAll()
        fullData.assigned_property = organizations[0].properties[0].id
      }
    }

    try {
      await this.$api.auth.register(fullData)
      await authService.login({ username: fullData.email, password: fullData.password })
      authService.redirectAfterLogin()
    } catch (data) {
      this.errorText = data.detail
    }
  }
}
