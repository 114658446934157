
import Vue from 'vue'
import Component from 'vue-class-component'
import { authService } from '../services/authServiceInstance'

@Component({})
export default class App extends Vue {
  beforeCreate () {
    authService.init()
  }
}
