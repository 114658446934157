import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.NODE_ENV === 'production'
      ? 'AIzaSyCyk8umaS8NEYzavAJ2Xfua_ZKeL2eysjg'
      : 'AIzaSyCcgc7cylZn7kWNBY8D5jS_iYzsPTYDPqE',
    libraries: 'places',
  },
  installComponents: true,
})
