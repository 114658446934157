
import { Component, Vue } from 'vue-property-decorator'
import { ICellRendererParams } from 'ag-grid-community'

@Component({})
export default class LeasePeriodsTableSemesters extends Vue {
  params!: ICellRendererParams

  get leasePeriod () {
    return this.params.data
  }
}
