import { LeasePeriodSemester } from './LeasePeriodSemester'
import { SubPeriod } from './entities'

export class LeasePeriod {
  id?: number = undefined
  name: string = ''
  // TODO Should be property_id.
  property: number | null = null
  // TODO Check if this is relevant somewhere.
  property_id: number | null = null // inheritance binding
  property_name: string = ''
  visible: boolean = false
  is_for_custom_lease: boolean = false
  start_date: string | null = null
  end_date: string | null = null
  open_to_renewals: boolean = false
  allow_same_bed_renewals: boolean = false
  // TODO Check what this data is.
  periods_to_renew_from: LeasePeriod[] = []
  originally_visible: boolean = false
  visible_to_managers: boolean = false
  genders_available: ('M' | 'F')[] = []
  sub_periods: SubPeriod[] = []
  semesters: LeasePeriodSemester[] = []
  contract_template_ids: number[] = []
  guarantor_contract_template_ids: number[] = []
  available: boolean = false
  constructor (data?: Partial<LeasePeriod>) {
    if (data && data.sub_periods) {
      data.sub_periods = data.sub_periods.map((rawSubPeriod: Partial<SubPeriod>) => {
        return rawSubPeriod instanceof SubPeriod ? rawSubPeriod : new SubPeriod(rawSubPeriod)
      })
    }
    data.available = data.visible && !data.open_to_renewals && !data.allow_same_bed_renewals
    Object.assign(this, data)
  }
}
