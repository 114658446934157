export class PortfolioItem {
  constructor (data?: Partial<PortfolioItem>) {
    Object.assign(this, data)
  }

  id: number | string = '';
  name: string = '';
  image: string = '';
  properties: number[] = [];
}
