
import { Vue, Component, Watch } from 'vue-property-decorator'
import { State, Getter, Action } from 'vuex-class'
import API_tmp from '../middleware/api'
import ManagerSettingsEditorSubmenu from '../components/manager/ManagerSettingsEditorSubmenu.vue'
import PropertiesPortfolioEditor from '../components/manager/PropertiesPortfolioEditor.vue'
import WebpagePropertiesListEditor from '../components/manager/WebpagePropertiesListEditor.vue'
import TestimonialsCarousel from '../components/common/TestimonialsCarousel.vue'
import { Organization } from '../middleware/entities'
import { MediaItem, extensions } from '../middleware/MediaItem'
import WebpageModelEditor from '../components/manager/WebpageModelEditor.vue'
import FileUploader from '../components/common/FileUploader.vue'
import InstagramWidget from '../components/common/InstagramWidget.vue'
import { WebsiteBlockAttr } from '../middleware/WebsiteBlockAttr'

const namespace: string = 'organization'

const websiteBlockAttrs = [
  'discover-you-new-home',
  'featured-locations',
  'about',
  'latest-posts',
  'what-people-say-about-us',
  'have-a-question?-please-ask-us',
]
@Component({
  components: {
    FileUploader,
    ManagerSettingsEditorSubmenu,
    PropertiesPortfolioEditor,
    WebpagePropertiesListEditor,
    TestimonialsCarousel,
    WebpageModelEditor,
    InstagramWidget,
  },
})
export default class ManagerSettingsCompany extends Vue {
  @Getter('organization', { namespace }) organization: any;
  @Getter('organizationLoaded', { namespace }) organizationLoaded: any;
  @Action('loadOrganization', { namespace }) loadOrganization: any;
  formData: Organization | null = new Organization()
  loadedBackgroundMedia: File[] = []
  deletedBackgroundMedia: Set<MediaItem> = new Set()
  reorderedBackgroundMedia: MediaItem[] = []
  isLoading: boolean = true
  changedFields: Set<string> = new Set()
  websiteBlockAttrs: { [key: string]: WebsiteBlockAttr} = {}
  submenuLinks = [
    {
      href: 'header-media',
      text: 'Header media',
      icon: 'image',
    },
    {
      href: 'property-portfolio',
      text: 'Property portfolio',
      icon: 'description',
    },
    {
      href: 'properties-list',
      text: 'Properties list',
      icon: 'format_list_bulleted',
    },
    {
      href: 'about',
      text: 'About',
      icon: 'description',
    },
    {
      href: 'testimonials',
      text: 'Testimonials',
      icon: 'emoji_events',
    },
    {
      href: 'contacts',
      text: 'Contact information',
      icon: 'phone',
    },
    {
      href: 'social-networks',
      text: 'Social networks',
      icon: 'public',
    },
    {
      href: 'webpage-model',
      text: 'Webpage model',
      icon: 'desktop_mac',
    },
  ]

  get backgroundMedia () {
    return this.organization
      ? this.organization.background_media.map(media => ({
        ...media,
        url: media.file,
        type: (extensions.find(item => item.formats.includes(media.file.split('.').pop())) || { type: '' }).type,
      }))
      : []
  }

  deleteBackgroundMedia (deletedImage) {
    this.deletedBackgroundMedia.add(this.organization.background_media.find(({ file }) => file === deletedImage.file))
  }

  async mounted () {
    if (!this.organizationLoaded) {
      this.loadOrganization()
    } else {
      this.formData = { ...this.organization }
      this.isLoading = false
    }
  }

  @Watch('organizationLoaded')
  async onOrganizationLoaded () {
    this.formData = { ...this.organization }
    this.websiteBlockAttrs = websiteBlockAttrs
      .map(attr => this.organization.website_block_attrs.find(({ block_name }) => block_name === attr))
      .reduce((cur, block) => {
        cur[block.block_name] = { ...block }
        return cur
      }, {})
    this.isLoading = false
  }

  async saveCompanyPage () {
    this.isLoading = true
    const savingOrg: Partial<Organization> = {
      id: this.organization.id,
    }
    this.changedFields.forEach((field: string) => {
      savingOrg[field] = this.formData[field]
    })

    try {
      if (this.loadedBackgroundMedia.length || this.deletedBackgroundMedia.size || this.reorderedBackgroundMedia.length) {
        await this.loadBgMedia()
      }
      await API_tmp.manager.saveOrganization(`${this.organization.id}`, savingOrg)
      await this.loadWebsiteBlockAttrs()
      this.loadOrganization()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
      await this.$store.dispatch('ui/showError', "Couldn't save company page")
    } finally {
      this.clearForm()
      this.$store.dispatch('ui/showSuccess', 'Your changes have successfully saved')
    }
  }

  clearForm () {
    this.changedFields.clear()
    this.loadedBackgroundMedia = []
    this.deletedBackgroundMedia.clear()
    // @ts-ignore
    this.$refs.backgroundMedia && this.$refs.backgroundMedia.reload()
    this.isLoading = false
  }

  async loadBgMedia () {
    await Promise.all(
      this.loadedBackgroundMedia
        .map(media => {
          const formData = new FormData()
          formData.append('organization', `${this.organization.id}`)
          formData.append('file', media)
          return this.$api.bgMedia.uploadOrganizationMedia(formData)
        })
        .concat(Array.from(this.deletedBackgroundMedia).map(media => {
          return this.$api.bgMedia.deleteOrganizationMedia(media.id)
        }))
        .concat(this.reorderedBackgroundMedia.reduce((res, { order, id }, idx) => {
          const defaultMedia = this.backgroundMedia[idx]

          if (order !== defaultMedia.order || id !== defaultMedia.id) {
            res.push(this.$api.bgMedia.changeOrganizationMediaOrder(id, order))
          } return res
        }, [])),
    )
  }

  async loadWebsiteBlockAttrs () {
    await Promise.all(Object.keys(this.websiteBlockAttrs)
      .map(block => {
        const websiteBlock = this.websiteBlockAttrs[block]
        if (websiteBlock.id) {
          return this.$api.websiteBlockAttr.editOrganizationWebsiteBlockAttrs(`${websiteBlock.id}`, websiteBlock)
        } else {
          return this.$api.websiteBlockAttr.createOrganizationWebsiteBlockAttr({ ...websiteBlock, organization: this.organization.id })
        }
      }),
    )
  }
}
