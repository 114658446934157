import Vue from 'vue'
import Router, { RouteConfig } from 'vue-router'
import RouterBypass from '../views/RouterBypass.vue'
import WebLayout from '../components/common/WebLayout.vue'
import Home from '../views/Home.vue'
import Property from '../views/Property.vue'
import Login from '../views/LoginPage.vue'
import Manager from '../views/Manager.vue'
import ManagerSettings from '../views/ManagerSettings.vue'
import ManagerSettingsLayouts from '../views/ManagerSettingsLayouts.vue'
import ManagerSettingsPaymentPlans from '../views/PaymentPlans/PaymentPlans.vue'
import ManagerSettingsPaymentPlansEditor from '../views/PaymentPlans/PaymentPlansEditor.vue'
import ManagerSettingsAssignPlans from '../views/ManagerSettingsAssignPlans.vue'
import ManagerSettingsDocumentTemplates from '../views/ManagerSettingsDocumentTemplates.vue'
import ManagerSettingsDocumentTemplatesEditor from '../views/ManagerSettingsDocumentTemplatesEditor.vue'
import ManagerSettingsRentalApplication from '../views/ManagerSettingsRentalApplication.vue'
import LeasePeriodsPage from '../views/LeasePeriods/LeasePeriodsPage.vue'
import ManagerSettingsProperties from '../views/ManagerSettingsProperties.vue'
import ManagerSettingsPropertyEditor from '../views/ManagerSettingsPropertyEditor.vue'
import ManagerSettingsCompany from '../views/ManagerSettingsCompany.vue'

Vue.use(Router)

export const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: WebLayout,
    children: [
      {
        path: '/login',
        name: 'Login',
        component: Login,
        props: ({ query }) => ({ redirect: query.redirect }),
      },
      {
        path: '/',
        name: 'Home',
        component: Home,
      },
      {
        path: '/property/:id',
        name: 'Property',
        component: Property,
        props: true,
      },
    ],
  },

  {
    path: '/manager',
    name: 'Manager',
    component: Manager,
    meta: {
      link: {
        text: 'Manager',
      },
    },
    children: [
      {
        path: 'settings',
        component: RouterBypass,
        meta: {
          bypass: 'ManagerSettings',
          link: {
            text: 'Settings',
            icon: 'settings',
          },
        },
        children: [
          {
            path: '',
            name: 'ManagerSettings',
            component: ManagerSettings,
          },

          {
            path: 'layouts',
            name: 'layouts',
            component: ManagerSettingsLayouts,
            meta: {
              link: {
                text: 'Layouts',
              },
            },
          },

          {
            path: 'lease-periods',
            name: 'lease-periods',
            component: LeasePeriodsPage,
            meta: {
              link: {
                text: 'Lease Periods',
              },
            },
          },
          {
            path: 'payment-plans',
            component: RouterBypass,
            meta: {
              bypass: 'payment-plans',
              link: {
                text: 'Payment Plans',
              },
            },
            children: [
              {
                path: '',
                name: 'payment-plans',
                component: ManagerSettingsPaymentPlans,
              },

              {
                path: ':id/edit',
                name: 'ManagerSettingsPaymentPlansEditor',
                component: ManagerSettingsPaymentPlansEditor,
                props: ({ params }) => ({
                  paymentPlanId: parseInt(params.id),
                  previewOnlyMode: false,
                }),
                meta: {
                  link: {
                    text: 'Edit',
                  },
                },
              },

              {
                path: ':id',
                name: 'ManagerSettingsPaymentPlansPreviewer',
                component: ManagerSettingsPaymentPlansEditor,
                props: ({ params }) => ({
                  paymentPlanId: parseInt(params.id),
                  previewOnlyMode: true,
                }),
                meta: {
                  link: {
                    text: 'Preview',
                  },
                },
              },
            ],
          },

          {
            path: 'assign-plans',
            name: 'assign-plans',
            component: ManagerSettingsAssignPlans,
            meta: {
              link: {
                text: 'Assign Plans',
              },
            },
          },

          {
            path: 'document-templates',
            component: RouterBypass,
            meta: {
              bypass: 'document-templates',
              link: {
                text: 'Document Templates',
              },
            },
            children: [
              {
                path: '',
                name: 'document-templates',
                component: ManagerSettingsDocumentTemplates,
              },

              {
                path: ':id/edit',
                name: 'ManagerSettingsDocumentTemplatesEditor',
                component: ManagerSettingsDocumentTemplatesEditor,
                props: ({ params }) => ({
                  documentTemplateId: parseInt(params.id),
                  previewOnlyMode: false,
                }),
                meta: {
                  link: {
                    text: 'Edit',
                  },
                },
              },

              {
                path: ':id',
                name: 'ManagerSettingsDocumentTemplatesPreviewer',
                component: ManagerSettingsDocumentTemplatesEditor,
                props: ({ params }) => ({
                  documentTemplateId: parseInt(params.id),
                  previewOnlyMode: true,
                }),
                meta: {
                  link: {
                    text: 'Preview',
                  },
                },
              },
            ],
          },

          {
            path: 'rental-application',
            name: 'rental-application',
            component: ManagerSettingsRentalApplication,
            meta: {
              link: {
                text: 'Rental Application',
              },
            },
          },

          {
            path: 'property-webpages',
            component: RouterBypass,
            meta: {
              bypass: 'property-webpages',
              link: {
                text: 'Property Webpages',
              },
              fromOrigin: true,
            },
            children: [
              {
                path: '',
                name: 'property-webpages',
                component: ManagerSettingsProperties,
              },
              {
                path: ':id/edit',
                name: 'ManagerSettingsPropertyEditor',
                component: ManagerSettingsPropertyEditor,
                props: ({ params }) => ({
                  propertyId: parseInt(params.id),
                  previewOnlyMode: false,
                }),
                meta: {
                  link: {
                    text: 'Edit',
                  },
                  fromOrigin: true,
                },
              },
            ],
          },
          {
            path: 'company-webpage',
            component: RouterBypass,
            meta: {
              link: {
                text: 'Company Webpage',
              },
            },
            children: [
              {
                path: '',
                name: 'company-webpage',
                component: ManagerSettingsCompany,
              },
            ],
          },
        ],
      },
    ],
  },
]

const scrollBehavior = (to, from) => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
  return { x: 0, y: 0 }
}

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior,
  routes,
})
