import property from './PropertyRepository'
import leasePeriod from './LeasePeriodRepository'
import amenity from './AmenityRepository'
import bgMedia from './BgMediaRepository'
import testimonial from './TestimonialRepository'
import portfolio from './PortfolioRepository'
import websiteBlockAttr from './WebsiteBlockAttrRepository'
import propertyFriend from './PropertyFriendsRepository'
import auth from './AuthRepository'
import organization from './OrganizationsRepository'
import resource from './ResourceRepository'
import paymentPlan from './PaymentPlanRepository'
import ledger from './LedgerRepository'
import semester from './SemestersRepository'

export default {
  property,
  leasePeriod,
  amenity,
  bgMedia,
  testimonial,
  portfolio,
  websiteBlockAttr,
  propertyFriend,
  auth,
  organization,
  resource,
  paymentPlan,
  ledger,
  semester,
}
