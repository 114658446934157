<template>
  <svg
    width="12"
    height="30"
    viewBox="0 0 12 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.625 0C7.69605 0 9.375 1.67895 9.375 3.75C9.375 5.82105 7.69605 7.5 5.625 7.5C3.55395 7.5 1.875 5.82105 1.875 3.75C1.875 1.67895 3.55395 0 5.625 0ZM8.4375 8.4375H7.77187C6.44115 9.04939 4.86621 9.07582 3.47813 8.4375H2.8125C1.25918 8.4375 0 9.69668 0 11.25V19.2188C0 19.9954 0.62959 20.625 1.40625 20.625H2.34375V28.5938C2.34375 29.3704 2.97334 30 3.75 30H7.5C8.27666 30 8.90625 29.3704 8.90625 28.5938V20.625H9.84375C10.6204 20.625 11.25 19.9954 11.25 19.2188V11.25C11.25 9.69668 9.99082 8.4375 8.4375 8.4375Z"
      fill="#789bff"
      fill-opacity="0.7"
    />
  </svg>
</template>

<script>
export default {
  name: 'RcMaleIcon',
}
</script>
