<template>
  <svg
    style="margin-bottom: -2px"
    width="25"
    height="15"
    viewBox="0 0 25 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.875 7.5C8.59805 7.5 10 6.09805 10 4.375C10 2.65195 8.59805 1.25 6.875 1.25C5.15195 1.25 3.75 2.65195 3.75 4.375C3.75 6.09805 5.15195 7.5 6.875 7.5ZM20.625 2.5H11.875C11.5297 2.5 11.25 2.77969 11.25 3.125V8.75H2.5V0.625C2.5 0.279687 2.22031 0 1.875 0H0.625C0.279687 0 0 0.279687 0 0.625V14.375C0 14.7203 0.279687 15 0.625 15H1.875C2.22031 15 2.5 14.7203 2.5 14.375V12.5H22.5V14.375C22.5 14.7203 22.7797 15 23.125 15H24.375C24.7203 15 25 14.7203 25 14.375V6.875C25 4.45859 23.0414 2.5 20.625 2.5Z"
      fill="#8E9AA5"
      fill-opacity="0.7"
    />
  </svg>
</template>

<script>
export default {
  name: 'RcBedIcon',
}
</script>
