<template>
  <svg
    class="RcFilterIcon"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2962 0H0.703942C0.0793913 0 -0.235755 0.757793 0.206774 1.20032L5.62499 6.61936V12.6562C5.62499 12.8857 5.73694 13.1007 5.92491 13.2323L8.26865 14.8723C8.73105 15.196 9.37499 14.868 9.37499 14.2963V6.61936L14.7934 1.20032C15.235 0.758672 14.922 0 14.2962 0Z"
      fill="#5D6E7F"
    />
  </svg>
</template>

<script>
export default {
  name: 'RcFilterIcon',
}
</script>

<style lang="scss">
.RcFilterIcon {
  vertical-align: text-bottom;
}
</style>
