import { WebsiteBlockAttr } from './WebsiteBlockAttr'
import { MediaItem } from './MediaItem'
import { Amenity } from './Amenity'
import { Image } from './entities'
import { LeasePeriod } from './LeasePeriod'

export class Property {
  constructor (data?: Partial<Property>) {
    if (data && data.lease_periods) {
      data.lease_periods = data.lease_periods.map((rawLeasePeriod: Partial<LeasePeriod>) => {
        return rawLeasePeriod instanceof LeasePeriod ? rawLeasePeriod : new LeasePeriod(rawLeasePeriod)
      })
    }

    Object.assign(this, data)
  }

  id: number = NaN;
  name: string = '';
  organization_id: number = NaN; // inheritance binding
  property_images: Image[]= [];
  lease_periods: LeasePeriod[] = [];
  amenities: Amenity[] = [];
  background_image: string = '';
  background_media: MediaItem[] = [];
  street: string = '';
  city: string = '';
  state: string = '';
  code: string = '';
  full: boolean = false;
  facebook: string = '';
  twitter: string = '';
  instagram: string = '';
  serve_new_site: boolean = false
  website_block_attrs: WebsiteBlockAttr[] = []
  status: string = ''
}
