
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Resource } from '../../middleware/Resource'
import FileUploader from '../common/FileUploader.vue'
import { State, Action, Getter } from 'vuex-class'

const namespace: string = 'property'

@Component({
  components: {
    FileUploader,
  },
})

export default class ResourcesEditor extends Vue {
  @Prop({ type: String, required: true }) propertyId: string;
  @Prop({ type: Array }) propertyResources: Resource[];
  @Action('setResources', { namespace }) setResources: any;
  @Getter('resources', { namespace }) resources: Resource[];
  @Getter('resourcesLoaded', { namespace }) resourcesLoaded: boolean;

  dialog: boolean = false
  dialogLoading: boolean = false
  editedResourceId: string | null = null
  formData: Resource = new Resource()
  loadedFile: File[] = []

  mounted () {
    if (this.propertyResources && this.propertyResources.length) {
      this.setResources(this.propertyResources)
    } else if (!this.resourcesLoaded) {
      this.loadResources()
    }
  }

  loadResources = async () => {
    let resources = []
    try {
      resources = await this.$api.resource.list(this.propertyId)
    } catch (e) {
      await this.$store.dispatch('ui/showError', 'Couldn\'t load resources')
    } finally {
      this.setResources(resources)
    }
  }

  closeDialog () {
    this.dialog = false
    this.formData = new Resource()
    this.editedResourceId = null
    this.loadResources()
  }

  reloadImageUploader () {
    // @ts-ignore
    this.$refs.resource_file && this.$refs.resource_file.reload()
  }

  async saveResource () {
    this.dialogLoading = true
    const formData = new FormData()

    if (this.loadedFile.length) {
      formData.append('resource_file', this.loadedFile[0])
    }
    formData.append('name', this.formData.name)
    formData.append('prop', this.propertyId)
    try {
      if (this.editedResourceId) {
        formData.append('id', this.editedResourceId)
        await this.$api.resource.edit(this.editedResourceId, formData)
      } else {
        const res = await this.$api.resource.create(formData)
      }
    } catch (e) {
      await this.$store.dispatch('ui/showError', `Couldn't ${this.editedResourceId ? 'edit' : 'create'} property resource`)
    } finally {
      this.closeDialog()
      this.dialogLoading = false
    }
  }

  editResource (id: string) {
    this.editedResourceId = id
    this.formData = { ...this.resources.find((resource) => id === resource.id) }
    this.reloadImageUploader()
    this.dialog = true
  }

  async deleteResource (id: string) {
    await this.$api.resource.deleteResource(id)
    this.setResources(this.resources.filter(resource => resource.id !== id))
  }
}
