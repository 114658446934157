// @ts-ignore
import Quill from 'quill'

const Embed = Quill.import('blots/embed')

class Variable extends Embed {
  static blotName = 'Variable';
  static tagName = 'span';
  static className = 'quill-variable';

  static create (variable: { name: string, value: string, color?: string }) {
    const computedVariable = typeof variable === 'object' ? variable : JSON.parse(variable)
    const node = super.create(computedVariable)

    node.innerText = computedVariable.name

    node.setAttribute('spellcheck', 'false')
    node.setAttribute('autocomplete', 'off')
    node.setAttribute('autocorrect', 'off')
    node.setAttribute('autocapitalize', 'off')

    node.setAttribute('data-variable', JSON.stringify(computedVariable))

    if (computedVariable.color) {
      node.setAttribute('class', this.className.concat(` ${this.className}--${computedVariable.color}`))
    }
    return node
  }

  static value (domNode: HTMLElement) {
    return domNode.dataset.variable
  }

  update (mutations: any, context: any) { // this method has something to do with some GKeyboard bug on androids and variables copying
    for (const mutation of mutations) {
      if (mutation.type !== 'childList') continue
      if (mutation.removedNodes.length === 0) continue

      const nodeType = mutation.removedNodes[0].nodeType
      return setTimeout(() => this._remove(nodeType), 0)
    }

    const unhandledMutations = mutations.filter((m: any) => m.type !== 'childList')
    super.update(unhandledMutations, context)
  }

  _remove!: (nodeType: any) => any;
}

// noinspection JSIgnoredPromiseFromCall
Quill.register({
  'formats/Variable': Variable,
})

export function insertVariable (editor: Quill, variable: { name: string, value?: string, color?: string }) {
  Variable.prototype._remove = function (nodeType: any) {
    let adjust = 0
    if (nodeType === Node.TEXT_NODE) adjust = -1

    const cursorPosition = editor.getSelection().index + adjust
    // @ts-ignore
    this.remove()

    setTimeout(() => editor.setSelection(cursorPosition, Quill.sources.API), 0)
  }

  const range = editor.selection.savedRange
  if (!range || range.length !== 0) return

  const cursorPosition = range.index

  editor.insertEmbed(cursorPosition, 'Variable', variable, Quill.sources.API)
  editor.insertText(cursorPosition + 1, ' ', Quill.sources.API)
  editor.setSelection(cursorPosition + 2, Quill.sources.API)
}
