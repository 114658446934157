import { Ledger } from './Ledger'

export class PaymentPlan {
  constructor (data?: Partial<PaymentPlan>) {
    if (data && data.ledger_entries) {
      data.ledger_entries = data.ledger_entries.map((rawLedger: Partial<Ledger>) => {
        return rawLedger instanceof Ledger ? rawLedger : new Ledger(rawLedger)
      })
    }

    Object.assign(this, data)
  }

  static get types () {
    return ['New Tenant', 'Renewal Tenant', 'Renewal Transfer Tenant']
  }

  id: number = NaN
  name: string = ''
  lease_period_id: number = NaN // inheritance binding
  property_id: number = NaN
  type: string = ''
  effective_start_date: string = ''
  effective_end_date: string = ''
  total: number = NaN
  active: boolean = false
  approved: boolean = false
  approved_by?: string = ''
  approved_at?: string = ''
  created_by: string = ''
  created_at: string = ''
  ledger_entries: Ledger[] = []
}
