
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { State, Action, Getter } from 'vuex-class'
import { Testimonial } from '../../middleware/Testimonial'

const namespace: string = 'property'

@Component({})
export default class TestimonialsCarousel extends Vue {
  @Prop({ type: Boolean }) previewModeOnly!: boolean;
  @Prop({ type: String }) organizationId: string
  @Prop({ type: String }) propertyId: string
  @Action('setTestimonials', { namespace }) setTestimonials: any;
  @Getter('testimonials', { namespace }) testimonials: Testimonial[];
  @Getter('testimonialsLoaded', { namespace }) testimonialsLoaded: boolean;
  async mounted () {
    if (this.organizationId || this.propertyId) {
      this.loadTestimonials()
    }
  }

  @Watch('organizationId')
  async onOrganizationIdChange (val) {
    if (val) {
      this.loadTestimonials()
    }
  }

  @Watch('propertyId')
  async onPropertyIdChange (val) {
    if (val) {
      this.loadTestimonials()
    }
  }

  get testimonialComputed () {
    if (this.$vuetify.breakpoint.xsOnly) {
      return [...this.testimonials].splice(0, 6)
    }
    if (this.$vuetify.breakpoint.smAndDown) {
      return [...this.testimonials].splice(0, 8)
    }

    return this.testimonials
  }

  async loadTestimonials () {
    try {
      const testimonials = this.propertyId
        ? await this.$api.testimonial.getPropertyTestimonials(this.propertyId)
        : await this.$api.testimonial.getOrganizationTestimonials(this.organizationId)
      this.setTestimonials(this.propertyId
        ? testimonials
        : testimonials.filter(item => item.show_on_organization,
        ))
    } catch (e) {
      await this.$store.dispatch('ui/showError', 'Couldn\'t load testimonials')
    }
  }

  currentSlide = 0;
}
