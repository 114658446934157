
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
// @ts-ignore
// noinspection TypeScriptCheckImport
import Quill from 'quill'
import { insertVariable } from '../quill-variable'

@Component({})
export default class ManagerSettingsDocumentTemplatesEditorSubjectEditor extends Vue {
  @Prop({ required: true, type: Boolean }) readonly loading!: boolean
  @Prop({ required: true, type: Boolean }) readonly disabled!: boolean
  @Prop({ required: true, type: Array }) readonly variables!: { name: string, length: number }[]

  variablesMenuOpened: boolean = false
  editor: any = null
  editorFocused = false
  messageLength = 0

  validationUnlocked = false
  errorMessage = ''

  mounted () {
    this.editor = new Quill(this.$refs.editor, {
      debug: process.env.NODE_ENV === 'production' ? false : 'warn',
      modules: { toolbar: false },
      formats: ['Variable'],
    })

    delete this.editor.getModule('keyboard').bindings[13]
    delete this.editor.getModule('keyboard').bindings[9]
    document.querySelector('.manager-settings-document-templates-editor-subject-editor .ql-editor')!
      .setAttribute('tabindex', '0');

    (this.editor.on as any)('text-change', () => {
      this.updateMessageLength()
      this.emitChanges()

      if (this.validationUnlocked) {
        this.validate()
      }
    });

    (this.editor.on as any)('selection-change', (event: any) => {
      if (event !== null) {
        this.editorFocused = true
        this.onFocus()
      } else {
        this.editorFocused = false

        if (this.validationUnlocked) {
          this.validate()
        } else {
          this.validationUnlocked = true
          this.validate()
        }
      }
    })

    if (this.disabled) {
      this.editor.disable()
    }
  }

  @Watch('disabled')
  onDisabledChange (newVal: boolean) {
    newVal ? this.editor.disable() : this.editor.enable()
  }

  getValue () {
    const contents = this.editor.getContents().ops

    contents.forEach((op: any) => {
      if (typeof op.insert === 'string') {
        op.insert = op.insert.replace(/\n/g, '')
      }
    })

    return contents
  }

  setValue (value: any[]) {
    this.editor.setContents(value)
    this.emitChanges()
  }

  emitChanges () {
    this.$emit('change', this.getValue())
  }

  validate () {
    if (this.messageLength === 0) {
      this.errorMessage = 'Email Subject is required'
      return false
    }

    if (this.messageLength > 998) {
      this.errorMessage = "Subject's length must be less then or equal to 998 characters"
      return false
    }

    this.errorMessage = ''
    return true
  }

  updateMessageLength () {
    const insertedVariables = this.getValue()
      .filter((op: any) => op.insert.Variable)
      .map((op: any) => {
        return JSON.parse(op.insert.Variable)
      })

    const variablesCount = insertedVariables.length
    const variablesCommonLength = insertedVariables
      .map((insertedVariable: { name: string, value: string, color?: string }) => insertedVariable.name.length)
      .reduce((acc: number, curr: number) => acc + curr, 0)

    this.messageLength = this.editor.getLength() - variablesCount + variablesCommonLength - 1
  }

  insertVariable (variable: { name: string, value: string }) {
    if (this.disabled) return
    insertVariable(this.editor, variable)
  }

  onFocus () {
    this.editor.focus()
    this.$emit('focus')
  }
}
