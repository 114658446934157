export const HELLO_SIGN_TAGS_LIST = [
  { name: 'Tenant Signature', value: '[sig|req|signer1|Tenant Signature]' },
  { name: 'Guarantor Signature', value: '[sig|req|signer2|Guarantor Signature]', color: 'warning' },
  { name: 'Landlord Signature', value: '[sig|req|signer3|Landlord Signature]', color: 'success' },
  { name: 'Tenant Initials', value: '[initial|req|signer1|Landlord Initials]' },
  { name: 'Guarantor Initials', value: '[initial|req|signer2|Guarantor Initials]', color: 'warning' },
  { name: 'Landlord Initials', value: '[initial|req|signer3|Landlord Initials]', color: 'success' },
  { name: 'Tenant Date', value: '[date|req|signer1|Tenant Date]' },
  { name: 'Guarantor Date', value: '[date|req|signer2|Guarantor Date]', color: 'warning' },
  { name: 'Landlord Date', value: '[date|req|signer3|Landlord Date]', color: 'success' },
]

export const VANITY_WEB_URLS = {
  5: 'https://trescohousing.com/',
}
