<template>
  <svg
    class="RcCalendarIcon"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#5080FB"
      d="M7.5 9.16663H5.83333V10.8333H7.5V9.16663ZM10.8333 9.16663H9.16667V10.8333H10.8333V9.16663ZM14.1667 9.16663H12.5V10.8333H14.1667V9.16663ZM15.8333 3.33329H15V1.66663H13.3333V3.33329H6.66667V1.66663H5V3.33329H4.16667C3.24167 3.33329 2.50833 4.08329 2.50833 4.99996L2.5 16.6666C2.5 17.5833 3.24167 18.3333 4.16667 18.3333H15.8333C16.75 18.3333 17.5 17.5833 17.5 16.6666V4.99996C17.5 4.08329 16.75 3.33329 15.8333 3.33329ZM15.8333 16.6666H4.16667V7.49996H15.8333V16.6666Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'RcCalendarIcon',
}
</script>

<style lang="scss">
.RcCalendarIcon {
  vertical-align: text-bottom;
}
</style>
