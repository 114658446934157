export class Testimonial {
  constructor (data?: Partial<Testimonial>) {
    Object.assign(this, data)
  }

  id: number | string = '';
  testimonial: string = '';
  name: string = '';
  image: string = '';
  show_on_organization: boolean = false;
}
