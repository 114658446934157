import Axios from 'axios'
import { AuthServiceKeys } from '../services/AuthService'

export const axiosInstance = Axios.create({
  baseURL: '/',
})

axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem(AuthServiceKeys.mgrToken) || localStorage.getItem(AuthServiceKeys.token)
    config.headers.authorization =
      // TODO Only one token should remain.
      token
        ? `Token ${token}`
        : undefined
    return config
  },
  error => Promise.reject(error),
)

// @deprecated, use { axiosIntance } insteaad
export default axiosInstance
