import { executeApiRequest } from './../middleware/api'
import { PortfolioItem } from './../middleware/PortfolioItem'
import { API } from './endpoints'

const get = () => executeApiRequest({ method: 'get', url: API.PORTFOLIOS.BASE })
const create = (portfolioItem: PortfolioItem) => executeApiRequest({ method: 'post', url: API.PORTFOLIOS.BASE, data: portfolioItem })

const edit = async (id: string, portfolio: PortfolioItem) => executeApiRequest({ method: 'post', url: API.PORTFOLIOS.EDIT(id), data: portfolio })
const deletePortfolio = async (id: string) => executeApiRequest({ method: 'delete', url: API.PORTFOLIOS.SINGLE(id) })
const uploadImage = async (id: string, image: FormData) => executeApiRequest({ method: 'post', url: API.PORTFOLIOS.UPLOAD_IMAGE(id), data: image })

export default {
  get,
  create,
  edit,
  deletePortfolio,
  uploadImage,
}
