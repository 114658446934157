import { executeApiRequest } from '../middleware/api'
import { API } from './endpoints'

const login = async (credentials: {username: string, password: string}) => executeApiRequest({ method: 'post', url: API.TOKEN_AUTH, data: credentials })
const register = async (credentials: any) => executeApiRequest({ method: 'post', url: API.USERS.SIGN_UP, data: credentials })
const resetPassword = async (credentials: {email: string}) => executeApiRequest({ method: 'post', url: API.SEND_RESET_EMAIL, data: credentials })

export default {
  login,
  register,
  resetPassword,
}
