
import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator'
import { State, Action, Getter } from 'vuex-class'
import { Property } from '../../middleware/Property'
import { Swiper as VueSwiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import { authService } from '../../services/authServiceInstance'
import { LeasePeriod } from '../../middleware/LeasePeriod'
import moment from 'moment'
import Swiper from 'swiper'

// @deprecated, use PropertyLeasePeriods
@Component({
  components: {
    VueSwiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
})
export default class PropertyLeasePeriodsOld extends Vue {
  @Prop({ type: Property }) property: Property;
  @Prop({ type: String }) description: string;
  @Prop({ type: Boolean }) previewModeOnly: boolean;
  @Action('toggleAuthModal', { namespace: 'ui' }) toggleAuthModal: any;

  @Ref() vueSwiper: any // really VueSwiper, but gives TS error

  lease_periods: LeasePeriod[] = []
  currentSlide = 1;
  selectedPeriod = null;
  selectedRoomType = null;
  swiperOptions = {
    spaceBetween: 23,
    slidesPerView: 2,
    watchOverflow: true,
    loop: true,
    centerInsufficientSlides: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
      clickable: true,
    },
    breakpoints: {
      960: {
        slidesPerView: 3,
      },
    },
  }

  onSwiperReady () {
    this.selectFirst(this.swiperInstance)
    this.swiperInstance.on('slideNextTransitionEnd', () => {
      this.currentSlide = this.currentSlide === this.lease_periods.length ? 1 : this.currentSlide + 1
    })
    this.swiperInstance.on('slidePrevTransitionEnd', () => {
      this.currentSlide = this.currentSlide === 1 ? this.lease_periods.length : this.currentSlide - 1
    })
    this.swiperInstance.update()
  }

  get swiperInstance (): Swiper {
    return this.vueSwiper.$swiper
  }

  next () {
    this.swiperInstance.slideNext()
  }

  prev () {
    this.swiperInstance.slidePrev()
  }

  convertDate (date) {
    return moment(date, 'YYYY-MM-DD').format('MM-DD-YYYY')
  }

  selectPeriod (leasePeriod: LeasePeriod) {
    if (this.isDisabled(leasePeriod)) {
      return
    }
    this.selectedPeriod = leasePeriod
  }

  isDisabled (item: LeasePeriod) {
    return item ? item.allow_same_bed_renewals || item.open_to_renewals || !item.genders_available.length : false
  }

  getAvailableLabel (period: LeasePeriod) {
    let label = 'Available'
    if (period.allow_same_bed_renewals) {
      label = 'Same Bed Renewals'
    }
    if (period.open_to_renewals) {
      label = 'Open to Renewals'
    }
    if (!period.genders_available.includes('F') && !period.genders_available.includes('M')) {
      label = 'Full'
    }
    return label
  }

  selectFirst (swiper) {
    const index = this.lease_periods.findIndex(i => !this.isDisabled(i))
    if (index !== -1) {
      this.selectedPeriod = this.lease_periods[index]
      this.currentSlide = index + 1
      swiper && swiper.slideToLoop(index)
    }
  }

  preparePropertyData () {
    const propertyData = {
      assigned_property: this.property.id,
    }

    return !this.selectedPeriod ? propertyData : Object.assign(propertyData, {
      agreement_period: this.selectedPeriod.id,
      start_date: this.selectedPeriod.start_date,
      end_date: this.selectedPeriod.end_date,
    })
  }

  checkAvailability () {
    if (authService.hasRole('student')) return

    this.toggleAuthModal({
      open: true,
      type: 'register',
      data: this.preparePropertyData(),
    })
  }

  async mounted () {
    try {
      const leasePeriods = await this.$api.leasePeriod.getForProperty(this.property.id)

      this.lease_periods = leasePeriods
        .filter(({ visible }) => visible)
        .sort((a, b) => moment(a.start_date, 'YYYY-MM-DD').diff(moment(b.start_date, 'YYYY-MM-DD')) > 0 ? 1 : -1) as any
      if (this.lease_periods.length < 3) {
        this.swiperOptions.loop = false
      }
    } catch (e) {
      await this.$store.dispatch('ui/showError', "Couldn't load lease periods")
    }
  }
}
