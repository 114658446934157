
import { Vue, Component } from 'vue-property-decorator'
import { ICellRendererParams } from 'ag-grid-community'
import ConfirmationModal from '@/components/common/ConfirmationModal.vue'

@Component({
  components: { ConfirmationModal },
})
export default class ManagerSettingsLayoutsActionColumn extends Vue {
  params!: ICellRendererParams

  get parent () {
    return this.params.context.componentParent
  }

  editLayout () {
    this.parent.openEditLayoutDialog(this.params.data)
  }

  deleteLayout () {
    this.parent.deleteLayout(this.params.data)
  }
}
