
import { Component, Vue, Prop } from 'vue-property-decorator'
import { API } from '../../api/endpoints'
import axios from '../../middleware/axios'
import { LeasePeriodSemester } from '../../middleware/LeasePeriodSemester'
import { Executor } from 'asva-executors'
import { mediumDate } from '../../filters'

@Component({})
export default class LeasePeriodSemesterSelect extends Vue {
  @Prop() value!: LeasePeriodSemester[]

  loadSemestersExecutor = Executor.createAndRun(this.loadLeasePeriodSemesters)
  semesters: LeasePeriodSemester[] = []

  async loadLeasePeriodSemesters () {
    const semesters = (await axios.get(API.SEMESTERS.BASE)).data
    this.semesters = semesters
  }

  get valueProxy (): LeasePeriodSemester[] {
    return this.value
  }

  set valueProxy (value: LeasePeriodSemester[]) {
    this.$emit('input', value)
  }

  get selectedSemesters (): LeasePeriodSemester[] {
    const semesterIds = this.valueProxy.map(semester => semester.id)
    return this.semesters.filter(semester => semesterIds.includes(semester.id))
  }

  selectAll () {
    if (this.value.length === this.semesters.length) {
      this.valueProxy = []
    } else {
      this.valueProxy = this.semesters
    }
  }

  get startDate () {
    if (!this.valueProxy.length) {
      return ''
    } else {
      let date = ''
      for (let i = 0; i < this.selectedSemesters.length; i++) {
        // TODO We might want to make this comparison clearer.
        // As, while it works, it's not exactly clear as to how.
        if (!date || new Date(this.selectedSemesters[i].start_date as string) < new Date(date)) {
          date = this.selectedSemesters[i].start_date as string
        }
      }
      return mediumDate(date)
    }
  }

  get endDate () {
    if (!this.valueProxy.length) {
      return ''
    } else {
      let date = ''
      for (let i = 0; i < this.selectedSemesters.length; i++) {
        if (!date || new Date(this.selectedSemesters[i].end_date as string) > new Date(date)) {
          date = this.selectedSemesters[i].end_date as string
        }
      }
      return mediumDate(date)
    }
  }
}
