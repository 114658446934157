import { Module } from 'vuex'
import { Organization, SubPeriod } from '../../middleware/entities'
import API_tmp from '../../middleware/API_tmp'
import { LeasePeriod } from '../../middleware/LeasePeriod'
import { Property } from 'src/middleware/Property'
import { AccountCode } from 'src/middleware/AccountCode'

interface IManagerState {
  loading: boolean;
  organization: Organization;

  properties: Property[];
  selectedProperties: Property[];
  allPropertiesWasLoaded: boolean;

  leasePeriods: LeasePeriod[];
  selectedLeasePeriods: LeasePeriod[];

  subPeriods: SubPeriod[];

  accountCodes: AccountCode[];
}

// noinspection JSUnusedGlobalSymbols
export default <Module<IManagerState, any>>{
  namespaced: true,

  state: {
    loading: false,
    organization: new Organization(),

    properties: [],
    selectedProperties: [],
    allPropertiesWasLoaded: false,

    leasePeriods: [],
    selectedLeasePeriods: [],

    subPeriods: [],

    accountCodes: [],
  },

  mutations: {
    SET_LOADING (state, value) {
      state.loading = value
    },

    SET_ORGANIZATION (state, value) {
      state.organization = value
    },

    SET_PROPERTIES (state, value) {
      state.properties = value
    },

    SET_SELECTED_PROPERTIES (state, value) {
      state.selectedProperties = value

      if (state.selectedProperties === state.properties) {
        state.selectedLeasePeriods = state.leasePeriods
      } else if (state.selectedProperties.length === 0) {
        state.selectedLeasePeriods = []
      } else {
        state.selectedLeasePeriods = state.selectedLeasePeriods.filter(leasePeriod => {
          return state.selectedProperties
            .flatMap(property => {
              return property.lease_periods
            })
            .includes(leasePeriod)
        })
      }
    },

    TOGGLE_ALL_PROPERTIES_WAS_LOADED (state, value) {
      state.allPropertiesWasLoaded = true
    },

    SET_SUB_PERIODS (state, value) {
      state.subPeriods = value
    },

    SET_ACCOUNT_CODES (state, value) {
      state.accountCodes = value
    },
  },

  actions: {
    async loadOrganization ({ commit, dispatch }) {
      commit('SET_LOADING', true)

      try {
        const organization = await API_tmp.manager.getOrganization()
        commit('SET_ORGANIZATION', organization)

        const properties = organization.properties
        commit('SET_PROPERTIES', properties)
        commit('SET_SELECTED_PROPERTIES', [...properties])

        const accountCodes = organization.account_codes
        commit('SET_ACCOUNT_CODES', accountCodes)
      } catch {
        dispatch('ui/showError', "Couldn't load the organization", { root: true })
      } finally {
        commit('SET_LOADING', false)
      }
    },
  },

  getters: {
    organization: (state) => {
      return state.organization
    },
    properties: (state) => {
      return state.properties
    },
    propertiesIds: (state) => {
      return state.properties.map(({ id }) => id)
    },
    propertyById: (state) => (id: number): Property | null => {
      return state.properties.find(property => {
        return property.id === id
      }) || null
    },
    propertyIdsAsItems: (state): { text: string, value: number }[] => {
      return state.properties.map(property => {
        return {
          text: property.name,
          value: property.id,
        }
      })
    },
    selectedPropertiesIds: (state): number[] => {
      return state.selectedProperties.map(property => property.id)
    },

    subPeriodById: (state) => (subPeriodId: number): SubPeriod | null => {
      return state.subPeriods.find(subPeriod => {
        return subPeriod.id === subPeriodId
      }) || null
    },

    subPeriodIdsAsItemsByLeasePeriodId: (state) => (leasePeriodId: number): { text: string, value: number }[] => {
      return state.subPeriods
        .filter(subPeriod => {
          return subPeriod.lease_period_id === leasePeriodId
        })
        .map(subPeriod => {
          return {
            text: subPeriod.name,
            value: subPeriod.id,
          }
        })
    },

    accountCodeById: (state) => (accountCodeId: number): AccountCode | null => {
      return state.accountCodes.find(accountCode => {
        return accountCode.id === accountCodeId
      }) || null
    },
  },
}
