import { createMock } from './../middleware/api'
import { PaymentPlan } from '../middleware/PaymentPlan'

const getPaymentPlans = async (leasePeriodIds: number[], propertyIds: number[]): Promise<PaymentPlan[]> => {
  if (!leasePeriodIds.length) return Promise.resolve([])

  try {
    /* MOCK DATA */
    const data: Partial<PaymentPlan>[] = await new Promise((resolve, reject) => { // mock
      setTimeout(() => {
        resolve(
          Array.from(new Array(10 * leasePeriodIds.length), (c, i) => {
            return {
              id: i,
              name: `Payment Plan #${i}`,
              type: PaymentPlan.types[Math.random() * PaymentPlan.types.length | 0],
              lease_period_id: leasePeriodIds[Math.random() * leasePeriodIds.length | 0],
              property_id: propertyIds[Math.random() * propertyIds.length | 0],
              total: Math.random() * 10000,
              active: Math.random() > 0.5,
              approved: Math.random() > 0.5,
              created_by: 'Karen Kilmer',
              created_at: '2019-10-05T07:11:10.510Z',
            }
          }),
        )
      }, 1000)
    })
    /* /MOCK DATA */

    return data.map((rawPaymentPlan: Partial<PaymentPlan>) => new PaymentPlan(rawPaymentPlan))
  } catch (e) {
    console.info('Error in getPaymentPlans:')
    console.error(e)
    throw e
  }
}

const createPaymentPlan = async (paymentPlan: PaymentPlan): Promise<number> => {
  try {
    /* MOCK DATA */
    return await new Promise((resolve, reject) => { // mock
      setTimeout(() => {
        resolve(Math.random() * 1000 | 0)
      }, 1000)
    })
    /* /MOCK DATA */
  } catch (e) {
    console.info('Error in createPaymentPlan:')
    console.error(e)
    throw e
  }
}

const clonePaymentPlan = async (paymentPlan: PaymentPlan): Promise<number> => {
  try {
    /* MOCK DATA */
    return await new Promise((resolve, reject) => { // mock
      setTimeout(() => {
        resolve(Math.random() * 1000 | 0)
      }, 1000)
    })
    /* /MOCK DATA */
  } catch (e) {
    console.info('Error in clonePaymentPlan:')
    console.error(e)
    throw e
  }
}

const getPaymentPlan = async (paymentPlanId: number, leasePeriodIds: number[], propertyIds: number[]): Promise<PaymentPlan> => {
  try {
    /* MOCK DATA */
    const data: Partial<PaymentPlan> = await new Promise((resolve, reject) => { // mock
      setTimeout(() => {
        resolve({
          id: paymentPlanId,
          name: `Payment Plan #${paymentPlanId}`,
          type: PaymentPlan.types[Math.random() * PaymentPlan.types.length | 0],
          lease_period_id: leasePeriodIds[Math.random() * leasePeriodIds.length | 0],
          property_id: propertyIds[Math.random() * propertyIds.length | 0],
          effective_start_date: '2020-06-08',
          effective_end_date: '2020-06-08',
          active: Math.random() > 0.5,
          approved: false,
          approved_by: 'Karen Kilmer',
          approved_at: '2020-06-08T22:12:58.510Z',
          created_by: 'Karen Kilmer',
          created_at: '2019-10-05T07:11:10.510Z',
          ledger_entries: Array.from(new Array(5), (c, i) => {
            return {
              id: i,
              sub_period_id: 0,
              due_date: [
                'Due Today',
                'Due on Approval',
                '2020-06-04T22:12:00.510Z',
              ][Math.random() * 3 | 0],
              account_code_id: Math.random() * 3 | 0,
              description: 'Custom Description',
              credit_amount: Math.random() * 10,
              charge_amount: Math.random() * 10000,
            }
          }),
        })
      }, 1000)
    })
    /* /MOCK DATA */

    return new PaymentPlan(data)
  } catch (e) {
    console.info('Error in getPaymentPlan:')
    console.error(e)
    throw e
  }
}

const updatePaymentPlan = async (paymentPlan: PaymentPlan): Promise<void> => {
  try {
    /* MOCK DATA */
    await new Promise((resolve, reject) => { // mock
      setTimeout(() => {
        resolve()
      }, 1000)
    })
    /* /MOCK DATA */
  } catch (e) {
    console.info('Error in updatePaymentPlan:')
    console.error(e)
    throw e
  }
}

const approvePaymentPlan = async (paymentPlan: PaymentPlan): Promise<void> => {
  try {
    /* MOCK DATA */
    await createMock({})
    /* /MOCK DATA */
  } catch (e) {
    console.info('Error in approvePaymentPlan:')
    console.error(e)
    throw e
  }
}

export default {
  list: getPaymentPlans,
  get: getPaymentPlan,
  clone: clonePaymentPlan,
  approve: approvePaymentPlan,
  update: updatePaymentPlan,
  create: createPaymentPlan,
}
