import Vue from 'vue'
import router from '../router/router'
import store from '../store/store'
import App from './App.vue'
import vuetify from '../plugins/vuetify'
import '../plugins/gmap'
import mixins from '../mixins/lazyLoadingHack'
import vueSmoothScroll from 'vue2-smooth-scroll'
import 'ag-grid-enterprise'
import * as filters from '../filters'
import '../registerServiceWorker'
import { ApiVuePlugin } from '../api/ApiVuePlugin'
import '@/assets/styles/main.scss'
import 'swiper/swiper-bundle.css'
import 'swiper/components/navigation/navigation.min.css'

for (const filter in filters) {
  Vue.filter(filter, (filters as { [key: string]: Function })[filter])
}

Vue.use(vueSmoothScroll)
Vue.use(ApiVuePlugin)
Vue.mixin(mixins)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
