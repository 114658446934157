
import { Component, Vue, Watch } from 'vue-property-decorator'
import LeasePeriodsTable from '../../views/LeasePeriods/LeasePeriodsTable.vue'
import axios from '../../middleware/axios'
import { API } from '../../api/endpoints'
import { LeasePeriod } from '../../middleware/LeasePeriod'
import ManagerPropertiesSelector
  from '../../components/manager/ManagerPropertiesSelector.vue'
import { VForm } from '../../custom-typings'
import LeasePeriodEdit from './LeasePeriodEdit.vue'
import { Property } from '../../middleware/Property'
import { authService } from '../../services/authServiceInstance'

@Component({
  components: { LeasePeriodEdit, ManagerPropertiesSelector, LeasePeriodsTable },
})
export default class LeasePeriodsPage extends Vue {
  leasePeriods: LeasePeriod[] = []
  editedLeasePeriod: LeasePeriod | null = null
  leasePeriodSaving = false

  mounted () {
    this.loadPeriods()
  }

  @Watch('selectedProperties')
  onSelectedPropertiesChange () {
    this.loadPeriods()
  }

  get user () {
    return authService.user
  }

  get activeProperty () {
    return this.$store.state.activeProperty
  }

  get selectedProperties (): Property[] {
    return this.$store.state.manager.selectedProperties
  }

  get properties (): Property[] {
    return this.$store.state.manager.properties
  }

  async loadPeriods () {
    if (!this.selectedProperties.length) {
      throw new Error('Manager should have at least one selected property')
    }

    this.leasePeriods = await this.$api.leasePeriod.getForProperty(this.selectedProperties[0].id)
  }

  async toggleField (period: LeasePeriod, field: 'visible' | 'visible_to_managers' | 'allow_same_bed_renewals' | 'open_to_renewals'): Promise<void> {
    if (!period.id) {
      throw new Error('lease period should have id defined')
    }
    // TODO Very strange and complicated for something as simple as modifying several flags.
    if (field === 'visible') {
      if (period.visible) {
        await axios.post(API.AGREEMENT_PERIODS.INVISIBLE_TO_TENATS(period.id))
        period.visible = false
        period.open_to_renewals = false
      } else {
        await axios.post(API.AGREEMENT_PERIODS.VISIBLE_TO_TENATS(period.id))
        period.visible = true
      }
    } else if (field === 'visible_to_managers') {
      if (period.visible_to_managers) {
        await axios.post(API.AGREEMENT_PERIODS.INVISIBLE_TO_MANAGERS(period.id))
        period.visible_to_managers = false
      } else {
        await axios.post(API.AGREEMENT_PERIODS.VISIBLE_TO_MANAGERS(period.id))
        period.visible_to_managers = true
      }
    } else if (field === 'allow_same_bed_renewals') {
      let newState = false
      if (!period.allow_same_bed_renewals) {
        newState = true
      }
      await axios.put(API.AGREEMENT_PERIODS_RESOURCE.SINGLE(period.id), {
        allow_same_bed_renewals: newState,
      })
      period.allow_same_bed_renewals = newState
      period.visible = true
    } else if (field === 'open_to_renewals') {
      if (period.open_to_renewals) {
        await axios.post(API.AGREEMENT_PERIODS.NOT_RENEWALS_ONLY(period.id))
        period.open_to_renewals = false
      } else {
        await axios.post(API.AGREEMENT_PERIODS.RENEWALS_ONLY(period.id))
        period.open_to_renewals = true
        period.visible = true
      }
    }
  }

  async saveLeasePeriod (): Promise<void> {
    if (!(this.$refs.editLeasePeriodForm as VForm).validate()) return

    this.leasePeriodSaving = true

    if (this.editedLeasePeriod?.id) {
      await this.updateLeasePeriod()
    } else {
      await this.createLeasePeriod()
    }

    this.leasePeriodSaving = false
    this.closeEditLeasePeriodDialog()
    await this.loadPeriods()
  }

  async createLeasePeriod (): Promise<void> {
    const payload = {
      name: this.editedLeasePeriod?.name,
      semesters: this.editedLeasePeriod?.semesters.map(semester => semester.id),
    }
    try {
      await axios.post(API.PROPERTIES.ADD_PERIOD(this.editedLeasePeriod?.property_id || ''), payload)
      this.$store.dispatch('ui/showSuccess', 'Lease period created.')
      this.closeEditLeasePeriodDialog()
    } catch (e) {
      this.$store.dispatch('ui/showError', 'The was a problem with our server. Please contact support or try again later.')
    }
  }

  async updateLeasePeriod (): Promise<void> {
    const payload = {
      name: this.editedLeasePeriod?.name,
      semesters: this.editedLeasePeriod?.semesters.map(semester => semester.id),
    }
    await axios.post(API.AGREEMENT_PERIODS.EDIT(this.editedLeasePeriod?.property_id || ''), payload)
    this.$store.dispatch('ui/showSuccess', 'Lease period edited.')
  }

  openEditLeasePeriodDialog (value: boolean) {
    if (value) {
      this.editedLeasePeriod = new LeasePeriod()
    }
  }

  onLeasePeriodEdit (leasePeriod: LeasePeriod) {
    this.editedLeasePeriod = new LeasePeriod({
      id: leasePeriod.id,
      // TODO Property names are off.
      property_id: leasePeriod.property,
      name: leasePeriod.name,
      semesters: leasePeriod.semesters,
    })
  }

  async onRenewalPeriodsUpdated (leasePeriod: LeasePeriod): Promise<void> {
    const payload = {
      periods_to_renew_from: leasePeriod.periods_to_renew_from.map(period => period.id),
    }
    await axios.put(API.AGREEMENT_PERIODS_RESOURCE.SINGLE(leasePeriod.id as number), payload)
  }

  closeEditLeasePeriodDialog () {
    this.editedLeasePeriod = null
  }

  get editLeasePeriodFormTitle (): string {
    return (this.editedLeasePeriod as LeasePeriod).id ? 'Edit Lease Period' : 'Add New Lease Period'
  }

  get editLeasePeriodActivatorText (): string {
    return (this.editedLeasePeriod as LeasePeriod).id ? 'Save' : 'Create'
  }
}

