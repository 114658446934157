import { Module } from 'vuex'

interface IUIState {
  authModal: {
    type: string,
    open: boolean;
    data: any
  };

  snackbar: {
    model: boolean;
    text: string;
    options: any
  };
  nav: {
    disabled: boolean
  };
}

// @deprecated
export default <Module<IUIState, any>>{
  namespaced: true,

  state: {
    authModal: {
      type: '',
      open: false,
      data: null,
    },
    snackbar: {
      model: false,
      text: '',
      options: {},
    },
    nav: {
      disabled: false,
    },
  },

  getters: {
    authModal: (state) => {
      return state.authModal
    },
    snackbar: (state) => {
      return state.snackbar
    },
    navDisabled: (state) => {
      return state.nav.disabled
    },
  },

  mutations: {
    SET_SNACKBAR (state, payload) {
      state.snackbar = {
        ...state.snackbar,
        ...payload,
      }

      state.snackbar.model = true
    },

    SET_AUTH_MODAL (state, payload) {
      state.authModal = payload
    },

    SET_NAV_DISABLED_STATUS (state, payload) {
      state.nav.disabled = payload
    },
  },

  actions: {
    showError ({ commit }, error) {
      commit('SET_SNACKBAR', {
        text: error,
        options: {
          color: 'error',
          top: true,
        },
      })
    },

    showSuccess ({ commit }, success) {
      commit('SET_SNACKBAR', {
        text: success,
        options: {
          color: 'success',
          top: true,
        },
      })
    },

    toggleAuthModal ({ commit, getters }, data) {
      const authModalMerged = Object.assign(getters.authModal, data)
      commit('SET_AUTH_MODAL', authModalMerged)
    },

    setNavDisabledState ({ commit }, data) {
      commit('SET_NAV_DISABLED_STATUS', data)
    },
  },
}
