import { PortfolioItem } from 'src/middleware/PortfolioItem'
import { PropertySite, PropertySettings } from './../../middleware/entities'
import { Property } from './../../middleware/Property'
import { Amenity } from './../../middleware/Amenity'
import { Module } from 'vuex'
import axios from '../../middleware/axios'
import { Testimonial } from 'src/middleware/Testimonial'
import { Resource } from 'src/middleware/Resource'
import { PropertyImage } from 'src/middleware/PropertyImage'

interface PropertyState {
  properties: Property[];
  propertiesLoaded: boolean;
  propertiesSites: PropertySite[];
  propertiesSettings: PropertySettings[];
  propertyLoading: boolean;
  portfolios: PortfolioItem[];
  portfoliosLoaded: boolean;
  testimonials: Testimonial[];
  testimonialsLoaded: boolean;
  propertyImages: PropertyImage[];
  propertyImagesLoaded: boolean;
  amenities: Amenity[];
  amenitiesLoaded: boolean;
  resources: Resource[];
  resourcesLoaded: boolean;
}

export default <Module<PropertyState, any>>{
  namespaced: true,

  state: {
    properties: [],
    propertiesLoaded: false,
    portfolios: [],
    portfoliosLoaded: false,
    propertiesSites: [],
    propertiesSettings: [],
    propertyLoading: false,
    testimonials: [],
    testimonialsLoaded: false,
    propertyImages: [],
    propertyImagesLoaded: false,
    amenities: [],
    amenitiesLoaded: false,
    resources: [],
    resourcesLoaded: false,
  },

  mutations: {
    SET_PROPERTIES: (state, value) => {
      state.properties = value
    },
    SET_PROPERTIES_LOADED: (state, value) => {
      state.propertiesLoaded = value
    },
    SET_PROPERTIES_SITES: (state, sites) => {
      state.propertiesSites = sites
    },
    SET_PROPERTIES_SETTINGS: (state, settings) => {
      state.propertiesSettings = settings
    },
    SET_PROPERTIES_LOADING: (state, value) => {
      state.propertyLoading = value
    },
    UPDATE_PROPERTIES_SITES: (state, site) => {
      updateArrInStore(state, site, 'propertiesSites')
    },
    UPDATE_PROPERTIES_SETTINGS: (state, settings) => {
      updateArrInStore(state, settings, 'propertiesSettings')
    },
    UPDATE_PROPERTIES: (state, property) => {
      updateArrInStore(state, property, 'properties')
    },
    SET_PORTFOLIOS: (state, value) => {
      state.portfolios = value
    },
    SET_PORTFOLIOS_LOADED: (state, value) => {
      state.portfoliosLoaded = value
    },
    SET_TESTIMONIALS: (state, value) => {
      state.testimonials = value
    },
    SET_TESTIMONIALS_LOADED: (state, value) => {
      state.testimonialsLoaded = value
    },
    SET_PROPERTY_IMAGES: (state, value) => {
      state.propertyImages = value
    },
    SET_PROPERTY_IMAGES_LOADED: (state, value) => {
      state.propertyImagesLoaded = value
    },
    SET_AMENITIES: (state, value) => {
      state.amenities = value
    },
    SET_AMENITIES_LOADED: (state, value) => {
      state.amenitiesLoaded = value
    },
    SET_RESOURCES: (state, value) => {
      state.resources = value
    },
    SET_RESOURCES_LOADED: (state, value) => {
      state.resourcesLoaded = value
    },
  },

  actions: {
    async loadProperties ({ commit }) {
      try {
        const properties = (await axios.get('/api/properties')).data
        commit('SET_PROPERTIES', properties)
      } catch (err) {
        throw err
      } finally {
        commit('SET_PROPERTIES_LOADED', true)
      }
    },

    togglePropertiesLoading ({ commit, state }) {
      commit('SET_PROPERTIES_LOADING', !state.propertyLoading)
    },
    async loadSingleProperty ({ commit, getters }, propertyId) {
      commit('SET_PROPERTIES_LOADING', true)
      try {
        const property = (await axios.get(`/api/properties/${propertyId}`)).data
        const currentProperty = getters.singleProperty(propertyId)
        property.full = true
        commit('UPDATE_PROPERTIES', currentProperty ? Object.assign(currentProperty, property) : property)
      } catch (err) {
        throw err
      } finally {
        commit('SET_PROPERTIES_LOADING', false)
      }
    },

    async loadPropertySite ({ commit }, propertyId) {
      try {
        const property = (await axios.get(`properties-site/${propertyId}`)).data
        commit('UPDATE_PROPERTIES_SITES', property)
      } catch (err) {
        throw err
      }
    },

    async loadPropertySettings ({ commit }, propertyId) {
      try {
        const property = (await axios.get(`properties-settings/${propertyId}`)).data
        commit('UPDATE_PROPERTIES_SETTINGS', property)
      } catch (err) {
        throw err
      }
    },

    async loadPortfolios ({ commit }) {
      try {
        const portfolios = (await axios.get('/api/portfolios')).data
        commit('SET_PORTFOLIOS', portfolios)
      } catch (err) {
        throw err
      } finally {
        commit('SET_PORTFOLIOS_LOADED', true)
      }
    },

    updatePortfolios ({ commit }, portfolios) {
      commit('SET_PORTFOLIOS', portfolios)
    },

    setTestimonials ({ commit }, testimonials) {
      commit('SET_TESTIMONIALS', testimonials)
      commit('SET_TESTIMONIALS_LOADED', true)
    },
    setPropertyImages ({ commit }, propertyImages) {
      commit('SET_PROPERTY_IMAGES', propertyImages)
      commit('SET_PROPERTY_IMAGES_LOADED', true)
    },
    setAmenities ({ commit }, amenities) {
      commit('SET_AMENITIES', amenities)
      commit('SET_AMENITIES_LOADED', true)
    },
    setResources ({ commit }, resources) {
      commit('SET_RESOURCES', resources)
      commit('SET_RESOURCES_LOADED', true)
    },
  },

  getters: {
    properties: (state) => {
      return state.properties
    },
    propertiesLoaded: (state) => {
      return state.propertiesLoaded
    },
    propertiesSites: (state) => {
      return state.propertiesSites
    },
    propertiesSettings: (state) => {
      return state.propertiesSettings
    },
    propertyLoading: (state) => {
      return state.propertyLoading
    },
    singleProperty: (state) => (id: number): Property|null => {
      const property = state.properties.find((i: Property) => i.id === +id)

      return property ? new Property(property) : null
    },
    portfolios: (state) => {
      return state.portfolios
    },
    portfoliosLoaded: (state) => {
      return state.portfoliosLoaded
    },
    testimonials: (state) => {
      return state.testimonials
    },
    testimonialsLoaded: (state) => {
      return state.testimonialsLoaded
    },
    propertyImages: (state) => {
      return state.propertyImages
    },
    propertyImagesLoaded: (state) => {
      return state.propertyImagesLoaded
    },
    amenities: (state) => {
      return state.amenities
    },
    amenitiesLoaded: (state) => {
      return state.amenitiesLoaded
    },
    resources: (state) => {
      return state.resources
    },
    resourcesLoaded: (state) => {
      return state.resourcesLoaded
    },
  },
}

const updateArrInStore = (state: any, obj: any, type: string) => {
  const arr = state[type] || []
  const idx = arr.findIndex((a: any) => a.id === obj.id)

  if (idx < 0) {
    arr.push(obj)
  } else {
    arr.splice(idx, 1, obj)
  }
}
