
import { Component, Prop, Vue } from 'vue-property-decorator'
import moment from 'moment'
import { LeasePeriod } from '../../../middleware/LeasePeriod'
import RcCalendarIcon from './RcCalendarIcon.vue'
import RcBedIcon from '../RcBedIcon.vue'
import RcFemaleIcon from './RcFemaleIcon.vue'
import RcMaleIcon from './RcMaleIcon.vue'

@Component({
  name: 'LeasePeriodListItem',
  components: { RcMaleIcon, RcFemaleIcon, RcBedIcon, RcCalendarIcon },
})
export default class LeasePeriodListItem extends Vue {
  @Prop({ required: true }) leasePeriod: LeasePeriod

  convertDate (date) {
    return moment(date, 'YYYY-MM-DD').format('MM-DD-YYYY')
  }
}
