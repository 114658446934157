
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class ConfirmationModal extends Vue {
  resolve = () => undefined
  reject = () => undefined
  show = false
  message = ''

  public confirm (message: string): Promise<void> {
    this.message = message
    this.show = true
    return new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
  }
}
