
import { Vue, Component, Prop } from 'vue-property-decorator'
import GalleryWidget from '../common/GalleryWidget.vue'
import { Amenity } from '../../middleware/Amenity'

@Component({
  components: {
    GalleryWidget,
  },
})
export default class PropertyFeatures extends Vue {
  @Prop({ type: Array }) features: Amenity[];
  @Prop({ type: String }) description: string;

  get filteredFeatures () {
    if (!this.features) return []
    return this.features
      .sort((a, b) => a.order - b.order)
      .map(i => ({ url: i.image, caption: i.name }))
  }
}
