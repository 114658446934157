
import { Vue, Component } from 'vue-property-decorator'

@Component({})
export default class ManagerPropertiesSelector extends Vue {
  get selectedProperties () {
    return this.$store.state.manager.selectedProperties
  }

  set selectedProperties (value) {
    this.$store.commit('manager/SET_SELECTED_PROPERTIES', value)
  }

  selectAll () {
    if (this.$store.state.manager.selectedProperties.length === this.$store.state.manager.properties.length) {
      this.selectedProperties = []
    } else {
      this.selectedProperties = this.$store.state.manager.properties
    }
  }
}
