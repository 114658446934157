
import { Vue, Component } from 'vue-property-decorator'
import { ICellRendererParams } from 'ag-grid-community'

@Component({})
export default class LeasePeriodsTableActions extends Vue {
  params!: ICellRendererParams

  get parent () {
    return this.params.context.componentParent
  }

  editPaymentPlan () {
    this.parent.onLeasePeriodEdit(this.params.data)
  }
}
