
import { Vue, Component } from 'vue-property-decorator'
import { Property } from '../../middleware/Property'
import { LeasePeriod } from '../../middleware/LeasePeriod'
import leasePeriod from '../../store/modules/leasePeriod'
import { State, Action, Getter } from 'vuex-class'
const namespace = 'leasePeriod'

@Component({})
export default class ManagerLeasePeriodsSelector extends Vue {
  @Action('loadLeasePeriods', { namespace }) setLeasePeriods: Function;
  @Action('selectLeasePeriods', { namespace }) selectLeasePeriods: Function;
  @Getter('leasePeriodsLoaded', { namespace }) leasePeriodsLoaded: any;
  @Getter('leasePeriodsForProperties', { namespace }) leasePeriods: any;
  @Getter('selectedLeasePeriods', { namespace }) selectedLeasePeriods: LeasePeriod[];
  mounted () {
    this.loadLeasePeriods()
  }

  async loadLeasePeriods () {
    if (!this.leasePeriodsLoaded) {
      try {
        const leasePeriods = await this.$api.leasePeriod.list()

        this.setLeasePeriods(leasePeriods)
      } catch (e) {
        console.log(e)
      }
    }
  }

  get allSelected () {
    return this.selectedLeasePeriods.length === this.filteredLeasePeriods.length
  }

  get filteredLeasePeriods () {
    return this.leasePeriods(this.$store.state.manager.selectedProperties.map(({ id }) => id))
      .map((leasePeriod: LeasePeriod) => ({
        text: leasePeriod.name,
        value: leasePeriod,
      }))
  }

  selectAll () {
    this.selectLeasePeriods(this.allSelected ? [] : this.filteredLeasePeriods)
  }
}
