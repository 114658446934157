
import { Vue, Component } from 'vue-property-decorator'
import { AgGridVue } from 'ag-grid-vue'
import ManagerSettingsDocumentTemplatesActionColumn from '@/components/manager/ManagerSettingsDocumentTemplatesActionColumn.vue'
import { ColDef, ColumnApi, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community'
import { mediumDate } from '../filters'
import API_tmp from '../middleware/API_tmp'
import { DocumentTemplate } from '../middleware/entities'
import { VForm } from '../custom-typings'

@Component({
  components: {
    AgGridVue,
    ManagerSettingsDocumentTemplatesActionColumn,
  },
})
export default class ManagerSettingsDocumentTemplates extends Vue {
  gridOptions: GridOptions = {
    context: {
      componentParent: this,
    },

    suppressScrollOnNewData: true,
    rowSelection: 'single',
    suppressCellSelection: true,
    animateRows: true,
    domLayout: 'autoHeight',

    defaultColDef: {
      sortable: true,
      filter: true,
      enableRowGroup: true,
      resizable: true,
    },
  }

  columnDefs: ColDef[] = [
    { headerName: 'Name', field: 'name' },
    { headerName: 'Document Type', field: 'type' },
    {
      headerName: 'Status',
      field: 'approved',
      valueFormatter (params) {
        return params.value ? 'Approved' : 'Draft'
      },
    },
    {
      headerName: 'Date',
      field: 'created_at',
      valueFormatter (params) {
        return params.value ? mediumDate(params.value) : ''
      },
    },
    { headerName: 'Created By', field: 'created_by' },
    { headerName: 'Approved By', field: 'approved_by' },
    {
      headerName: 'Actions',
      cellRendererFramework: ManagerSettingsDocumentTemplatesActionColumn,
    },
  ]

  gridApi: GridApi = null!
  columnApi: ColumnApi = null!

  documentTemplates: DocumentTemplate[] = []
  documentTemplatesLoading = false
  documentTemplateSaving = false

  editedDocumentTemplate = new DocumentTemplate()
  editedDocumentTemplateIsForClone = false
  editDocumentTemplateDialog = false

  onGridReady (params: GridReadyEvent) {
    this.gridApi = params.api
    this.columnApi = params.columnApi

    this.loadDocumentTemplates()
  }

  get editDocumentTemplateDialogFormTitle () {
    return this.editedDocumentTemplateIsForClone ? 'Clone Document Template' : 'New Document Template'
  }

  openCloneDocumentTemplateDialog (documentTemplate: DocumentTemplate) {
    this.editedDocumentTemplate = new DocumentTemplate(documentTemplate)
    this.editedDocumentTemplateIsForClone = true
    this.editDocumentTemplateDialog = true
  }

  closeEditDocumentTemplateDialog () {
    this.editDocumentTemplateDialog = false

    setTimeout(() => {
      this.editedDocumentTemplateIsForClone = false
      this.editedDocumentTemplate = new DocumentTemplate();
      (this.$refs.editDocumentTemplateForm as VForm).resetValidation()
    }, 300)
  }

  sizeColumns () {
    if (this.$vuetify.breakpoint.mdAndDown) {
      if (this.columnApi) this.columnApi.autoSizeAllColumns()
    } else {
      if (this.gridApi) this.gridApi.sizeColumnsToFit()
    }
  }

  async loadDocumentTemplates () {
    this.documentTemplatesLoading = true

    try {
      this.documentTemplates = await API_tmp.manager.settings.documentTemplates.getDocumentTemplates()
    } catch {
      await this.$store.dispatch('ui/showError', "Couldn't load documents template")
    } finally {
      this.documentTemplatesLoading = false
    }
  }

  async saveDocumentTemplate () {
    if (!(this.$refs.editDocumentTemplateForm as VForm).validate()) return

    this.documentTemplateSaving = true

    if (!this.editedDocumentTemplateIsForClone) {
      await this.createDocumentTemplate(this.editedDocumentTemplate)
    } else {
      await this.cloneDocumentTemplate(this.editedDocumentTemplate)
    }

    this.closeEditDocumentTemplateDialog()
    this.documentTemplateSaving = false
  }

  async createDocumentTemplate (documentTemplate: DocumentTemplate) {
    try {
      documentTemplate.id = await API_tmp.manager.settings.documentTemplates.createDocumentTemplate(documentTemplate)
      documentTemplate.created_at = (new Date()).toString()
      this.gridApi.updateRowData({ add: [documentTemplate] })
    } catch {
      await this.$store.dispatch('ui/showError', "Couldn't create document template")
    }
  }

  async cloneDocumentTemplate (documentTemplate: DocumentTemplate) {
    try {
      documentTemplate.id = await API_tmp.manager.settings.documentTemplates.cloneDocumentTemplate(documentTemplate)
      documentTemplate.created_at = (new Date()).toString()
      this.gridApi.updateRowData({ add: [documentTemplate] })
    } catch {
      await this.$store.dispatch('ui/showError', "Couldn't clone document template")
    }
  }
}
